import { Spacer } from '@rmstransactions/components';
import { IconLabelWrapper } from '../../styled';
import { IconDownload, IconPrint } from '@snsw-gel/react';
import { FormEmail } from '../../../../components';
import React from 'react';
import { base64toBlob } from '../../../../services/ConfirmationService';

type DownloadReceiptProps = {
  pdfBase64: string;
  downloadFileName: string;
  handleClick: (message: string, action: string) => void;
  receptUUID: string;
}

const DownloadReceipt = ({pdfBase64, downloadFileName, handleClick, receptUUID}: DownloadReceiptProps) => {


  const blob = base64toBlob(pdfBase64!);
  const url = URL.createObjectURL(blob);

  return (
    <>
      <Spacer mb={1} data-testid='downloadReceipt'>
        <h3 style={{ fontSize: '1.35rem' }}>
          Download or share your receipt
        </h3>
        <IconLabelWrapper>
          <div className='iconWrapper'>
            <IconPrint />
          </div>
          {/* eslint-disable-next-line */}
          <a
            href={url}
            target={'_blank'}
            rel='noopener noreferrer'
            onClick={() =>
              handleClick('Keep a receipt for your records', 'Print')
            }
          >
            Print
          </a>
        </IconLabelWrapper>
        <IconLabelWrapper>
          <div className='iconWrapper'>
            <IconDownload />
          </div>
          <a
            download={downloadFileName}
            href={url}
            title='Download transaction receipt'
            onClick={() =>
              handleClick(
                'Keep a receipt for your records',
                'Download transaction receipt'
              )
            }
          >
            Download transaction receipt
          </a>
        </IconLabelWrapper>
      </Spacer>
      <Spacer id='emailform'>
        <FormEmail uuid={receptUUID} />
      </Spacer>

    </>
  );
}

export default DownloadReceipt
