import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { store } from 'providers/globalState';
import {
  Button,
  Input,
  FormGroup,
  Row,
  Col,
} from '@snsw-gel/react';
import { ButtonContainer, HelpTextBottom } from '../Styled';
import {
  handleGetRegistrationsByPlateOrBillingNumber,
  handleSetNumber,
  handleSetNumberType,
  validatePlateNumberOrBillingNumberOnInput,
} from '../FindVehiclePage.helpers';
import { RenewRegoSession } from '../../../session/RenewRegoSession';

const FindVehicleForm = (props: any) => {
  const {
    setIsLoading,
    setIsMultipleVehicle,
    setInlineErrorOrWarning,
    suppliedPlateNumberOrBillingNumber,
  } = props;

  const { dispatch } = useContext(store) as {
    dispatch: any;
  };

  const history = useHistory();
  const [
    plateNumberOrBillingNumberOnInput,
    setPlateNumberOrBillingNumberOnInput,
  ] = useState<string | null>('');
  const [numberType, setNumberType] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [inputValidationErrorMessage, setInputValidationErrorMessage] =
    useState('');
  const [hasInputValidationError, setHasInputValidationError] = useState(false);

  useEffect(() => {
    if (RenewRegoSession.getPlateNumber() != null) {
      setPlateNumberOrBillingNumberOnInput(RenewRegoSession.getPlateNumber());
    }
    handleSetNumberType(plateNumberOrBillingNumberOnInput, setNumberType);
    handleSetNumber(numberType, plateNumberOrBillingNumberOnInput, setNumber);
  }, [numberType, plateNumberOrBillingNumberOnInput, setNumber, setNumberType]);

  const resetForm = () => {
    setIsMultipleVehicle(false);
    setInlineErrorOrWarning([]);
    setHasInputValidationError(false);
  };

  const handleClick = () => {
    resetForm();
    const isPlateNumberOrBillingNumberOnInputValid: boolean =
      validatePlateNumberOrBillingNumberOnInput(
        plateNumberOrBillingNumberOnInput,
        setHasInputValidationError,
        setInputValidationErrorMessage
      );
    if (isPlateNumberOrBillingNumberOnInputValid) {
      setIsLoading(true);
      handleGetRegistrationsByPlateOrBillingNumber(
        number,
        numberType,
        history,
        dispatch,
        setIsLoading,
        setIsMultipleVehicle,
        setInlineErrorOrWarning
      );
    }
  };

  const handleChange = (e: any) => {
    setHasInputValidationError(false);
    setInputValidationErrorMessage('');
    let inputValue = e.target.value;
    setPlateNumberOrBillingNumberOnInput(
      (inputValue = inputValue.toUpperCase())
    );
  };

  const handleInputFieldKeyPress = (e: any) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      handleClick();
    }
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <HelpTextBottom className='help-text'>
            Enter a plate number, for example ABC123. Or, enter the 8 digit
            billing number on your renewal notice or Certificate of
            Registration.
          </HelpTextBottom>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <FormGroup
            margin={{ top: "none" }}
            id='find-vehicle-form-group'
            errorMessage={inputValidationErrorMessage}
            hasError={hasInputValidationError}
            isRequired
          >
            <Input
              style={{ marginTop: '0px'}}
              onChange={handleChange}
              value={plateNumberOrBillingNumberOnInput}
              className='input'
              data-testid='input'
              onKeyPress={handleInputFieldKeyPress}
              aria-label='Enter a NSW plate number or billing number'
            />
          </FormGroup>
        </Col>

        <Col span={6}>
          <ButtonContainer>
            <Button
              onClick={handleClick}
              data-testid='find-vehicle-button'
              variant='primary'
            >
              Find vehicle
            </Button>
          </ButtonContainer>
        </Col>
      </Row>

    </>
  );
};

export default FindVehicleForm;
