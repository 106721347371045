import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components';

export const FormEmailWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-flow: column;

    ${Breakpoint('sm')`
    flex-flow: row;
  `}


    label {
        clip: rect(1px, 1px, 1px, 1px) !important;
        clip-path: inset(50%) !important;
        height: 1px !important;
        margin: -1px !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }

    .field-item {
        width: 100%;
        max-width: 23.5rem;
        margin: 0;
    }
    
    input {
        margin-top: 0;
    }

    button {
        min-width: 7rem;
        max-width: 14rem;
        height: 3rem;
        margin: 0.5rem 0 0 1rem;

        ${Breakpoint('sm')`
      margin: 0 0 0 1rem;
    `}
    }
`;

export const IconLabelWrapper = styled.div`
    display: flex;
    margin-bottom: 1rem;

    .iconWrapper,
    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
    }

    p,
    a {
        margin: 0;
    }
`;
