import {
  getUserType,
  removeOtherElementsFromArray,
} from 'services/RegistrationService';
import { errorLogger, debugLogger } from '../../services/LoggerService';
import {
  UserToken,
  ErrorService,
  analyticsServiceV2,
  AnalyticsSessionManager,
} from '@rmstransactions/components';
import { getRegistrationsByPlateOrBillingNumber } from 'services/RegistrationService';
import pushAnalyticsNewPage from 'session/pushAnalyticsNewPage';
import {
  BILLING,
  DRUPAL_RENEW_REGISTRATION_URL,
  FIND_VEHICLE_INVALID_PLATE_OR_BILLING_NUMBER_INPUT,
  FIND_VEHICLE_INVALID_INPUT_EXCEED_MAXIMUM_CHARACTERS,
  FIND_VEHICLE_INVALID_INPUT_NO_NUMBER_ENTERED,
  FIND_VEHICLE_INVALID_INPUT_SPECIAL_CHARACTER_ENTERED,
  PLATE,
  TRANSACTION_STATE,
} from '../../constants/constants';
import { RenewRegoSession } from '../../session/RenewRegoSession';
import {
  getErrorMessageByCode,
  inlineErrorOrWarningPresent,
  stripSuccessIfAny,
} from '../../helpers/error-processor';
import { handleDRIVESSystemMessages } from '../ErrorPage/ErrorDetails.helpers';
import { Config } from '../../config/env';

export const handleUserType = async (
  setIsLoading: any,
  dispatch: any,
  history: any
): Promise<void> => {
  return getUserType()
    .then((response: any) => {
      if (response && response.data && response.headers) {
        UserToken.setValue(response.headers['x-token']);
        const userDetailsData = response.data;
        dispatch({ type: 'set_userDetails', payload: userDetailsData });
        let clonedUserDetailsData = Object.assign({}, userDetailsData);
        clonedUserDetailsData.snswEmailAddress = null;
        RenewRegoSession.setUserDetails(JSON.stringify(clonedUserDetailsData));

        // setup Google Analytics
        const { sessionId, myAccountId, type } = userDetailsData;
        if (sessionId) AnalyticsSessionManager.setSessionID(sessionId);
        if (myAccountId) AnalyticsSessionManager.setUserID(myAccountId);
        if (type && type in analyticsServiceV2.analyticsUserLoginMapedValues) {
          const analyticsUserLoginType =
            analyticsServiceV2.analyticsUserLoginMapedValues[type];
          AnalyticsSessionManager.setUserLoginType(analyticsUserLoginType);
        }
        AnalyticsSessionManager.setTransactionStartTime();
        pushAnalyticsNewPage();
      } else {
        setIsLoading(false);
        history.push('/error');
      }
    })
    .catch((error: any) => {
      errorLogger('getUserType Error Response', error);
      throw error;
    });
};

export const isEligibleToRenew = (eligibility: any) => {
  return (
    eligibility?.registrationEligibilityInfo?.eligibilityStatus === 'Y' ||
    (eligibility?.registrationEligibilityInfo?.eligibilityStatus === 'N' &&
      getErrorMessageByCode(
        eligibility?.systemMessages?.message,
        'NO_CTP_ESAFETY_DETAILS'
      ))
  );
};

const handleToReveiwDetailsPage = (
  response: any,
  dispatch: any,
  history: any
) => {
  const reviewDetailsInput = {
    registration: response?.data?.registrations[0]?.registration,
    eligibility: response?.data?.eligibilities[0]?.eligibility,
  };
  RenewRegoSession.setReviewInputDetails(JSON.stringify(reviewDetailsInput));
  dispatch({
    type: 'set_reviewDetailsInput',
    payload: reviewDetailsInput,
  });
  history.push('/review-details');
};

const handleSingleVehicle = (
  response: any,
  dispatch: any,
  setIsMultipleVehicle: any,
  setInlineErrorOrWarnings: any,
  history: any,
  searchedValue: string = ''
) => {
  const registrationBindingId = response?.data?.registrations[0]?.bindingId;
  const eligibility = response?.data?.eligibilities[0]?.eligibility;
  const eligibilityBindingId = response?.data?.eligibilities[0]?.bindingId;

  if (
    registrationBindingId === eligibilityBindingId &&
    isEligibleToRenew(response?.data?.eligibilities[0]?.eligibility)
  ) {
    handleToReveiwDetailsPage(response, dispatch, history);
  } else {
    const drivesMessages = stripSuccessIfAny(
      eligibility?.systemMessages?.message
    );
    if (
      drivesMessages?.length > 0 &&
      inlineErrorOrWarningPresent(drivesMessages, 'FIND_VEHICLE_PAGE')
    ) {
      drivesMessages?.map(
        (message: any) => (message.pageActionID = 'FIND_VEHICLE_PAGE')
      );
      setInlineErrorOrWarnings(drivesMessages);
    } else {
      handleMultipleVehicle(response, dispatch, setIsMultipleVehicle);
    }
  }
};

const handleMultipleVehicle = (
  response: any,
  dispatch: any,
  setIsMultipleVehicle: any,
  searchedValue: string = ''
) => {
  setIsMultipleVehicle(true);
  const multipleVehicleList = {
    registration: response?.data?.registrations,
    eligibility: response?.data?.eligibilities,
    searchedValue,
  };
  dispatch({
    type: 'set_multipleVehicleList',
    payload: multipleVehicleList,
  });
};

export const handleGetRegistrationsByPlateOrBillingNumber = (
  number: any,
  numberType: any,
  history: any,
  dispatch: any,
  setIsLoading: any,
  setIsMultipleVehicle: any,
  setInlineErrorOrWarnings: any,
  plateType: string | null | undefined = null
) => {
  getRegistrationsByPlateOrBillingNumber(number, numberType)
    .then((response: any) => {
      setIsLoading(false);
      if (response?.data?.systemMessages?.message?.length > 0) {
        callHandleErrorOrWarning(
          stripSuccessIfAny(response?.data?.systemMessages?.message),
          setInlineErrorOrWarnings,
          history
        );
      }
      if (response?.data && response?.data?.registrations) {
        if (response?.data?.registrations?.length === 1) {
          handleSingleVehicle(
            response,
            dispatch,
            setIsMultipleVehicle,
            setInlineErrorOrWarnings,
            history,
            number
          );
          if (plateType) {
            dispatch({
              type: 'set_plateType',
              payload: null,
            });
          }
        } else if (response?.data?.registrations?.length > 1) {
          setIsLoading(false);
          if (plateType) {
            const registrationIndex = response?.data?.registrations?.findIndex(
              (registrationWrapper: any) =>
                registrationWrapper?.registration?.plate?.plateType ===
                plateType
            );
            const bindingId =
              response?.data?.registrations[registrationIndex]?.bindingId;
            removeOtherElementsFromArray(
              response?.data?.registrations,
              registrationIndex
            );
            const eligibilityIndex = response?.data?.eligibilities?.findIndex(
              (eligibility: any) => eligibility?.bindingId === bindingId
            );
            removeOtherElementsFromArray(
              response?.data?.eligibilities,
              eligibilityIndex
            );
            dispatch({
              type: 'set_plateType',
              payload: null,
            });
            handleSingleVehicle(
              response,
              dispatch,
              setIsMultipleVehicle,
              setInlineErrorOrWarnings,
              history,
              number
            );
          } else {
            handleMultipleVehicle(
              response,
              dispatch,
              setIsMultipleVehicle,
              number
            );
          }
        } else {
          history.push('/error');
        }
      }
    })
    .catch((error: any) => {
      const { apiHost } = Config;
      ErrorService.handleAPIError(
        error,
        history,
        apiHost,
        TRANSACTION_STATE,
        DRUPAL_RENEW_REGISTRATION_URL
      );
    });
};

const callHandleErrorOrWarning = (
  messages: any,
  setInlineErrorOrWarnings: any,
  history: any
) => {
  handleDRIVESSystemMessages(
    messages,
    'FIND_VEHICLE_PAGE',
    setInlineErrorOrWarnings,
    history
  );
};

const removeShouldIgnoreCharacters = (input: any) => {
  return input?.replace(/-|\.|\s/g, '');
};

export const handleSetNumber = (
  numberType: any,
  plateNumberOrBillingNumberOnInput: any,
  setNumber: any
) => {
  plateNumberOrBillingNumberOnInput = removeShouldIgnoreCharacters(
    plateNumberOrBillingNumberOnInput
  );
  if (
    numberType === BILLING &&
    plateNumberOrBillingNumberOnInput.length === 7
  ) {
    const addZeroToBillingNumber = '0' + plateNumberOrBillingNumberOnInput;
    setNumber(addZeroToBillingNumber);
  } else {
    setNumber(plateNumberOrBillingNumberOnInput);
  }
};

export const handleSetNumberType = (
  plateNumberOrBillingNumberOnInput: any,
  setNumberType: any
) => {
  plateNumberOrBillingNumberOnInput = removeShouldIgnoreCharacters(
    plateNumberOrBillingNumberOnInput
  );
  if (plateNumberOrBillingNumberOnInput.match(/^[0-9]{7,8}$/)) {
    setNumberType(BILLING);
  } else if (plateNumberOrBillingNumberOnInput.match(/^[A-Za-z0-9]{1,6}$/)) {
    setNumberType(PLATE);
  } else {
    setNumberType('');
  }
};

export const validatePlateNumberOrBillingNumberOnInput = (
  plateNumberOrBillingNumberOnInput: any,
  setHasInputValidationError: any,
  setInputValidationErrorMessage: any
) => {
  plateNumberOrBillingNumberOnInput = removeShouldIgnoreCharacters(
    plateNumberOrBillingNumberOnInput
  );
  const plateNumberOrBillingNumberOnInputLength =
    plateNumberOrBillingNumberOnInput?.length;

  if (plateNumberOrBillingNumberOnInputLength < 1) {
    setHasInputValidationError(true);
    setInputValidationErrorMessage(
      FIND_VEHICLE_INVALID_INPUT_NO_NUMBER_ENTERED
    );
    return false;
  } else if (!plateNumberOrBillingNumberOnInput?.match('^[A-Za-z0-9]+$')) {
    setHasInputValidationError(true);
    setInputValidationErrorMessage(
      FIND_VEHICLE_INVALID_INPUT_SPECIAL_CHARACTER_ENTERED
    );
    return false;
  } else if (plateNumberOrBillingNumberOnInputLength > 8) {
    setHasInputValidationError(true);
    setInputValidationErrorMessage(
      FIND_VEHICLE_INVALID_INPUT_EXCEED_MAXIMUM_CHARACTERS
    );
    return false;
  } else if (
    (plateNumberOrBillingNumberOnInputLength === 7 ||
      plateNumberOrBillingNumberOnInputLength === 8) &&
    !plateNumberOrBillingNumberOnInput?.match('^[0-9]+$')
  ) {
    setHasInputValidationError(true);
    setInputValidationErrorMessage(
      FIND_VEHICLE_INVALID_PLATE_OR_BILLING_NUMBER_INPUT
    );
    return false;
  }
  return true;
};
