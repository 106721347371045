import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const CardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    max-width: 73.25rem;
    width: 100vw;
    
    .card {
        max-width: 17.3rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
    

    @media only screen and (max-width: ${tokens.breakpoints.md}px) {
        width: 100%;
    }
    
    @media only screen and (max-width: ${tokens.breakpoints.sm}px) {
        .card {
            max-width: 100%;
            margin-right: 0;
        }
    }
`
