import React from 'react';
import {
  TransactionDetailsContainer,
  TransactionPriceDetails,
  ChangeTermsAdditionalInfo,
} from './styled';
import { ITransactionDetailsSummary } from 'types/TransactionDetails';
import {
  InPageAlert
} from '@snsw-gel/react';
import getFormattedPrice from 'helpers/getFormattedPrice';
import ContentPanel from 'components/ContentPanel';

interface ITransactionDetailsProps {
  transactionDetails: ITransactionDetailsSummary;
  registrationTermMonths?: number;
}

function cleanString(s: string) {
  return s.replace(/[^\w!?]/g, '');
}

const TransactionDetails: React.FunctionComponent<ITransactionDetailsProps> = ({
  registrationTermMonths,
  transactionDetails,
}) => {
  const otherRegistrationTermMonths = registrationTermMonths === 12 ? 3 : 12;
  return (
    <TransactionDetailsContainer>
      <TransactionPriceDetails>
        {transactionDetails.items.map((item) =>
          item.amount >= 0 ? (
            <div className="positiveAmount" key={item.name.replace(' ', '')}>
              <div data-testid={`transactionName${cleanString(item.name)}`}>
                {item.name}
              </div>
              <div data-testid={`transactionAmount${cleanString(item.name)}`}>
                {getFormattedPrice(item.amount)}
              </div>
            </div>
          ) : (
            <div
              className="negativeAmount"
              key={item.name.replace(' ', '') + item.amount}
            >
              <div className="showDiscount"></div>
              <div
                data-testid={`transactionName${cleanString(item.name)}`}
                className="addMarginTopNBottom"
              >
                <span>&nbsp;&nbsp;{item.name}</span>
                <div
                  data-testid={`transactionAmount${cleanString(item.name)}`}
                  className="keepRight"
                >
                  {getFormattedPrice(item.amount)}
                </div>
              </div>
            </div>
          )
        )}
        <div className="positiveAmount">
          <div>
            <strong>
              Total renewal cost: <span>{registrationTermMonths} months</span>
            </strong>
          </div>
          <div>
            <strong className="totalAmount" data-testid="totalAmount">
              {getFormattedPrice(transactionDetails.totalAmount)}
            </strong>
          </div>
        </div>
      </TransactionPriceDetails>
      <ContentPanel
        id="canIChangeTheTerms"
        toggleButtonText="Can I change the term?"
        toggleButtonClass="canIChangeTheTerms"
        panelContentClass="canIChangeTheTermsPanel"
      >
        <section>
          <InPageAlert title='To change the term of this conditional registration, please
              contact Service NSW' variant='info'>
            <ChangeTermsAdditionalInfo>
              You can visit a{' '}
              <a
                href={'https://www.service.nsw.gov.au/service-centre'}
                target={'_blank'}
                rel='noopener noreferrer'
              >
                service centre
              </a>{' '}
              or call 13 77 88 if you want to change the term of a conditional
              registration to {otherRegistrationTermMonths} months if
              required.
            </ChangeTermsAdditionalInfo>
            <br />
          </InPageAlert>
        </section>
      </ContentPanel>
    </TransactionDetailsContainer>
  );
};

export default TransactionDetails;
