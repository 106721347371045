export const ctpDescriptions = [
  {
    code: 'PLNT',
    description:
      'Plant vehicles that are used temporarily or very infrequently on roads or road-related areas can be conditionally-registered or plant vehicle is used in a public place (eg a park).<br/><br/>' +
      'Plant vehicles cannot carry any load other than tools and accessories necessary for the operation of the vehicle.',
  },
  {
    code: 'FARM',
    description:
      'Vehicles that are used solely for farming proposes.<br/><br/>' +
      'Examples may include but not limited to:<br/>' +
      '2 or 3 wheel agricultural vehicles operated for farming purposes, All-terrain vehicles (ATVs) operated for farming purposes.',
  },
  {
    code: 'SVEH',
    description:
      'These vehicle types can include:' +
      '<ul>' +
      '<li>Purpose-built vehicle</li>' +
      '<li>Lawn Mower (over 250kg)</li>' +
      '<li>Historic vehicles (30 years of age or older)</li>' +
      '<li>Street Rod</li>' +
      '<li>Rally Vehicle</li>' +
      '<li>Classic Vehicle (30 years of age or older)</li>' +
      '</ul>' +
      "Historic, classic, street rod or rally vehicle you cannot renew online and must be done at a <a target='_blank' href='https://www.service.nsw.gov.au/service-centre'>service centre</a> or call 13 77 88.",
  },
  {
    code: 'RECG',
    description:
      'Defined as recreational all-terrain vehicles (ATV) and 2 or 3 wheel motor cycles﻿ with engine capacity greater than 300ml used on Stockton Beach Recreation Vehicle Area.',
  },
  {
    code: 'RECM',
    description:
      'Defined as recreational 2 or 3 wheel motor cycles with engine capacity 101 - 300ml used on Stockton Beach Recreation Vehicle Area.',
  },
  {
    code: 'RECL',
    description:
      'Defined as recreational 2 or 3 wheel motor cycles with engine capacity less than 100ml used on Stockton Beach Recreation Vehicle Area.',
  },
  {
    code: 'MVEH',
    description:
      'Defined as non-complying motorcycles with an engine capacity greater than 300ml operating within Kosciuszko National Park.',
  },
  {
    code: 'CYCM',
    description:
      'Defined as a non-complying motorcycle with a 101 - 300ml engine capacity.',
  },
];
export const ctpLabels = [
  {
    code: 'PLNT',
    label: 'Plant - Non Farm',
  },
  {
    code: 'FARM',
    label: 'Farm Vehicle',
  },
  {
    code: 'SVEH',
    label: 'Special Vehicle',
  },
  {
    code: 'RECG',
    label: 'General Recreation Vehicle',
  },
  {
    code: 'RECM',
    label: 'Recreational Cycle 101- 300ml',
  },
  {
    code: 'RECL',
    label: 'Recreational Cycle 100 ml or less',
  },
  {
    code: 'MVEH',
    label: 'Miscellaneous Vehicle',
  },
  {
    code: 'CYCM',
    label: 'Cycle 101 - 300ml',
  },
  {
    code: 'TRLR',
    label: 'Trailer',
  },
];

export const ctpLabel = (code: any) =>
  ctpLabels?.filter((ctpLabel) => ctpLabel?.code === code)[0]?.label;
