import {eligibilityFlagsResult} from "./eligibilityFlagsResult";

export const MultipleTermSorter = (
  validRenewalTermsInput: any
) => {
  let validRenewalTerms = Object.assign([], validRenewalTermsInput);
  validRenewalTerms?.sort((a: any, b: any) => {
    if (a.term < b.term) {
      return -1;
    }
    if (a.term > b.term) {
      return 1;
    }
    return 0;
  });
  validRenewalTerms?.forEach((validRenewalTerm: any) => {
    validRenewalTerm.eligibilityFlagsResult =
          eligibilityFlagsResult(validRenewalTerm);
  });
  return validRenewalTerms;
};
