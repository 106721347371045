import React, { useState } from 'react';
import { FormCheckbox } from '@snsw-gel/react';
import {
  DOC_ID_SELF_DECLARATION,
  ERROR_MESSAGE_DECLARATION,
} from '../../../../constants/constants';

const SelfDeclarationStatement = (props: any) => {
  const { handleAcceptSelfDeclaration, selfDeclarationValidationError } = props;
  const [isChecked, setChecked] = useState(false);

  const handleCheckbox = () => {
    const newValue = !isChecked;
    setChecked(newValue);
    let currentTime = new Date().getTime().toString();
    handleAcceptSelfDeclaration(newValue, currentTime);
  };

  return (
    <FormCheckbox
      id={DOC_ID_SELF_DECLARATION}
      name='FormCheckbox name'
      label='I am the registered operator of this vehicle or the agent registering the vehicle on behalf of the registered operator, there are no changes required to the operating conditions, CTP class or area of operation and I confirm the vehicle is suitable for safe use.'
      value='FormCheckbox value'
      errorMessage={ERROR_MESSAGE_DECLARATION}
      hasError={selfDeclarationValidationError}
      data-testid='selfDeclarationCheckBox'
      onChange={handleCheckbox}
      checked={isChecked}
    />
  );
};

export default SelfDeclarationStatement;
