import React, { useContext, useEffect } from 'react';
import ErrorLayout from './ErrorLayout';
import { useParams } from 'react-router-dom';
import {
  AnalyticsService,
  DisableTelOnTabletDesktop,
} from '@rmstransactions/components';
import { getErrorDetails } from '../../../helpers/error-processor';
import styled from 'styled-components';
import TimeoutPage from '../../TimeoutPage/TimeoutPage';
import { AppState, store } from 'providers/globalState';
import { getAccessibleMessage } from '../ErrorDetails.helpers';

const ErrorMessageLayout = styled.span`
  .top-spacing {
    padding-top: 6px;
  }
`;

const BusinessError = () => {
  const { globalState } = useContext(store) as {
    globalState: AppState;
  };
  const { errorCode }: { errorCode: string } = useParams();
  let errorDetails = getErrorDetails(errorCode);
  var { header, message, showRenewAnotherVehicle, messageHeadline } = {
    ...errorDetails,
  };

  message = getAccessibleMessage(message);

  showRenewAnotherVehicle = showRenewAnotherVehicle === false ? false : true;
  AnalyticsService.ErrorReport(
    'Business_error',
    errorCode,
    message,
    globalState.transactionStartTime
  );
  window.scrollTo(0, 0);

  useEffect(() => {
    document.title = 'Service NSW - Renew registration - Error';
  }, []);

  return (
    <>
      <TimeoutPage />
      <ErrorLayout
        heading={header ? header : 'Registration renewal incomplete'}
        showRenewAnotherVehicle={showRenewAnotherVehicle}
        messageHeadline={messageHeadline}
      >
        {message ? (
          <ErrorMessageLayout>
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </ErrorMessageLayout>
        ) : (
          <>
            We need to check a few details before we proceed. Please visit a{' '}
            <a href='https://www.service.nsw.gov.au/service-centre'>
              service centre
            </a>{' '}
            or call us on <DisableTelOnTabletDesktop phoneNumber='13 77 88' />.
          </>
        )}
      </ErrorLayout>
    </>
  );
};

export default BusinessError;
