import React from 'react';
import { Spacer } from '@rmstransactions/components';
import { Button,Heading } from '@snsw-gel/react';


const TollReliefDisplay = (props: any) => {
  let {
    doSfRedirect,
    plateNumber,
    registrationID,
    registrationScheme,
  }: {
    doSfRedirect: any;
    plateNumber: any;
    registrationID: any;
    registrationScheme: any;
  } = props;
  const handleClick = () => {
    doSfRedirect(plateNumber, registrationID, registrationScheme);
  };
  return (
    <>
      <Spacer mt={24} />
      <Heading level={2}>You can still claim a Toll Relief discount</Heading>
      <p>
        You’re eligible for a Toll Relief discount on this 12 month registration
        renewal.{' '}
      </p>
      <Button variant='arrow' external={true} onClick={handleClick}>
        Continue to Toll Relief
      </Button>
      <Spacer mt={24} />
    </>
  );
};

export default TollReliefDisplay;
