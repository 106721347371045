import { computeSafetyCheckFlag } from './ComputeSafetyCheckFlag';
import { isFlagsEligibleForRenewal } from './isFlagsEligibleForRenewal';

export const eligibilityFlagsResult = (validRenewalTerm: any) => {
  return {
    term: validRenewalTerm.term,
    ctpFlag: validRenewalTerm.ctpFlag,
    safetyInspectionFlag: validRenewalTerm.safetyInspectionFlag,
    hvInspectionFlag: validRenewalTerm.hvInspectionFlag,
    computedSafetyCheckFlag: computeSafetyCheckFlag(
      validRenewalTerm.safetyInspectionFlag,
      validRenewalTerm.hvInspectionFlag
    ),
    eligible: isFlagsEligibleForRenewal(validRenewalTerm),
  };
};

export const anyTermHaveAValidCTP = (validRenewalTerms: any) => {
  let anyTermWithValidCTP: boolean = false;
  validRenewalTerms?.forEach((validRenewalTerm: any) => {
    if (validRenewalTerm?.ctpFlag === 'V' || validRenewalTerm?.ctpFlag === 'N') {
      anyTermWithValidCTP = true;
    }
  });
  return anyTermWithValidCTP;
};
