import React from 'react';
import {
  InPageAlert,
} from '@snsw-gel/react';
import {
  isBpayAlertMsg,
  isBpayAlertMsgCtp,
  isBpayAlertMsgTill7Days,
} from '../../../../services/isBpayAlertMsg';
import { NoBPAYMessageContainer } from '../../../ConditionalSummaryPage/styled';
import { SCHEME_FULL } from '../../../../constants/constants';
import { Spacer } from '@rmstransactions/components';
const moment = require('moment');

const BPAYAlertMessages = (props: any) => {
  const {
    expiryDate,
    registrationScheme,
    ctpPolicy,
  }: { expiryDate: any; registrationScheme: any; ctpPolicy: any } = props;
  const cancellationDate = moment(expiryDate)
    .add(3, 'months')
    .locale('en-AU')
    .format('YYYY-MM-DD');
  return (
    <>
      {isBpayAlertMsg(expiryDate) ? (
        <Spacer mt={56} mb={36}>
          <NoBPAYMessageContainer>
            <InPageAlert variant="info" title="BPAY is unavailable for this renewal">
              This registration has expired and may be cancelled before a BPAY
              payment is processed. BPAY will not be available in the next
              step. BPAY can take up to 3 business days to process.
            </InPageAlert>
          </NoBPAYMessageContainer>
        </Spacer>
      ) : registrationScheme === SCHEME_FULL &&
        ctpPolicy &&
        isBpayAlertMsgCtp(ctpPolicy?.validUntilDate) ? (
        <Spacer mt={56} mb={36}>
          <NoBPAYMessageContainer>
            <InPageAlert variant="info" title="BPAY is unavailable for this renewal">
              BPAY will not be available in the next step because it can take
              up to 3 business days to process and your CTP has days or less
              until it expires.
            </InPageAlert>
          </NoBPAYMessageContainer>
        </Spacer>
      ) : registrationScheme === SCHEME_FULL &&
        ctpPolicy &&
        isBpayAlertMsgTill7Days(ctpPolicy?.validUntilDate) ? (
        <Spacer mt={56} mb={36}>
          <NoBPAYMessageContainer>
            <InPageAlert variant="info" title="Renew while BPAY is available">
              If you wish to use BPAY, complete your registration by{' '}
              {moment(ctpPolicy?.validUntilDate)
                .businessSubtract(4)
                .format('D MMMM')}
              . BPAY can take up to 3 business days to process and will only
              be available if you have more than 3 days until your CTP
              expires.
            </InPageAlert>
          </NoBPAYMessageContainer>
        </Spacer>
      ) : isBpayAlertMsgTill7Days(cancellationDate) ? (
        <Spacer mt={56} mb={36}>
          <NoBPAYMessageContainer>
            <InPageAlert variant="info" title="Renew while BPAY is available">
              If you wish to use BPAY, complete your registration by{' '}
              {moment(cancellationDate).businessSubtract(4).format('D MMMM')}.
              BPAY can take up to 3 business days to process and will only be
              available if you have more than 3 days until your registration
              is cancelled.
            </InPageAlert>
          </NoBPAYMessageContainer>
        </Spacer>
      ) : (
        <></>
      )}
    </>
  );
};
export default BPAYAlertMessages;
