import styled from 'styled-components';

export const TransactionDetailsContainer = styled.div`
  & .canIChangeTheTerms {
    padding-left: 0;
    margin-top: 8px;
  }
`;
export const TransactionPriceDetails = styled.div`
  & > div.positiveAmount {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;

    .showDiscount {
      float: left;
      height: 20px;
      width: 20px;
      margin-bottom: 15px;
      border: 1px solid black;
      clear: both;
      padding-left: 4px;
    }
  }

  & > div.negativeAmount {
    padding: 0 0 8px 0;
    display: inline;
    justify-content: space-between;

    .showDiscount {
      float: left;
      height: 17px;
      width: 17px;
      border-bottom: 2px solid rgb(222, 227, 229);
      clear: both;
      padding-left: 4px;
    }
    .addMarginTopNBottom {
      margin-top: 4px;
      margin-bottom: 4px;
    }
    .keepRight {
      float: right;
    }
  }

  .totalAmount {
    font-size: 20px;
  }
`;

interface PriceItemContainerProps {
  emphasisedFontSize?: boolean;
}

export const PriceItemContainer = styled.div<PriceItemContainerProps>`
  padding-right: 1.875rem;
  font-size: ${({ emphasisedFontSize }) =>
    emphasisedFontSize ? '1.125rem' : '1rem'};
`;

export const HeadingDetails = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
`;

export const NegativeAmountContainer = styled.span`
  padding-right: 30px;
`;
export const BoldMediumParagraph = styled.p`
  font-weight: bold;
  font-size: medium;
`;
