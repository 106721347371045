import React from 'react';
import { Modal } from '@snsw-gel/react';

const TermsModal = (props: any) => {
  const { closeTAndCs, acceptTAndCs } = props;
  return (
    <Modal
      title='Registration renewal Terms and Conditions'
      isScrollable={true}
      buttons={[
        { text: 'Accept', id: 'acceptTAndCs', onClick: acceptTAndCs },
        { text: 'Close', id: 'closeTAndCs', onClick: closeTAndCs },
      ]}
    >
      <div>
        <p>
          These Terms and Conditions, together with the Terms of Use, Copyright
          Statement and Privacy Statement published on the Service NSW website
          ("Terms"), set out the conditions that apply to your use of the
          service allowing online application for a vehicle registration ("the
          Service").
        </p>
        <p>
          By using the Service, you agree to comply with these Terms together
          with all relevant guidelines, instructions and procedures from Service
          NSW and Transport for NSW ("Instructions"). Nothing in these Terms
          limits or qualifies your rights or obligations under any legislation,
          including legislation relating to motor vehicles, traffic, roads or
          road transport.
        </p>
        <h3>Changing these Terms</h3>
        <p>
          Transport for NSW and Service NSW may change these Terms or their
          Instructions from time to time by republishing them online. You agree
          that by doing this, you have been provided with sufficient notice of
          the variation. You should read the Terms and Instructions each time
          before you use the Service in case they have changed. If you do not
          agree to any change, you should not continue to use the Service.
        </p>
        <h3>Preconditions – clear funds</h3>
        <p>
          You acknowledge and agree that your vehicle registration will not be
          renewed until the registration fee is received in clear funds. Your
          vehicle's registration may not be renewed if your funds have not
          cleared before the expiry date of your current registration. If you
          are paying via BPAY, you must allow 3 business days for Transport for
          NSW to receive your cleared funds. You warrant that you are entitled
          to use any credit card for which you have provided details.
        </p>
        <h3>Payment</h3>
        <p>
          Where payment is required, all amounts are stated in Australian
          dollars and are inclusive of GST. You acknowledge that if you do not
          pay the correct vehicle registration fees (including where your
          financial institution declines or reverses payment or has not made the
          payment because you have not complied with its terms and conditions),
          Transport for NSW may cancel your vehicle registration or Service NSW
          may not process your application.
        </p>
        <h3>Preconditions – correct information </h3>
        <p>
          You warrant that all information, including personal information, that
          you provide in applying for vehicle registration is accurate, true and
          complete and, to the extent that it includes information about other
          people or a corporation, you are authorised to disclose it to us for
          the purposes for which you have disclosed it.
        </p>
        <h3>Incorrect information </h3>
        <p>
          You are responsible for any additional expenses you may incur if you
          submit incorrect information and Transport for NSW or Service NSW act
          upon it. For example, if you submit an incorrect BPAY payment, your
          vehicle registration may not be renewed, or if you enter the wrong
          Transport for NSW billing number the registration for the wrong
          vehicle may be renewed.
        </p>
        <h3>Preconditions – concession eligibility declaration</h3>
        <p>
          If you are claiming a concession on your registration fees, you
          declare that you are eligible for that concession. You acknowledge and
          agree that your application for a concession is subject to Transport
          for NSW verifying your concession entitlement and you agree to
          promptly provide all necessary information to assist Transport for NSW
          with verification. If you do not do so, you acknowledge that Transport
          for NSW may cancel your vehicle registration.
        </p>
        <h3>Conditional Registration</h3>
        <p>
          If your vehicle is recorded as having Conditional Registration then
          you declare that:
        </p>
        <ol type='I'>
          <li>
            <span lang='EN'>
              You are the Registered Operator of the vehicle or the agent
              registering the vehicle on behalf of the Registered Operator;
            </span>
          </li>
          <li>
            <span lang='EN'>
              There are no changes to the Registered Operator’s personal
              details;
            </span>
          </li>
          <li>
            <span lang='EN'>
              There are no changes to the usage of the vehicle, or changes which
              would require Transport for NSW to amend the vehicle’s operating
              conditions;
            </span>
          </li>
          <li>
            <span lang='EN'>
              The vehicle complies with the applicable Conditional Registration
              Vehicle Sheet and the vehicle and all equipment and fittings are
              in safe operating condition (the vehicle sheets can be found
              online);
            </span>
          </li>
          <li>
            <span lang='EN'>
              There are no changes to the vehicle’s dimensions and/or weight
              limits (note: operators of oversize and overmass vehicles must
              also comply with the conditions of the relevant access notice or
              permit. For more information contact Special Permits Unit on 1300
              656 371 for intrastate permits, and the National Heavy Vehicle
              Regulator on 1300 696 487 for interstate permits);
            </span>
          </li>
          <li>
            <span lang='EN'>
              There is no change to the area of operation of the vehicle;
            </span>
          </li>
          <li>
            <span lang='EN'>
              The Registered Operator will be responsible for maintaining the
              vehicle in a safe operating condition until the expiry of the
              registration; and
            </span>
          </li>
          <li>
            <span lang='EN'>
              All information listed and provided is true and complete.
            </span>
          </li>
        </ol>
        <h3>Liability</h3>
        <p>
          You acknowledge and agree that subject to any responsibilities implied
          by law and which cannot be excluded, Service NSW and Transport for NSW
          are not liable to you for any losses, expenses, damages, liabilities
          and claims whatsoever, whether direct, indirect or consequential,
          arising out of or in reference to the use of the Service or its
          discontinuance, however caused (including your reliance on any
          inaccurate or incomplete information or data contained within or
          displayed by the Service) whether in contract, tort (including
          negligence), statute or otherwise.
        </p>
        <p>
          Service NSW and Transport for NSW’s liability for breach of a
          condition or warranty implied by law which cannot be excluded is
          limited to the extent possible to the resupply of the Service.
        </p>
        <h3>Indemnity</h3>
        <p>
          You indemnify Service NSW and Transport for NSW in respect of any
          liability, loss, damage, cost or expense suffered or incurred by
          Service NSW and/or Transport for NSW arising from or in connection
          with your breach of these Terms.
        </p>
        <h3>Security of Information</h3>
        <p>
          You are responsible for taking reasonable care in how you access the
          Service, including using appropriate firewalls and anti-virus
          software. No data transmission over the Internet can be guaranteed as
          totally secure and communications to this Service may be intercepted
          or altered in transit. Although Transport for NSW and Service NSW
          strive to protect such information, they do not represent or warrant
          and cannot ensure the security of any information which is transmitted
          when you use the Service. When using this Service you transmit and
          receive information at your own risk and Transport for NSW and Service
          NSW bear no liability.
        </p>
        <h3>No Warranty</h3>
        <p>You acknowledge and agree that:</p>
        <ol type='I'>
          <li>
            <span lang='EN'>
              from time to time the Service may be unavailable. Transport for
              NSW and Service NSW make no representation or warranty in relation
              to the information available on the Service, including without
              limitation, that the Service will be free from any delay in
              operation or transmission, virus, communications failure, internet
              access difficulties or malfunction in hardware and software.
            </span>
          </li>
          <li>
            <span lang='EN'>
              Service NSW does not endorse any entity or internet site linked to
              the Service or any third party products or services referred to on
              the Toll Relief Scheme Service site. By linking to a third party
              website Service NSW is not accepting responsibility for the
              content of that website or your use of it and makes no warranty as
              to its suitability. Use of any third party website is subject to
              the terms of that website.
            </span>
          </li>
        </ol>
        <h3>Age</h3>
        <p>
          You warrant that you are aged 18 or over. If you are under 18, you
          must use the Service only with the involvement of a parent or
          guardian.
        </p>
        <h3>No Waiver</h3>
        <p>
          Failure by Transport for NSW or Service NSW to enforce any of its
          rights at any stage does not constitute a waiver of those rights.
        </p>
        <h3>Severability</h3>
        <p>
          If any provision of these Terms shall be deemed unlawful, void or for
          any reason unenforceable, then that provision shall be deemed
          severable from these Terms and shall not affect the validity and
          enforceability of any remaining provisions.
        </p>
        <h3>Jurisdiction</h3>
        <p>
          These Terms and your use of the Service are governed by the laws of
          New South Wales, Australia. You agree to submit to the exclusive
          jurisdiction of the courts of New South Wales. A reference to any
          legislation includes all delegated legislation made under it,
          including regulations, rules and amendments, consolidations,
          replacements or re-enactments of any of them.
        </p>
        <h3>General</h3>
        <p>
          A reference to an entity, whether statutory or not, which ceases to
          exist or whose powers or functions are transferred to another body is
          a reference to the body which replaces it or which substantially
          succeeds to its powers or functions. Specifying anything in these
          Terms after the words 'include' or 'for example' or similar
          expressions does not limit what else is included.
        </p>
        <h3>Disclaimer</h3>
        <p>
          Any enquiries relating to the Toll Relief Scheme should be directed to
          Transport for NSW. Transport for NSW may have their own protocols,
          terms and conditions and disclaimers for the Toll Relief Scheme, which
          you should familiarise yourself with.
        </p>
        <h3>Complaints Handling and Feedback</h3>
        <p>
          Service NSW welcomes all forms of feedback, including complaints on
          its services. We view effective complaint management as part of our
          commitment to improving service provisions and recurring problems.
        </p>
        <p></p>
        <p>You may leave your feedback or make a complaint by:</p>
        <p>Calling: 13 77 88</p>
        <p>
          Emailing:{' '}
          <a href='mailto:info@service.nsw.gov.au'>info@service.nsw.gov.au</a>
        </p>
        <p>
          Visiting:{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.service.nsw.gov.au/contact-us'
          >
            https://www.service.nsw.gov.au/contact-us
          </a>
        </p>
      </div>
    </Modal>
  );
};

export default TermsModal;
