import React from 'react';
import { BrandIconLocked } from '@snsw-gel/react';
import * as Styled from './styled';

interface PrivacyDisclaimerProps {
  inline?: boolean;
}

const PrivacyDisclaimer: React.FC<PrivacyDisclaimerProps> = ({
  inline = false,
}) => {
  return (
    <Styled.PrivacyWrapper inline={inline}>
      <Styled.GridLayout inline={inline}>
        <BrandIconLocked size='lg' />
        <Styled.DisclaimerText>
          Service NSW delivers services on behalf of Transport for NSW. Your
          personal information is disclosed and verified with Transport for NSW
          as part of this transaction. To learn how your personal information is
          handled visit the Privacy Statements from{' '}
          <ExternalTextLink href='https://www.service.nsw.gov.au/privacy'>
            Service NSW
          </ExternalTextLink>{' '}
          and{' '}
          <ExternalTextLink href='https://www.transport.nsw.gov.au/privacy-statement'>
            Transport for NSW.
          </ExternalTextLink>
        </Styled.DisclaimerText>
      </Styled.GridLayout>
    </Styled.PrivacyWrapper>
  );
};

export default PrivacyDisclaimer;

const ExternalTextLink: React.FC<{ href: string }> = ({ href, children }) => (
  <a href={href} rel='noopener noreferrer' target='_blank'>
    {children}
  </a>
);
