import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppState, store } from '../../providers/globalState';
import {
  Constants,
  EmailInput,
  ErrorService,
  Header,
  helpers,
  KioskService,
  Spacer,
  validation,
  EmailConfirmationModal,
} from '@rmstransactions/components';
import { EnhancedVehicleCardDetails } from 'components';
import {
  Button,
  ComponentLoader,
  ContentContainer,
  ErrorSummary,
  SectionPage,
  Heading,
} from '@snsw-gel/react';
import { ErrorMessages } from '@snsw/react-component-library';
import { EmailContainer, Line, UsageContainer } from './styled';
import { ButtonGroup } from '../../styled';
import { useHistory } from 'react-router-dom';
import { renewal, validateRenewal } from 'services/RegistrationService';
import PriceDetails from './components/PriceDetails/PriceDetails';
import UsageType from './components/UsageType/UsageType';
import TermsAndConditions from '../../components/TermsAndConditions';
import TermsSkeleton from './components/Skeleton/TermsSkeleton';
import ChangeUsageSkeleton from './components/Skeleton/ChangeUsageSkeleton';
import GLItemsSkeleton from './components/Skeleton/GLItemsSkeleton';
import { debugLogger, errorLogger } from '../../services/LoggerService';
import {
  getErrorIdentifier,
  getWarningIdentifier,
  stripSuccessIfAny,
} from '../../helpers/error-processor';
import RegistrationConditions from '../ConditionalSummaryPage/components/RegistrationConditions';
import {
  IConditions,
  ICtpClass,
  IOperatingCondition,
} from '../../types/Conditions';
import SelfDeclarationStatement from './components/SelfDeclarationStatement/SelfDeclarationStatement';
import PrivacyDisclaimer from 'components/PrivacyDisclaimer/PrivacyDisclaimer';
import InformationSection from './components/InformationSection/InformationSection';
import { RenewRegoSession } from '../../session/RenewRegoSession';
import CTPAndTerms from './components/EligibilityFlags/CTPAndTerms';

import VEOSContribution from './components/VEOS/VEOSContribution/VEOSContribution';
import {
  DOC_ID_CHANGE_USAGE_APPLY_BTN,
  DOC_ID_PPG_DECLARATION,
  DOC_ID_SELF_DECLARATION,
  DOC_ID_T_AND_C_S,
  DOC_ID_T_AND_C_S_VEOS,
  DRUPAL_RENEW_REGISTRATION_URL,
  ERROR_MESSAGE_CONFIRM_CHANGE_USAGE,
  ERROR_MESSAGE_DECLARATION,
  ERROR_MESSAGE_TERMS_AND_CONDTIONS,
  ERROR_MESSAGE_TERMS_AND_CONDTIONS_VEOS,
  FULL_VALIDATION,
  INITIAL_QUOTE,
  PENSIONER_USAGE_TYPE_CODES,
  SCHEME_CONDITIONAL,
  SCHEME_FULL,
  TRANSACTION_STATE,
  ERROR_MESSAGE_EMAIL,
  AUTHENTICATED_LINKED_USER_TYPE,
} from '../../constants/constants';
import { gotoDrupal } from '../../helpers/commonHelper';
import { AllowedUsageType } from '../../types/AllowedUsageTypes';
import ChangeUsageSection from './components/UsageType/ChangeUsageSection';
import ChangeUsageDisplay from './components/UsageType/ChangeUsageDisplay';
import { isBpayAlertMsg, isBpayAlertMsgCtp } from 'services/isBpayAlertMsg';
import InlineNotification from '../ErrorPage/components/InlineNotification';
import { handleDRIVESSystemMessages } from '../ErrorPage/ErrorDetails.helpers';
import TimeoutPage from '../TimeoutPage/TimeoutPage';
import PrimaryProducerDeclaration from './components/UsageType/PrimaryProducerDeclaration';
import PrimaryProducerModal from './components/UsageType/PrimaryProducerModal';
import { IValidRenewalTerms } from '../../types/Eligibilities';
import TollReliefDisplay from './components/UsageType/TollReliefDisplay';
import TollReliefModal from './components/UsageType/TollReliefModal';
import { doSfRedirect } from '../../components/SfRedirect/SfRedirect';
import { getErrorMessageByCode } from '../../helpers/error-processor';
import BPAYAlertMessages from './components/BPAYAlertMessages/BPAYAlertMessages';
import { eligibilityFlagsResult } from '../../calculator/eligibilityFlagsResult';
import {
  canDisplayVEOSPanel,
  getVEOSFeatureEnabled,
  isCTPPolicyUpdatedInDRIVES,
  redirectToOrchestrationPage,
  veosEligibilityCheck,
} from './ReviewDetailsPage.helpers';
import { Config } from '../../config/env';
import VEOSPanel from './components/VEOS/VEOSPanel/VEOSPanel';

const ReviewDetailsPage = () => {
  KioskService.sendMessage();
  const history = useHistory();
  const { globalState, dispatch } = useContext(store) as {
    globalState: AppState;
    dispatch: any;
  };
  const { veosFeatureEnabled, apiHost, veosFeatureEnabledServiceCenterIDS } =
    Config;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);
  const [email, setEmail] = useState('');
  const [termsValidationError, setTermsValidationError] = useState(false);
  const [termsVEOSValidationError, setTermsVEOSValidationError] =
    useState(false);
  const [selfDeclarationValidationError, setSelfDeclarationValidationError] =
    useState(false);
  const [acceptTAndC, setAcceptTAndC] = useState(false);
  const [acceptTAndCVEOS, setAcceptTAndCVEOS] = useState(false);
  const [acceptSelfDeclaration, setAcceptSelfDeclaration] = useState(false);
  const [declarationTimestamp, setDeclarationTimestamp] = useState('');
  const [shouldContinueTransaction, setShouldContinueTransaction] =
    useState<boolean>(true);
  const [allowUsageChange, setAllowUsageChange] = useState(false);
  const [toggleChangeUsage, setToggleChangeUsage] = useState(false);
  const [toggleAddPensionerCard, setToggleAddPensionerCard] = useState(false);
  const [isPensioner, setIsPensioner] = useState(false);
  const [selectedUsage, setSelectedUsage] = useState('');
  const [inlineErrorOrWarnings, setInlineErrorOrWarnings] = useState([]);
  const [
    changeUsageInlineErrorOrWarnings,
    setChangeUsageInlineErrorOrWarnings,
  ] = useState([]);
  const [acceptPpgDeclaration, setAcceptPpgDeclaration] = useState(false);
  const [pPGDeclarationValidationError, setPPGDeclarationValidationError] =
    useState(false);
  const [showModalPPG, setShowModalPPG] = useState(false);
  const [isChangeUsageConfirmed, setIsChangeUsageConfirmed] = useState(true);
  const [showTollReliefModal, setShowTollReliefModal] = useState(false);
  const [showTollReliefOnCancel, setShowTollReliefOnCancel] = useState(false);
  const [isEligibleForTollRelief, setIsEligibleForTollRelief] = useState(false);
  const [allFieldsValid, setAllFieldsValid] = useState(true);
  const [errorsSummary, setErrorsSummary] = useState([]);
  const [allowedUsageTypes, setAllowedUsageTypes] = useState([]);
  const [validRenewalTerms, setValidRenewalTerms] = useState([]);
  const errorSummaryRef = useRef(null);
  const [carbonOffsetAmount, setCarbonOffsetAmount] = useState(null);
  const [isVeosEditSelected, setIsVeosEditSelected] = useState(false);
  const [isVeosRemoveSelected, setIsVeosRemoveSelected] = useState(false);
  const [activeVeosDollarButton, setActiveVeosDollarButton] = useState('');
  const [customCarbonOffsetAmount, setCustomCarbonOffsetAmount] =
    useState(null);
  const [dollarButtonValue, setDollarButtonValue] = useState('');
  const [customInputErrorMessage, setCustomInputErrorMessage] = useState('');
  const [hasError, setHasError] = useState('');
  const [hasEmailValidationError, setHasEmailValidationError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [confirmEmailModalOpen, setConfirmEmailModalOpen] =
    useState<boolean>(false);
  const usageTypeRef = useRef<HTMLDivElement | null>(null);

  let emailErrorMessageText = ErrorMessages.getErrorMessage('emailFormat');
  let billingNumber = '';
  let registrationScheme = '';
  let renewalTermInMonths = '';
  let operatingCondition: IOperatingCondition = { code: '', description: '' };
  let ctpClass: ICtpClass = { code: '', label: '' };
  let conditions: IConditions = {
    operatingConditions: [operatingCondition],
    specialConditions: [],
    ctpClass: ctpClass,
    area: '',
  };

  let {
    reviewDetailsInput,
    userDetails,
    validateData,
    usageType,
    selectedTerm,
    transactionStartTime,
    pensionerDetails,
    selectedCTPPolicy,
    userId,
  } = globalState;

  if (
    !reviewDetailsInput &&
    RenewRegoSession.getReviewInputDetails() !== null
  ) {
    redirectToOrchestrationPage();
  }
  if (RenewRegoSession.getValidateData() !== null) {
    validateData = JSON.parse(RenewRegoSession.getValidateData()!);
  }
  if (!userDetails && RenewRegoSession.getUserDetails() !== null) {
    userDetails = JSON.parse(RenewRegoSession.getUserDetails()!);
  }
  let registration = reviewDetailsInput?.registration;
  let eligibility = reviewDetailsInput?.eligibility;
  const plateNumber = registration?.plate?.plateNumber;
  const registrationID = registration?.registrationID;
  const plateType = registration?.plate?.plateType;
  const registrationExpiryDate = registration?.effectivePeriod?.expiryDate;
  const tareWeight = parseInt(registration?.registeredVehicle?.tareWeight);
  const isVehicleLightWeight =
    parseInt(registration?.registeredVehicle?.gvm) <= 4500;

  if (!helpers.isNotEmptyObject(reviewDetailsInput)) {
    history.push(`/error`);
  }

  if (
    !helpers.isNotEmptyObject(registration) ||
    !helpers.isNotEmptyObject(eligibility)
  ) {
    history.push(`/error`);
  }

  const isRegisteredOperator =
    userDetails?.type === AUTHENTICATED_LINKED_USER_TYPE &&
    userDetails.rmsCustomerId ===
      eligibility?.registrationEligibilityInfo?.customerID;
  if (helpers.isNotEmptyValue(registrationID)) {
    billingNumber = registrationID;
    registrationScheme = SCHEME_FULL;
  } else if (
    helpers.isNotEmptyObject(registration?.registeredVehicle) &&
    helpers.isNotEmptyValue(registration?.registeredVehicle?.vehicleID)
  ) {
    billingNumber = registration?.registeredVehicle?.vehicleID;
    registrationScheme = SCHEME_CONDITIONAL;
    renewalTermInMonths = helpers.ctpMonths(
      eligibility?.registrationEligibilityInfo?.conditionalRegoDetails[0]
        .currentTerm
    );
    conditions = {
      operatingConditions:
        eligibility?.registrationEligibilityInfo?.conditionalRegoDetails[0]
          ?.conditions?.conditionalRegistration?.conditionCode,
      specialConditions:
        eligibility?.registrationEligibilityInfo?.conditionalRegoDetails[0]
          ?.conditions?.conditionalRegistration?.specialCondition,
      ctpClass: registration.registrationUsage.usageType,
      area:
        eligibility?.registrationEligibilityInfo?.conditionalRegoDetails[0]
          ?.ctpPremiumLocationCategoryCode === 'C'
          ? 'Country'
          : 'Other',
    };
  }

  const isVeosEligible = veosEligibilityCheck(
    registrationScheme,
    registration?.registeredVehicle?.gvm,
    registration?.registeredVehicle?.vehicleType?.code,
    usageType?.code
  );

  const showVEOSPanel = canDisplayVEOSPanel(
    globalState.veosQueryParamValue,
    veosFeatureEnabledServiceCenterIDS,
    veosFeatureEnabled,
    isVeosEligible
  );

  const isVEOSFeatureEnabled = getVEOSFeatureEnabled(
    globalState.veosQueryParamValue,
    veosFeatureEnabledServiceCenterIDS,
    veosFeatureEnabled
  );

  const resetAmountsIfNotApplied = () => {
    if (carbonOffsetAmount === null) {
      setActiveVeosDollarButton('');
      setCustomCarbonOffsetAmount(null);
      setDollarButtonValue('');
      setCustomInputErrorMessage('');
      setHasError('');
    }
  };

  const pageUrl =
    window.location.protocol + window.location.hostname + '/review-details';

  const termInMonths = helpers.ctpMonths(selectedTerm);

  // Functions should start from here
  const selectedTermFlags = () => {
    const validRenewalTerm = validRenewalTerms?.filter(
      (el: IValidRenewalTerms) => {
        if (el.term === selectedTerm) {
          return el;
        }
        return null;
      }
    );
    let eligibilityFlags;
    if (validRenewalTerm && validRenewalTerm?.length > 0) {
      eligibilityFlags = eligibilityFlagsResult(validRenewalTerm[0]);
      if (eligibilityFlags) {
        return {
          ctpFlag: eligibilityFlags.ctpFlag,
          safetyCheckFlag: eligibilityFlags.computedSafetyCheckFlag,
          safetyOrCTPIsMissing:
            eligibilityFlags.computedSafetyCheckFlag === 'I' ||
            eligibilityFlags.ctpFlag === 'I',
        };
      }
    }
    return null;
  };

  const handleBack = () => {
    return history.push('/find-vehicle');
  };

  const continueButtonText = () => {
    if (isLoadingSkeleton) {
      return 'Loading...';
    } else if (!shouldContinueTransaction) {
      return 'Quit';
    } else {
      if (parseInt(renewalCost()) > 0) {
        return 'Continue to payment';
      } else {
        return 'Confirm renewal';
      }
    }
  };

  const handleShowTollReliefOnCancel = () => {
    setShowTollReliefOnCancel(true);
  };

  const saveEmailHandler = (enteredEmail: any) => {
    setEmail(enteredEmail);
  };

  const AcceptedTAndCHandler = (recievedAcceptTAndC: any) => {
    setAcceptTAndC(recievedAcceptTAndC);
    setTermsValidationError(!recievedAcceptTAndC);
  };

  const AcceptedTAndCVEOSHandler = (recievedAcceptTAndCVEOS: any) => {
    setAcceptTAndCVEOS(recievedAcceptTAndCVEOS);
    setTermsVEOSValidationError(!recievedAcceptTAndCVEOS);
  };

  const selfDeclarationHandler = (
    recievedAcceptSelfDeclaration: any,
    declarationTimeStamp: any
  ) => {
    setAcceptSelfDeclaration(recievedAcceptSelfDeclaration);
    setSelfDeclarationValidationError(!recievedAcceptSelfDeclaration);
    setDeclarationTimestamp(
      recievedAcceptSelfDeclaration ? declarationTimeStamp : ''
    );
  };

  const handleAcceptPPGDeclaration = (
    recievedAcceptPPGDeclaration: any,
    declarationTimeStamp: any
  ) => {
    setAcceptPpgDeclaration(recievedAcceptPPGDeclaration);
    setPPGDeclarationValidationError(!recievedAcceptPPGDeclaration);
    setDeclarationTimestamp(
      recievedAcceptPPGDeclaration ? declarationTimeStamp : ''
    );
  };

  const validInput = () => {
    let errors: any = [];

    if (selectedUsage !== '' && selectedUsage !== usageType?.description) {
      setIsChangeUsageConfirmed(false);
      setAllFieldsValid(false);
      errors.push({
        id: DOC_ID_CHANGE_USAGE_APPLY_BTN,
        text: ERROR_MESSAGE_CONFIRM_CHANGE_USAGE,
      });
    }

    if (
      isPPG() &&
      !acceptPpgDeclaration &&
      !selectedTermFlags()?.safetyOrCTPIsMissing
    ) {
      setPPGDeclarationValidationError(true);
      setAllFieldsValid(false);
      errors.push({
        id: DOC_ID_PPG_DECLARATION,
        text: ERROR_MESSAGE_DECLARATION,
      });
    }

    if (helpers.isNotEmptyValue(email) && !validation.email(email)) {
      setHasEmailValidationError(true);
      setEmailErrorMessage(emailErrorMessageText);
      setAllFieldsValid(false);
      errors.push({
        id: Constants.DOC_ID_EMAIL_INPUT,
        text: ERROR_MESSAGE_EMAIL,
      });
    }

    if (registrationScheme === SCHEME_CONDITIONAL && !acceptSelfDeclaration) {
      setSelfDeclarationValidationError(true);
      setAllFieldsValid(false);
      errors.push({
        id: DOC_ID_SELF_DECLARATION,
        text: ERROR_MESSAGE_DECLARATION,
      });
    }

    if (!acceptTAndC) {
      setTermsValidationError(true);
      setAllFieldsValid(false);
      errors.push({
        id: DOC_ID_T_AND_C_S,
        text: ERROR_MESSAGE_TERMS_AND_CONDTIONS,
      });
    }

    if (carbonOffsetAmount != null && !acceptTAndCVEOS) {
      setTermsVEOSValidationError(true);
      setAllFieldsValid(false);
      errors.push({
        id: DOC_ID_T_AND_C_S_VEOS,
        text: ERROR_MESSAGE_TERMS_AND_CONDTIONS_VEOS,
      });
    }

    setErrorsSummary(errors);
    return errors.length === 0;
  };

  const checkAndAddPensionerDetails = (request: any) => {
    if (PENSIONER_USAGE_TYPE_CODES.indexOf(usageType?.code) >= 0) {
      request.pensionerDetails = pensionerDetails;
    }
  };

  const handleClickRenewal = () => {
    if (!shouldContinueTransaction) {
      return gotoDrupal();
    }
    if (!validInput()) {
      return;
    }

    if (email) {
      setConfirmEmailModalOpen(true);
      return;
    }

    handlePostRenewalRego();
  };

  const handlePostRenewalRego = () => {
    let currentTime = new Date().getTime().toString();
    setDeclarationTimestamp(currentTime);
    setIsLoading(true);
    const validateRenewalRequest: any = {
      registrationID: eligibility?.registrationEligibilityInfo?.registrationID,
      plate: registration?.plate,
      registrationScheme:
        eligibility?.registrationEligibilityInfo?.registrationScheme,
      validationType: FULL_VALIDATION,
    };
    if (
      eligibility?.registrationEligibilityInfo?.registrationScheme ===
      SCHEME_CONDITIONAL
    ) {
      validateRenewalRequest.pensionerConcessionFlag =
        eligibility?.registrationEligibilityInfo?.conditionalRegoDetails[0]?.pensionerConcessionFlag;
    } else {
      validateRenewalRequest.ctpPolicy = helpers.isNotEmptyValue(registrationID)
        ? selectedCTPPolicy
        : null;
      validateRenewalRequest.registrationTerm = helpers.isNotEmptyValue(
        registrationID
      )
        ? selectedTerm
        : eligibility?.registrationEligibilityInfo?.conditionalRegoDetails[0]
            ?.currentTerm;
      validateRenewalRequest.registrationUsage = globalState.usageType;
      checkAndAddPensionerDetails(validateRenewalRequest);
    }
    const restrictedPaymentMethods =
      isBpayAlertMsg(
        reviewDetailsInput?.registration?.effectivePeriod?.expiryDate
      ) || isBpayAlertMsgCtp(selectedCTPPolicy?.validUntilDate)
        ? 'BPAY'
        : null;
    const renewalFee = renewalCost();

    const renewalRequest: any = {
      declarationTimestamp,
      email,
      amount: renewalFee,
      registration,
      eligibility: eligibility?.registrationEligibilityInfo,
      validationRequest: validateRenewalRequest,
      restrictedPaymentMethods,
      kioskId: KioskService.isKiosk() ? KioskService.getKioskId() : '',
    };
    if (isVEOSFeatureEnabled) {
      let veosData: any = {};
      if (carbonOffsetAmount != null) {
        veosData.eligibleForCarbonOffset = isVeosEligible;
        veosData.carbonOffsetAmount = carbonOffsetAmount;
      } else {
        veosData.eligibleForCarbonOffset = isVeosEligible;
      }
      renewalRequest.veosData = veosData;
    }

    debugLogger(
      'Need to pass pensioner details also in FullValidation call on change usage to Pensioner'
    );
    debugLogger('renewalRequest', renewalRequest);
    renewal(renewalRequest)
      .then((response) => {
        if (response?.data?.redirectUrl) {
          debugLogger(
            'redirectUrl found in /renewal response',
            response.data.redirectUrl
          );
          window.location.href = response.data.redirectUrl;
          return;
        }
        if (response?.data?.systemMessages?.message?.length > 0) {
          const drivesMessages = stripSuccessIfAny(
            response.data.systemMessages.message
          );
          if (
            getErrorIdentifier(drivesMessages) ||
            getWarningIdentifier(drivesMessages)
          ) {
            handleDRIVESSystemMessages(
              drivesMessages,
              'REVIEW_DETAILS_PAGE_SUBMIT',
              setInlineErrorOrWarnings,
              history
            );

            setShouldContinueTransaction(false);
            setIsLoading(false);
            return;
          }
        }
        errorLogger('response.data', response?.data);
        history.push('/error', 'Invalid Data Returned');
      })
      .catch((error) => {
        errorLogger(error);
        ErrorService.handleAPIError(
          error,
          history,
          apiHost,
          TRANSACTION_STATE,
          DRUPAL_RENEW_REGISTRATION_URL
        );
      });
    return;
  };

  const loadingText = (
    <span>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </span>
  );

  const validateRequest = () => {
    let request: any = {
      registrationID: eligibility?.registrationEligibilityInfo?.registrationID,
      plate: registration?.plate,
      registrationScheme:
        eligibility?.registrationEligibilityInfo?.registrationScheme,
      validationType:
        eligibility?.registrationEligibilityInfo?.registrationScheme ===
        SCHEME_FULL
          ? INITIAL_QUOTE
          : FULL_VALIDATION,
    };
    if (
      eligibility?.registrationEligibilityInfo?.registrationScheme ===
      SCHEME_CONDITIONAL
    ) {
      request.pensionerConcessionFlag =
        eligibility?.registrationEligibilityInfo?.conditionalRegoDetails[0]?.pensionerConcessionFlag;
    } else {
      checkAndAddPensionerDetails(request);
    }
    return request;
  };

  const renewalCost = () =>
    registrationScheme === SCHEME_FULL
      ? validateData.renewRegistrationValidation?.registrationPriceDetails?.filter(
          (price: { term: string }) => price.term === selectedTerm
        )[0]?.transactionPrice?.grossAmount
      : validateData.renewRegistrationValidation?.registrationPriceDetails[0]
          ?.priceItem[0]?.amount;

  const setupNewUsage = (validateRequest: any) => {
    setUsageInGlobalState(validateRequest?.registrationUsage);
    setUsageInSessionReviewDetails(validateRequest?.registrationUsage);
    if (
      PENSIONER_USAGE_TYPE_CODES.indexOf(
        validateRequest?.registrationUsage?.code
      ) >= 0
    ) {
      setPensionerDetailsInGlobalState(validateRequest?.pensionerDetails);
    }
    setIsChangeUsageConfirmed(true);
    const newUsage = selectedUsage.slice();
    setSelectedUsage('');
  };

  const setupSubmitButton = (
    drivesMessages: any,
    validRenewalTermsInput: any = null
  ) => {
    const isSelectedTermEligible = () => {
      const validRenewalTerm = validRenewalTermsInput?.filter(
        (el: IValidRenewalTerms) => {
          if (el.term === selectedTerm) {
            return el;
          }
          return null;
        }
      );
      let eligibilityFlags;
      if (validRenewalTerm && validRenewalTerm?.length > 0) {
        eligibilityFlags = eligibilityFlagsResult(validRenewalTerm[0]);
        if (eligibilityFlags) {
          return eligibilityFlags.eligible;
        }
      }
      return false;
    };
    if (
      getErrorIdentifier(drivesMessages) ||
      (selectedUsage === '' &&
        eligibility?.registrationEligibilityInfo?.eligibilityStatus === 'N') ||
      (selectedUsage !== '' && !isSelectedTermEligible())
    ) {
      setShouldContinueTransaction(false);
    } else {
      setShouldContinueTransaction(true);
    }
  };

  const setupAllowedUsageTypesAndRenewalTerms = (
    validRenewalTermsInput: any,
    allowedRegistrationUsageInput: any
  ) => {
    setValidRenewalTerms(validRenewalTermsInput);
    setAllowedUsageTypes(allowedRegistrationUsageInput);
    setupChangeUsageLinks(allowedRegistrationUsageInput);
  };

  const setupChangeUsageLinks = (allowedUsageTypesInput: any) => {
    setAllowUsageChange(false);
    setIsPensioner(false);
    if (allowedUsageTypesInput?.length > 0) {
      setAllowUsageChange(true);
      setIsPensioner(
        allowedUsageTypesInput.some((el: AllowedUsageType) => {
          if ('PNIC' === el?.code || 'PNSR' === el?.code) return true;
          else return false;
        })
      );
    }
  };

  const handleValidateData = (validateRequest: any = {}) => {
    validateData = JSON.parse(RenewRegoSession.getValidateData()!!);
    if (validateData?.systemMessages?.message?.length > 0) {
      const drivesMessages = stripSuccessIfAny(
        validateData.systemMessages.message
      );
      if (selectedUsage !== '') {
        if (getWarningIdentifier(drivesMessages)) {
          handleDRIVESSystemMessages(
            drivesMessages,
            'REVIEW_DETAILS_PAGE_CHANGE_USAGE',
            setChangeUsageInlineErrorOrWarnings,
            history
          );
          setupAllowedUsageTypesAndRenewalTerms(
            validateData?.renewRegistrationValidation?.validRenewalTerms,
            validateData?.renewRegistrationValidation?.allowedRegistrationUsage
          );
          setupSubmitButton(
            drivesMessages,
            validateData?.renewRegistrationValidation?.validRenewalTerms
          );
          setupNewUsage(validateRequest);
        } else {
          setToggleChangeUsage(false);
          setupAllowedUsageTypesAndRenewalTerms(
            validateData?.renewRegistrationValidation?.validRenewalTerms,
            validateData?.renewRegistrationValidation?.allowedRegistrationUsage
          );
          setupSubmitButton(
            drivesMessages,
            validateData?.renewRegistrationValidation?.validRenewalTerms
          );
          setupNewUsage(validateRequest);
        }
      } else {
        setupAllowedUsageTypesAndRenewalTerms(
          eligibility?.registrationEligibilityInfo?.validRenewalTerms,
          eligibility?.registrationEligibilityInfo?.allowedRegistrationUsage
        );
        setupSubmitButton(
          drivesMessages,
          eligibility?.registrationEligibilityInfo?.validRenewalTerms
        );
        handleDRIVESSystemMessages(
          drivesMessages,
          'REVIEW_DETAILS_PAGE_ONLOAD_INITIAL_QUOTE',
          setInlineErrorOrWarnings,
          history
        );
      }
    }
  };

  const setUsageInGlobalState = (usageType: any) => {
    dispatch({
      type: 'set_usageType',
      payload: usageType,
    });
  };

  const setUsageInSessionReviewDetails = (usageType: any) => {
    const sessionReviewDetails = JSON.parse(
      RenewRegoSession.getReviewInputDetails()!
    );
    const sessionUsageTypeCode =
      sessionReviewDetails?.registration?.registrationUsage?.usageType?.code;
    if (
      helpers.isNotEmptyObject(usageType) &&
      helpers.isNotEmptyObject(sessionReviewDetails) &&
      sessionUsageTypeCode !== usageType?.code
    ) {
      sessionReviewDetails.registration.registrationUsage.usageType = usageType;
      RenewRegoSession.setReviewInputDetails(
        JSON.stringify(sessionReviewDetails)
      );
    }
  };

  const setPensionerDetailsInGlobalState = (pensionerDetails: any) => {
    dispatch({
      type: 'set_pensionerDetails',
      payload: pensionerDetails,
    });
  };

  const updateValidateData = (request: any, response: any) => {
    RenewRegoSession.setValidateData(JSON.stringify(response.data));
    if (selectedUsage === '') {
      setIsLoadingSkeleton(false);
    } else {
      setIsLoading(false);
    }
    dispatch({
      type: 'set_validateData',
      payload: response.data,
    });
    handleValidateData(request);
  };

  const callValidateApi = (validateRequest: any) => {
    if (selectedUsage === '') {
      setIsLoadingSkeleton(true);
    } else {
      setIsLoading(true);
    }
    validateRenewal(validateRequest)
      .then((response: any) => {
        if (selectedUsage === '') {
          if (
            registrationScheme === SCHEME_FULL &&
            response?.data?.renewRegistrationValidation?.validRenewalTerms &&
            isCTPPolicyUpdatedInDRIVES(
              eligibility?.registrationEligibilityInfo?.validRenewalTerms,
              response?.data?.renewRegistrationValidation?.validRenewalTerms
            )
          ) {
            redirectToOrchestrationPage();
          } else {
            updateValidateData(validateRequest, response);
          }
        } else {
          let drivesMessages;
          if (response?.data?.systemMessages?.message?.length > 0) {
            drivesMessages = response?.data?.systemMessages?.message;
          }
          if (
            getErrorMessageByCode(
              drivesMessages,
              'SELECTED_USAGE_PNIC_BUT_NO_DISABILITY'
            ) &&
            validateRequest?.registrationUsage?.code === 'PNIC'
          ) {
            let currentUsage = {
              code: 'PNSR',
              description: 'Pensioner Concession',
            };
            validateRequest.registrationUsage = currentUsage;
            callValidateApi(validateRequest);
          } else if (getErrorIdentifier(drivesMessages)) {
            handleDRIVESSystemMessages(
              drivesMessages,
              'REVIEW_DETAILS_PAGE_CHANGE_USAGE',
              setChangeUsageInlineErrorOrWarnings,
              history
            );
            setIsLoading(false);
          } else {
            setInlineErrorOrWarnings([]);
            updateValidateData(validateRequest, response);
          }
        }
      })
      .catch((error: any) => {
        debugLogger('error ', error);
        window.scrollTo(0, 0);
        ErrorService.handleAPIError(
          error,
          history,
          apiHost,
          TRANSACTION_STATE,
          DRUPAL_RENEW_REGISTRATION_URL
        );
      });
  };

  useEffect(() => {
    document.title = 'Service NSW - Renew registration - Review details';
    if (errorsSummary?.length) {
      // @ts-ignore
      errorSummaryRef.current.focus();
    } else {
      if (isLoadingSkeleton) {
        window.scrollTo(0, 0);
        if (!usageType) {
          setUsageInGlobalState(registration.registrationUsage?.usageType);
        }
        setPensionerDetailsInGlobalState(
          eligibility?.registrationEligibilityInfo?.pensionerDetails
        );
        if (RenewRegoSession.getValidateData() === null) {
          if (registrationScheme === SCHEME_CONDITIONAL) {
            dispatch({
              type: 'set_selectedTerm',
              payload:
                eligibility?.registrationEligibilityInfo
                  ?.conditionalRegoDetails[0]?.currentTerm,
            });
          } else {
            setupAllowedUsageTypesAndRenewalTerms(
              eligibility?.registrationEligibilityInfo?.validRenewalTerms,
              eligibility?.registrationEligibilityInfo?.allowedRegistrationUsage
            );
          }
          callValidateApi(validateRequest());
        } else {
          setIsLoadingSkeleton(false);
          handleValidateData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsSummary]);

  useEffect(() => {
    if (
      isChangeUsageConfirmed &&
      usageType?.code !== registration?.registrationUsage?.usageType?.code &&
      usageTypeRef &&
      usageTypeRef.current
    ) {
      setTimeout(() => {
        usageTypeRef?.current?.focus();
      });
    }
  }, [usageTypeRef?.current, usageType?.code]);

  const isPPG = () => {
    const usageTypeCode = usageType?.code;
    return usageTypeCode === 'PPG' || usageTypeCode === 'PPGN';
  };

  const handleSfRedirect = (
    plateNumber: any,
    registrationID: any,
    registrationScheme: any
  ) => {
    debugLogger(
      'Plate number is toll relief eligible so redirecting to Salesforce'
    );
    doSfRedirect(plateNumber, registrationID, registrationScheme);
  };

  const contributionIsSet =
    carbonOffsetAmount != null && !isVeosEditSelected && !isVeosRemoveSelected;

  return (
    <>
      <TimeoutPage />
      <Header data-testid='header' title='Check your registration renewal' />
      <ContentContainer>
        <ComponentLoader fullPage label={loadingText} active={isLoading} />
        <SectionPage>
          <div style={{ maxWidth: '48rem' }}>
            {allFieldsValid === false && (
              <ErrorSummary
                id='fieldValidationErrorSummary'
                errors={errorsSummary}
                ref={errorSummaryRef}
                singularTitle='This page has an error'
                pluralTitle='This page has errors'
              />
            )}
            <EnhancedVehicleCardDetails
              plateNumber={plateNumber}
              billingNumber={billingNumber}
              registeredVehicle={registration?.registeredVehicle}
              expiryDate={registration.effectivePeriod.expiryDate}
              registrationScheme={registrationScheme}
              isRegisteredOperator={isRegisteredOperator}
            />
            {inlineErrorOrWarnings?.length > 0
              ? inlineErrorOrWarnings?.map((inlineErrorOrWarning: any) => {
                  return (
                    <InlineNotification
                      errorMessageObject={inlineErrorOrWarning}
                    />
                  );
                })
              : registrationScheme === SCHEME_CONDITIONAL && (
                  <InformationSection />
                )}
            {registrationScheme === SCHEME_FULL ? (
              <Spacer>
                {!toggleChangeUsage ? (
                  <UsageContainer>
                    {isLoadingSkeleton ? (
                      <ChangeUsageSkeleton />
                    ) : (
                      <UsageType
                        usageType={usageType}
                        allowedRegistrationUsage={allowedUsageTypes}
                        ref={usageTypeRef}
                      ></UsageType>
                    )}
                    {isLoadingSkeleton ? (
                      <ChangeUsageSkeleton />
                    ) : (
                      <ChangeUsageSection
                        allowUsageChange={allowUsageChange}
                        isPensioner={isPensioner}
                        setUsageChange={setToggleChangeUsage}
                        pensionerCard={toggleAddPensionerCard}
                        setPensionerCard={setToggleAddPensionerCard}
                        setSelectedUsage={setSelectedUsage}
                      ></ChangeUsageSection>
                    )}
                  </UsageContainer>
                ) : (
                  <Spacer mb={'24px'}>
                    <div>
                      {
                        <ChangeUsageDisplay
                          usageType={usageType}
                          allowedUsageTypes={allowedUsageTypes}
                          pensionerCard={toggleAddPensionerCard}
                          setUsageChange={setToggleChangeUsage}
                          callValidateApi={callValidateApi}
                          registration={registration}
                          eligibility={eligibility}
                          selectedUsage={selectedUsage}
                          setSelectedUsage={setSelectedUsage}
                          changeUsageInlineErrorOrWarnings={
                            changeUsageInlineErrorOrWarnings
                          }
                          setChangeUsageInlineErrorOrWarnings={
                            setChangeUsageInlineErrorOrWarnings
                          }
                          setShowModalPPG={setShowModalPPG}
                          ctpFlag={selectedTermFlags()?.ctpFlag}
                          safetyCheckFlag={selectedTermFlags()?.safetyCheckFlag}
                          safetyOrCTPIsMissing={
                            selectedTermFlags()?.safetyOrCTPIsMissing
                          }
                          isChangeUsageConfirmed={isChangeUsageConfirmed}
                          setIsChangeUsageConfirmed={setIsChangeUsageConfirmed}
                          setPensionerCard={setToggleAddPensionerCard}
                        />
                      }
                    </div>
                  </Spacer>
                )}
                <Line />
              </Spacer>
            ) : (
              <Spacer mt={24}>
                <RegistrationConditions
                  conditions={conditions}
                  transactionStartTime={transactionStartTime}
                />
              </Spacer>
            )}
            {showModalPPG && (
              <PrimaryProducerModal setShowModalPPG={setShowModalPPG} />
            )}
            {((isPPG() && !selectedTermFlags()?.safetyOrCTPIsMissing) ||
              (selectedUsage === 'Primary Producer' &&
                !selectedTermFlags()?.safetyOrCTPIsMissing &&
                isPPG())) && (
              <>
                <PrimaryProducerDeclaration
                  handleAcceptPPGDeclaration={handleAcceptPPGDeclaration}
                  pPGDeclarationValidationError={pPGDeclarationValidationError}
                  setShowModalPPG={setShowModalPPG}
                />
                <Line />
              </>
            )}
            {registrationScheme === SCHEME_FULL ? (
              <>
                <CTPAndTerms
                  ctpPolicies={
                    eligibility?.registrationEligibilityInfo?.ctpPolicy
                  }
                  validRenewalTerms={
                    validateData?.renewRegistrationValidation?.validRenewalTerms
                      ? validateData?.renewRegistrationValidation
                          ?.validRenewalTerms
                      : eligibility?.registrationEligibilityInfo
                          ?.validRenewalTerms
                  }
                  setShouldContinueTransaction={setShouldContinueTransaction}
                  isLoadingSkeleton={isLoadingSkeleton}
                  registrationPriceDetails={
                    validateData?.renewRegistrationValidation
                      ?.registrationPriceDetails
                  }
                />
              </>
            ) : (
              <Spacer mt={36}>
                <Heading level={2}>{renewalTermInMonths}-month renewal</Heading>
                Based on your previous renewal term
              </Spacer>
            )}

            {showTollReliefOnCancel &&
              !toggleChangeUsage &&
              isEligibleForTollRelief &&
              usageType?.description === 'Private' && (
                <>
                  <TollReliefDisplay
                    doSfRedirect={handleSfRedirect}
                    plateNumber={plateNumber}
                    registrationID={registrationID}
                    registrationScheme={registrationScheme}
                  />
                  <Line />
                </>
              )}
            {showTollReliefModal && (
              <TollReliefModal
                setShowTollReliefModal={setShowTollReliefModal}
                setToggleChangeUsage={setToggleChangeUsage}
                doSfRedirect={handleSfRedirect}
                handleShowTollReliefOnCancel={handleShowTollReliefOnCancel}
                plateNumber={plateNumber}
                registrationID={registrationID}
                registrationScheme={registrationScheme}
              />
            )}
            {isLoadingSkeleton ? (
              <>
                <GLItemsSkeleton />
                <Line />
              </>
            ) : (
              <Spacer mb={32}>
                {helpers.isNotEmptyObject(
                  validateData.renewRegistrationValidation
                    ?.registrationPriceDetails
                ) && (
                  <>
                    <PriceDetails
                      transactionDetails={
                        validateData.renewRegistrationValidation
                      }
                      registrationTermMonths={helpers.ctpMonths(selectedTerm)}
                      registrationTerm={selectedTerm}
                      reviewDetailsInput={reviewDetailsInput}
                      tareWeight={tareWeight}
                      registrationScheme={registrationScheme}
                      usageType={usageType}
                      selectedTerm={selectedTerm}
                      isVehicleLightWeight={isVehicleLightWeight}
                    />
                    <Spacer mb={32} />

                    {showVEOSPanel && (
                      <Spacer mb={1.5} mt={1.5}>
                        <div
                          style={{ display: contributionIsSet ? '' : 'none' }}
                        >
                          <VEOSContribution
                            renewalCost={renewalCost()}
                            carbonOffsetAmount={carbonOffsetAmount}
                            setIsVeosEditSelected={setIsVeosEditSelected}
                            setIsVeosRemoveSelected={setIsVeosRemoveSelected}
                            setCarbonOffsetAmount={setCarbonOffsetAmount}
                            setActiveVeosDollarButton={
                              setActiveVeosDollarButton
                            }
                            setCustomCarbonOffsetAmount={
                              setCustomCarbonOffsetAmount
                            }
                            setDollarButtonValue={setDollarButtonValue}
                            setCustomInputErrorMessage={
                              setCustomInputErrorMessage
                            }
                            setHasError={setHasError}
                          />
                        </div>
                        <div
                          style={{ display: contributionIsSet ? 'none' : '' }}
                        >
                          <VEOSPanel
                            setCarbonOffsetAmount={setCarbonOffsetAmount}
                            isVeosEditSelected={isVeosEditSelected}
                            setIsVeosEditSelected={setIsVeosEditSelected}
                            setActiveVeosDollarButton={
                              setActiveVeosDollarButton
                            }
                            activeVeosDollarButton={activeVeosDollarButton}
                            setCustomCarbonOffsetAmount={
                              setCustomCarbonOffsetAmount
                            }
                            customCarbonOffsetAmount={customCarbonOffsetAmount}
                            setIsVeosRemoveSelected={setIsVeosRemoveSelected}
                            setDollarButtonValue={setDollarButtonValue}
                            dollarButtonValue={dollarButtonValue}
                            customInputErrorMessage={customInputErrorMessage}
                            setCustomInputErrorMessage={
                              setCustomInputErrorMessage
                            }
                            hasError={hasError}
                            setHasError={setHasError}
                            resetAmountsIfNotApplied={resetAmountsIfNotApplied}
                            contributionIsSet={contributionIsSet}
                          />
                        </div>
                      </Spacer>
                    )}
                  </>
                )}
              </Spacer>
            )}

            {shouldContinueTransaction && (
              <>
                {!isRegisteredOperator && (
                  <>
                    <Spacer mt={31} />
                    <EmailContainer className='set-width'>
                      <EmailInput
                        onSaveEmailValue={saveEmailHandler}
                        hasEmailValidationError={hasEmailValidationError}
                        emailErrorMessage={emailErrorMessage}
                        defaultValue={userDetails?.snswEmailAddress}
                      />
                    </EmailContainer>
                    <Spacer mt={'33'} mb={56} />
                  </>
                )}

                <Line />
                <Spacer mt={56} />
                {registrationScheme === SCHEME_CONDITIONAL && (
                  <Spacer mt={31} mb={24}>
                    <p>
                      <strong>I declare that</strong>
                    </p>
                  </Spacer>
                )}
                {isLoadingSkeleton ? (
                  <Spacer mt={31}>
                    <TermsSkeleton registrationScheme={registrationScheme} />
                  </Spacer>
                ) : (
                  <>
                    {registrationScheme === SCHEME_CONDITIONAL && (
                      <SelfDeclarationStatement
                        handleAcceptSelfDeclaration={selfDeclarationHandler}
                        selfDeclarationValidationError={
                          selfDeclarationValidationError
                        }
                      />
                    )}
                    <Spacer mt={24} mb={24}>
                      <TermsAndConditions
                        onAcceptTAndC={AcceptedTAndCHandler}
                        termsValidationError={termsValidationError}
                        onAcceptTAndCVEOS={AcceptedTAndCVEOSHandler}
                        termsVEOSValidationError={termsVEOSValidationError}
                        isVEOSAmountContributed={carbonOffsetAmount !== null}
                      />
                    </Spacer>
                  </>
                )}

                {registrationScheme === SCHEME_CONDITIONAL && (
                  <p>
                    Making this declaration you understand that a false
                    declaration may result in criminal prosecution with maximum
                    penalties including imprisonment for up to 2 years and a
                    fine of up to $22,000.
                  </p>
                )}
              </>
            )}

            {!isLoadingSkeleton &&
              shouldContinueTransaction &&
              renewalCost() > 0 && (
                <BPAYAlertMessages
                  expiryDate={
                    reviewDetailsInput?.registration?.effectivePeriod
                      ?.expiryDate
                  }
                  registrationScheme={registrationScheme}
                  ctpPolicy={selectedCTPPolicy}
                />
              )}

            <Spacer mt={56} mb={56}>
              <PrivacyDisclaimer inline />
            </Spacer>
            <ButtonGroup>
              <Button
                onClick={handleClickRenewal}
                theme='primary'
                className='buttons button-first'
                data-testid='continueButton'
                disabled={isLoadingSkeleton}
              >
                {continueButtonText()}
              </Button>
              <Button
                theme='secondary'
                className='buttons button-n-plus-1'
                data-testid='backButton'
                onClick={() => handleBack()}
              >
                Back
              </Button>
            </ButtonGroup>
          </div>
        </SectionPage>
      </ContentContainer>
      <EmailConfirmationModal
        emailAddress={email}
        onCancel={() => setConfirmEmailModalOpen(false)}
        onConfirm={() => {
          setConfirmEmailModalOpen(false);
          handlePostRenewalRego();
        }}
        isOpen={confirmEmailModalOpen}
      />
    </>
  );
};

export default ReviewDetailsPage;
