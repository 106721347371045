import { getIsNationalPass, getNationalPassURL } from 'services/NationalParkPassService';
import {Button, BrandIconRebatesSavings} from "@snsw-gel/react";
import {NationalPassWrapper} from './styled';
import React from "react";
import {helpers, Spacer} from "@rmstransactions/components";
import {RenewRegoSession} from "../../session/RenewRegoSession";

export const NationalPass = (props: any) => {
  let { confirmationData }: { confirmationData: any } = props;
  let plateNumber = '';
  let plateType = '';
  const reviewInputDetails = RenewRegoSession.getReviewInputDetails()!;

  if (reviewInputDetails) {
    const reviewDetailsInput = JSON.parse(reviewInputDetails);
    let registration = reviewDetailsInput.registration;
    if (helpers.isNotEmptyObject(registration?.plate)) {
      plateNumber = registration.plate.plateNumber;
      plateType = registration.plate.plateType;
    }
  } else {
    plateNumber = confirmationData?.registration?.plateNumber;
    plateType = confirmationData?.registration?.plateType;
  }
  return (
    <>
      {getIsNationalPass(confirmationData) && (
        <NationalPassWrapper>
          <div>
            <BrandIconRebatesSavings />
          </div>
          <Spacer mb={1.5}>
            <Spacer mb={'1.5rem'}>
              <h3 style={{ fontSize: '1.35rem', marginBottom: '0.75rem' }}>
                Save $15 on NSW National Parks passes
              </h3>
              <p style={{ margin: 0 }}>
                Access your favourite parks all year round. This Annual Pass is
                for the vehicle owner's private recreation use only. Park entry
                fees may apply when entering a park in a vehicle being used for
                commercial activity, including the transport of paying
                customers.
              </p>
            </Spacer>
            <form
              action={getNationalPassURL()}
              method='POST'
              target='_blank'
              id='nationalPass'
            >
              <input type='hidden' name='rego' id='rego' value={plateNumber} />
              <input
                type='hidden'
                name='carType'
                id='carType'
                value={plateType}
              />
              <input type='hidden' name='state' id='state' value='NSW' />
            </form>
            <Button variant='link' form='nationalPass' type='submit'>
              Buy a pass
            </Button>
          </Spacer>
        </NationalPassWrapper>
      )}
    </>
  );
};
