import React from 'react';
import {
  SkeletonContainer,
  SkeletonShape,
} from '@snsw-gel/react';

const UsageSkeleton = () => {
  return (
    <SkeletonContainer>
      <span
        style={{
          float: 'right',
          width: '300px',
          marginTop: '4px',
        }}
      >
        <SkeletonShape height={16} width={300} noMargin />
      </span>
    </SkeletonContainer>
  );
};

export default UsageSkeleton;
