import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
} from '@snsw-gel/react';
import {
  InlineError,
} from '@snsw/react-component-library';
import { Spacer } from '@rmstransactions/components';
import {
  DollarButtonContainer,
  FormContainer,
  NonRefundableNoteContainer,
  ApplyButtonContainer,
  EnterAmountContainer,
  KnowMoreContainer,
  ErrorContainer,
  ApplyButtonErrorSmall,
} from '../../styled';
import { PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME } from '../../../../../../constants/constants';

const VEOSAccordionContent = (props: any) => {
  const {
    setCarbonOffsetAmount,
    setIsVeosEditSelected,
    setActiveButton,
    activeButton,
    setCustomCarbonOffsetAmount,
    customCarbonOffsetAmount,
    setIsVeosRemoveSelected,
    setDollarButtonValue,
    dollarButtonValue,
    customInputErrorMessage,
    setCustomInputErrorMessage,
    hasError,
    setHasError,
  } = props;

  const [inputTextSelected, setInputTextSelected] = useState('');
  const [applyButtonErrorMessage, setApplyButtonErrorMessage] = useState('');
  const [buttonError, setButtonError] = useState('');

  const maxPresetAmount =
    PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[
      PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME.length - 1
    ].amount;
  useEffect(() => {
    if (
      customCarbonOffsetAmount !== null ||
      customCarbonOffsetAmount !== '' ||
      dollarButtonValue !== ''
    ) {
      setApplyButtonErrorMessage('');
      setButtonError('');
    }
    if (
      customCarbonOffsetAmount === '' ||
      (customCarbonOffsetAmount >= 5 && customCarbonOffsetAmount <= 200)
    ) {
      setCustomInputErrorMessage('');
      setHasError('');
    }
  }, [
    dollarButtonValue,
    customCarbonOffsetAmount,
    setCustomInputErrorMessage,
    setHasError,
  ]);

  const handleInputOnBlur = () => {
    setInputTextSelected('');
    if (
      customCarbonOffsetAmount === null ||
      customCarbonOffsetAmount === '' ||
      typeof customCarbonOffsetAmount === 'undefined'
    ) {
      setCustomInputErrorMessage('');
    } else if (isNaN(customCarbonOffsetAmount)) {
      setCustomInputErrorMessage(
        "You've entered an amount that is not valid. Please try again."
      );
      setHasError('error');
    } else if (customCarbonOffsetAmount < 5) {
      setCustomInputErrorMessage(
        'Please enter a minimum amount of $5.00 and try again.'
      );
      setHasError('error');
    } else if (customCarbonOffsetAmount > 200) {
      setCustomInputErrorMessage(
        'Please enter an amount under $200 and try again.'
      );
      setHasError('error');
    }
  };

  const handleApplyVEOS = () => {
    setIsVeosEditSelected(false);
    setIsVeosRemoveSelected(false);
    const selectedDollarButton = document.querySelectorAll(
      '[class*="dollarButton active"]'
    );
    if (
      (dollarButtonValue === null || dollarButtonValue === '') &&
      (customCarbonOffsetAmount === null || customCarbonOffsetAmount === '')
    ) {
      setApplyButtonErrorMessage(
        'Please select or input an amount then click apply.'
      );
      setButtonError('error');
      setIsVeosEditSelected(true);
    }

    if (customInputErrorMessage === null || customInputErrorMessage === '') {
      if (selectedDollarButton.length > 0) {
        setCarbonOffsetAmount(
          selectedDollarButton.item(0).getAttribute('value')
        );
      } else if (
        customCarbonOffsetAmount !== null ||
        customCarbonOffsetAmount !== ''
      ) {
        setCarbonOffsetAmount(customCarbonOffsetAmount);
      } else {
        setCarbonOffsetAmount(null);
        setIsVeosEditSelected(true);
      }
    } else {
      setCarbonOffsetAmount(null);
      setIsVeosEditSelected(true);
    }
  };

  const checkActive = (index: any, className: any) =>
    activeButton === index ? className : '';

  const handleDollarButtonClick = (idx: any, e: any) => {
    setActiveButton(idx);
    setDollarButtonValue(e.currentTarget.value);
    setCustomCarbonOffsetAmount(null);
    setHasError('');
    setCustomInputErrorMessage('');
  };

  const handleInputClick = () => {
    setInputTextSelected('selected');
  };

  const handleInputBoxOnChange = (e: any) => {
    var amount = e.target.value.substring(0, 6);
    const dotIndex = amount.indexOf('.');
    if (amount.length >= 4 && dotIndex >= 4) {
      amount = amount.substring(0, 3);
    }
    if (dotIndex >= 0) {
      if (dotIndex <= 3) {
        amount = amount.substring(0, dotIndex + 3);
      } else {
        return;
      }
    }
    setCustomCarbonOffsetAmount(amount);
    setActiveButton('');
    setDollarButtonValue('');
  };

  return (
    <>
      <Spacer mb={[1.5, 1.5]} mt={[1.0, 1.0]}>
        <Row alignItems='baseline'>
          <Col span={7}>
            <DollarButtonContainer>
              {PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME.map(
                (element: any, idx: any) => {
                  return (
                    <Button
                      variant='secondary'
                      className={`dollarButton ${checkActive(idx, 'active')}`}
                      onClick={(e: any) => handleDollarButtonClick(idx, e)}
                      value={element.amount}
                      data-testid={`dollarButton ${idx}`}
                    >
                      ${element.amount}
                    </Button>
                  );
                }
              )}
            </DollarButtonContainer>
          </Col>

          <Col span={5}>
            <ApplyButtonContainer>
              <Button
                variant='primary'
                onClick={handleApplyVEOS}
                className={`applyButton lg ${buttonError}`}
                data-testid='carbonOffsetApplyButton'
              >
                Apply
              </Button>
            </ApplyButtonContainer>
          </Col>
        </Row>
      </Spacer>
      <Spacer mt={[1.5, 1]}>
        <EnterAmountContainer>
          <span className='enterAmountText'>
            Select or enter an amount (from $5-$200) then apply.
          </span>
          <span className='inputBoxContainer'>
            <FormContainer>
              <FormGroup
                className={`formGroup ${inputTextSelected} ${hasError}`}
              >
                <Input
                  type='text'
                  data-testid='veosCustomDollarInput'
                  className={`inputTextBox ${inputTextSelected} ${hasError}`}
                  onBlur={handleInputOnBlur}
                  onClick={handleInputClick}
                  prefix='$'
                  name='customCarbonOffsetAmount'
                  value={customCarbonOffsetAmount || ''}
                  onChange={handleInputBoxOnChange}
                />
              </FormGroup>
            </FormContainer>
          </span>
          <span className='applyButtonError md'>
            <InlineError
              data-testid='applyButtonErrorMessage'
              errorMessage={applyButtonErrorMessage}
            />
          </span>
        </EnterAmountContainer>
        <ErrorContainer>
          <ApplyButtonErrorSmall>
            <InlineError
              data-testid='applyButtonErrorMessage'
              errorMessage={applyButtonErrorMessage}
            />
          </ApplyButtonErrorSmall>
          <InlineError
            data-testid='customInputErrorMessage'
            errorMessage={customInputErrorMessage}
          />
        </ErrorContainer>
        <ApplyButtonContainer>
          <Button
            variant='primary'
            onClick={handleApplyVEOS}
            className={`applyButton sm ${buttonError}`}
            data-testid='applyButton'
          >
            Apply
          </Button>
        </ApplyButtonContainer>
        <EnterAmountContainer>
          <span className='applyButtonError sm'>
            <InlineError errorMessage={applyButtonErrorMessage} />
          </span>
        </EnterAmountContainer>
      </Spacer>
      <Row>
        <NonRefundableNoteContainer>
          {' '}
          This voluntary contribution is non-refundable and separate to your
          renewal fees.
        </NonRefundableNoteContainer>
      </Row>
      <KnowMoreContainer>
        <span>
          Contributing ${maxPresetAmount} will offset 100% of an average
          vehicle's carbon emissions in NSW. If you would like to learn more
          visit the{' '}
          <a
            href='https://www.energy.nsw.gov.au/vehicle-emissions-offset-scheme'
            target='_blank'
            rel='noopener noreferrer'
          >
            Office of Energy and Climate Change.
          </a>
        </span>
      </KnowMoreContainer>
    </>
  );
};

export default VEOSAccordionContent;
