import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ConfirmationPage from 'pages/ConfirmationPage/ConfirmationPage';
import { ConditionalRegistrationSummaryPage } from 'pages/ConditionalSummaryPage';
import LandingPage from 'pages/LandingPage';
import {
  RMSGlobalStyle,
  MyAccountNav,
  MyAccountNavConfig,
} from '@rmstransactions/components';
import { GlobalNav } from '@snsw/react-global-nav';
import { theme, GlobalStyle } from '@snsw-gel/react';
import { ThemeProvider } from 'styled-components';
import GoogleAnalyticsMonitor from 'components/GoogleAnalyticsMonitor/GoogleAnalyticsMonitor';
import ErrorPage from 'pages/ErrorPage';
import FindVehiclePage from 'pages/FindVehiclePage';
import GlobalStateProvider from 'providers/globalState';
import BPayInformationPage from './pages/BPayInformationPage';
import { Config } from './config/env';
import {
  confirmationRedirectPath,
  myAccountRedirectConditionalRegoPath,
  myAccountRedirectWithPlateTypePath,
  myAccountRedirectWithoutPlateTypePath,
  viewReceiptUuidPath,
} from 'constants/paths';
import ReviewDetailsPage from './pages/ReviewDetailsPage/ReviewDetailsPage';
import ConfirmationRedirect from 'pages/ConfirmationRedirect/ConfirmationRedirect';

const { globalNavEnv } = Config;
MyAccountNavConfig.setupGlobalNavUri(globalNavEnv);
MyAccountNavConfig.setLoggedInUser();

const App = () => (
  <>
    <RMSGlobalStyle />
    <GlobalNav accountNavLinks={<MyAccountNav />}>
      <GlobalStateProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <GoogleAnalyticsMonitor />
            <Switch>
              <Route path='/error'>
                <ErrorPage />
              </Route>
              <Route path={myAccountRedirectConditionalRegoPath}>
                <ConditionalRegistrationSummaryPage />
              </Route>
              <Route path='/bpay-info'>
                <BPayInformationPage />
              </Route>
              <Route path={viewReceiptUuidPath}>
                <ConfirmationPage />
              </Route>
              <Route path='/view-receipt'>
                <ConfirmationPage />
              </Route>
              <Route path={confirmationRedirectPath}>
                <ConfirmationRedirect />
              </Route>
              <Route path='/confirmation'>
                <ConfirmationPage />
              </Route>
              <Route path='/' exact={true}>
                <LandingPage />
              </Route>
              <Route path='/find-vehicle'>
                <FindVehiclePage />
              </Route>
              <Route path='/find-vehicle?veos=true'>
                <FindVehiclePage />
              </Route>
              <Route path={myAccountRedirectWithPlateTypePath}>
                <FindVehiclePage />
              </Route>
              <Route path={myAccountRedirectWithoutPlateTypePath}>
                <FindVehiclePage />
              </Route>
              <Route path='/renew-rego-orchestration/*'>
                <FindVehiclePage />
              </Route>
              <Route path='/review-details'>
                <ReviewDetailsPage />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </GlobalStateProvider>
    </GlobalNav>
  </>
);

export default App;
