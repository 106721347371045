import { eligibilityFlagsResult } from './eligibilityFlagsResult';

export const MultipleCTPSorter = (
  ctpPolicyInput: any,
  validRenewalTermsInput: any
) => {
  let ctpPolicies = Object.assign([], ctpPolicyInput);
  let validRenewalTerms = Object.assign([], validRenewalTermsInput);
  ctpPolicies?.sort((a: any, b: any) => {
    if (a.policyTerm < b.policyTerm) {
      return -1;
    }
    if (a.policyTerm > b.policyTerm) {
      return 1;
    }
    return 0;
  });
  ctpPolicies?.forEach((ctpPolicy: any, index: number) => {
    validRenewalTerms?.forEach((validRenewalTerm: any, index: number) => {
      if (ctpPolicy.policyTerm === validRenewalTerm.term) {
        ctpPolicy.eligibilityFlagsResult =
          eligibilityFlagsResult(validRenewalTerm);
      }
    });
  });
  ctpPolicies.sort((a: any, b: any) =>
    a.eligibilityFlagsResult?.eligible === b.eligibilityFlagsResult?.eligible
      ? 0
      : a.eligibilityFlagsResult?.eligible
      ? -1
      : 1
  );
  ctpPolicies?.sort((a: any, b: any) => {
    if (a.policyTerm === b.policyTerm) {
      if (a.validUntilDate > b.validUntilDate) {
        return -1;
      }
      if (a.validUntilDate < b.validUntilDate) {
        return 1;
      }
    }
    return 0;
  });
  return ctpPolicies;
};
