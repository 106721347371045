import React, { useRef, useEffect } from 'react';
import {
  VEOSContainer,
  HeadingContainer,
  VEOSPanelContentDes,
  VEOSContainerContent,
  VEOSVideoContainer,
  VEOSAccordianContainer,
} from '../styled';
import VEOSAccordionContent from './components/VEOSAccordionContent';
import { Accordion } from '@rmstransactions/components';
import { PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME } from '../../../../../constants/constants';

const VEOSPanel = (props: any) => {
  const {
    setCarbonOffsetAmount,
    isVeosEditSelected,
    setIsVeosEditSelected,
    setActiveVeosDollarButton,
    activeVeosDollarButton,
    customCarbonOffsetAmount,
    setCustomCarbonOffsetAmount,
    setIsVeosRemoveSelected,
    dollarButtonValue,
    setDollarButtonValue,
    customInputErrorMessage,
    setCustomInputErrorMessage,
    hasError,
    setHasError,
    resetAmountsIfNotApplied,
    contributionIsSet,
  } = props;

  const prevDollarButtonValue = useRef('');
  const videoRef = useRef(null);

  useEffect(() => {
    prevDollarButtonValue.current = dollarButtonValue;
  }, [dollarButtonValue]);

  useEffect(() => {
    var videoRefElement: any = videoRef.current;
    if (videoRefElement !== null) {
      let dollarAmountIndex =
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME.findIndex(
          (value: any) => value.amount === dollarButtonValue
        );
      dollarAmountIndex = dollarAmountIndex >= 0 ? dollarAmountIndex : 0;
      videoRefElement!!.currentTime =
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[dollarAmountIndex].time;
      videoRefElement.disableRemotePlayback = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let startTime: any;
  let endTime: any;
  let videoSrc: any = 'videos/trees.mp4';

  const calculateStartEndTime = () => {
    const isCustomFiveToTen =
      customCarbonOffsetAmount >= 5 && customCarbonOffsetAmount <= 10;
    const isCustomTenToTwenty =
      customCarbonOffsetAmount > 10 && customCarbonOffsetAmount <= 20;
    const isCustomTwentyToForty =
      customCarbonOffsetAmount > 20 && customCarbonOffsetAmount <= 40;
    const isCustomFortyToFifty =
      customCarbonOffsetAmount > 40 && customCarbonOffsetAmount <= 50;
    const isCustomFiftyToSixty =
      customCarbonOffsetAmount > 50 && customCarbonOffsetAmount <= 60;
    const isCustomSixtyToSeventy =
      customCarbonOffsetAmount > 60 && customCarbonOffsetAmount <= 70;
    const isCustomSeventyPlus =
      customCarbonOffsetAmount > 70 && customCarbonOffsetAmount <= 200;

    switch (true) {
      //for first
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[0].amount &&
        prevDollarButtonValue.current === '':
        startTime = 0;
        endTime = 1.3;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[0].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[1].amount:
        startTime = 7.6;
        endTime = 8.2;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[0].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[2].amount:
        startTime = 6.5;
        endTime = 8.2;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[0].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[3].amount:
        startTime = 5;
        endTime = 8.2;
        break;
      //for second
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[1].amount &&
        prevDollarButtonValue.current === '':
        startTime = 0;
        endTime = 1.8;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[1].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[0].amount:
        startTime = 1.3;
        endTime = 1.8;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[1].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[2].amount:
        startTime = 6.5;
        endTime = 7.6;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[1].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[3].amount:
        startTime = 5;
        endTime = 7.6;
        break;
      //for third
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[2].amount &&
        prevDollarButtonValue.current === '':
        startTime = 0;
        endTime = 3;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[2].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[0].amount:
        startTime = 1.3;
        endTime = 3;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[2].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[1].amount:
        startTime = 1.8;
        endTime = 3;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[2].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[3].amount:
        startTime = 5;
        endTime = 6.5;
        break;
      //for forth
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[3].amount &&
        prevDollarButtonValue.current === '':
        startTime = 0;
        endTime = 4.5;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[3].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[0].amount:
        startTime = 1.3;
        endTime = 4.5;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[3].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[1].amount:
        startTime = 1.8;
        endTime = 4.5;
        break;
      case dollarButtonValue ===
        PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[3].amount &&
        prevDollarButtonValue.current ===
          PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME[2].amount:
        startTime = 3;
        endTime = 4.5;
        break;
      //for custom amount
      case isCustomFiveToTen:
        startTime = 0.0;
        endTime = 0.5;
        break;
      case isCustomTenToTwenty:
        startTime = 0;
        endTime = 1.3;
        break;
      case isCustomTwentyToForty:
        startTime = 0.5;
        endTime = 1.7;
        break;
      case isCustomFortyToFifty:
        startTime = 0.5;
        endTime = 2.1;
        break;
      case isCustomFiftyToSixty:
        startTime = 0.6;
        endTime = 3;
        break;
      case isCustomSixtyToSeventy:
        startTime = 0.5;
        endTime = 3.5;
        break;
      case isCustomSeventyPlus:
        startTime = 0.5;
        endTime = 4.5;
        break;
      default:
        startTime = 1.2;
        endTime = 1.3;
    }
    var videoRefElement: any = videoRef.current;
    if (videoRefElement !== null && videoRefElement !== undefined) {
      videoRefElement!!.currentTime = startTime;
      const playPromise = videoRefElement.play();
      if (playPromise !== undefined && typeof playPromise.then === 'function') {
        playPromise.then(() => {
          setTimeout(() => {
            videoRefElement!!.pause();
            videoRefElement!!.currentTime = endTime;
          }, (endTime - startTime) * 1000);
        }, false);
      }
    }
  };

  const isFirstInstance =
    prevDollarButtonValue.current === '' && dollarButtonValue === '';
  if (prevDollarButtonValue.current !== dollarButtonValue || isFirstInstance) {
    calculateStartEndTime();
  }

  const showExpanded = Boolean(!contributionIsSet || isVeosEditSelected);

  return (
    <VEOSContainer>
      <VEOSContainerContent>
        <div>
          <HeadingContainer>
            Reduce your environmental footprint
          </HeadingContainer>
          <VEOSPanelContentDes>
            <p>
              Offset your vehicle's carbon emissions and contribute to a more
              sustainable future.{' '}
            </p>
          </VEOSPanelContentDes>
        </div>
        <VEOSVideoContainer>
          <video
            id='treesVideo'
            data-testid='video'
            src={videoSrc}
            ref={videoRef}
            width='270'
            playsInline
            muted
          >
            <p>
              Your browser doesn't support HTML video. Here is a
              <a href={videoSrc}>link to the video</a> instead.
            </p>
          </video>
        </VEOSVideoContainer>
      </VEOSContainerContent>
      {showExpanded && (
        <VEOSAccordianContainer>
          <Accordion
            accordionTitle='Make a carbon offset contribution'
            isVeosEditSelected={isVeosEditSelected}
            resetAmountsIfNotApplied={resetAmountsIfNotApplied}
            accordionContent={
              <VEOSAccordionContent
                setCarbonOffsetAmount={setCarbonOffsetAmount}
                setIsVeosEditSelected={setIsVeosEditSelected}
                setActiveButton={setActiveVeosDollarButton}
                activeButton={activeVeosDollarButton}
                customCarbonOffsetAmount={customCarbonOffsetAmount}
                setCustomCarbonOffsetAmount={setCustomCarbonOffsetAmount}
                setIsVeosRemoveSelected={setIsVeosRemoveSelected}
                setDollarButtonValue={setDollarButtonValue}
                dollarButtonValue={dollarButtonValue}
                customInputErrorMessage={customInputErrorMessage}
                setCustomInputErrorMessage={setCustomInputErrorMessage}
                hasError={hasError}
                setHasError={setHasError}
              />
            }
          />
        </VEOSAccordianContainer>
      )}
    </VEOSContainer>
  );
};

export default VEOSPanel;
