import styled from 'styled-components';
import { Size } from '@rmstransactions/components/Styled';

export const SafetyCheckContainer = styled.div`
  padding: 32px 0px 32px 0px;
`;

export const RenewalTermContainer = styled.div`
  padding: 32px 0px 32px 0px;
`;

export const SelectUsageContainer = styled.div`
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  width: 116px;
  color: rgb(41, 47, 60);
`;

export const Line = styled.div`
  border-top: 2px solid rgb(222, 227, 229);
`;

export const UsageContainer = styled.div`
  padding: 1.5rem 0rem 2rem 0rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const EmailContainer = styled.div`
  @media only screen and (min-width: ${Size.md + 1}px) {
    .field-item {
      width: 430px;
    }
  }

  @media only screen and (max-width: ${Size.md}px) {
    .field-item {
      width: 100%;
    }
  }
`;

export const UsageListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 4px;
  .field-item {
    margin-top: 0px;
  }
  @media only screen and (min-width: ${Size.sm + 1}px) {
    .select-item {
      width: 374px;
    }
  }
  @media only screen and (max-width: ${Size.sm}px) {
    .select-item {
      width: 100%;
    }
    #FormSelectIDRequired {
      max-width: 100%;
    }
  }

  .hideOnSmallerDevice {
    @media only screen and (max-width: ${Size.sm}px) {
      display: none;
    }
  }
  .cancelButton {
    @media only screen and (min-width: ${Size.sm + 1}px) {
      margin-left: 17px;
      margin-top: 20px;
    }
  }
`;

export const PipeContainer = styled.span`
   {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
export const Heading = styled.span`
  @media only screen and (max-width: ${Size.sm}px) {
    .keepLinkRight {
      float: right;
    }
  }

  @media only screen and (min-width: ${Size.sm + 1}px) {
    .hideOnLargerDevice {
      display: none;
    }
  }
`;

export const SkeletonShapeContainer = styled.div`
  margin-right: 30px;
  width: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
`;
