import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import pushAnalyticsNewPage from 'session/pushAnalyticsNewPage';

const GoogleAnalyticsMonitor: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    pushAnalyticsNewPage();

    history.listen(delayPushAnalytics);
  }, [history]);

  return null;
};

export default GoogleAnalyticsMonitor;

const delayPushAnalytics = () => {
  setTimeout(pushAnalyticsNewPage, 100);
};
