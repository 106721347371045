import React, {Component} from 'react';
import IdleTimer, { IdleTimerProps } from 'react-idle-timer';
import { Modal } from '@snsw-gel/react';
import {gotoDrupal, gotoStartPage} from "../../helpers/commonHelper";
export default class TimeoutPage extends Component {
  idleTimer: IdleTimer | null;
  timeout: number;
  state: {
    isIdle: boolean;
  };

  constructor(props: IdleTimerProps) {
    super(props);
    this.timeout = 3000 * 60 * 5;
    this.idleTimer = null;
    this.state = {
      isIdle: false,
    };
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  componentDidMount() {
    this.setState({
      isIdle: this.idleTimer && this.idleTimer.isIdle(),
    });

    setInterval(() => {
      this.setState({
        isIdle: this.idleTimer && this.idleTimer.isIdle(),
      });
    }, 1000);
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref: any) => {
            this.idleTimer = ref;
          }}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          timeout={this.timeout}
          crossTab={{
            emitOnAllTabs: true,
          }}
        />
        <div>
          <div>
            {this.state.isIdle && (
              <Modal
                title='Your session has timed out'
                buttons={[
                  { text: 'Start again', onClick: gotoStartPage },
                  { text: 'Quit', onClick: gotoDrupal },

                ]}
              >
                <p>
                  There has been no activity for the last 15 minutes or more.
                  For your security, your session has ended.
                </p>
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  }

  handleOnActive() {
    this.setState({ lastEvent: 'active' });
  }

  handleOnIdle() {
    this.setState({ lastEvent: 'idle' });
    if (this.idleTimer) this.idleTimer.pause();
  }
}
