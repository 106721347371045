import {IRegistration, IRegistrationSummary, isRegistrationData,} from './Registration';
import {ICustomer, isCustomerData} from './Customer';
import {
  isTransactionDetailsCompleteData,
  ITransactionDetailsComplete,
  ITransactionDetailsSummary,
} from './TransactionDetails';

interface ITransactionComplete {
  transactionId: string;
  registration: IRegistration;
  registrationScheme: string;
  transactionDetails: ITransactionDetailsComplete;
  usage: string;
  pdfBase64: string;
  googleLink: string;
  outlookLink: string;
  yahooLink: string
}

interface ITransactionCompleteWithCustomer extends ITransactionComplete {
  customer: ICustomer;
}

interface ITransactionSummary {
  transactionId: string;
  registration: IRegistrationSummary;
  transactionDetails: ITransactionDetailsSummary;
}

interface IPaymentRequest {
  declarationTimestamp: string;
  amount: number
}

export interface EmailRequest {
  emailAddress: string;
  uuid: string;
}

function isTransactionCompleteData(data: any): data is ITransactionComplete {
  return (
    !!data &&
    isTransactionDetailsCompleteData(data.transactionDetails) &&
    isRegistrationData(data.registration)
  );
}

function isTransactionCompleteWithCustomerData(
  data: any
): data is ITransactionCompleteWithCustomer {
  const { registration, transactionDetails, customer } = data;

  return (
    isTransactionDetailsCompleteData(transactionDetails) &&
    isCustomerData(customer) &&
    isRegistrationData(registration)
  );
}

export type { ITransactionComplete, ITransactionSummary, IPaymentRequest, ITransactionCompleteWithCustomer };
export {
  isTransactionCompleteData,
  isTransactionCompleteWithCustomerData,
};
