export const RenewRegoSession = {
  getReviewInputDetails() {
    return sessionStorage.getItem('reviewInputDetails');
  },
  setReviewInputDetails(reviewInputDetails: string) {
    sessionStorage.setItem('reviewInputDetails', reviewInputDetails);
  },
  getValidateData() {
    return sessionStorage.getItem('validateData');
  },
  setValidateData(validateData: string) {
    sessionStorage.setItem('validateData', validateData);
  },
  getUserDetails() {
    return sessionStorage.getItem('userDetails');
  },
  setUserDetails(userDetails: string) {
    sessionStorage.setItem('userDetails', userDetails);
  },
  clearUserDetails() {
    return sessionStorage.removeItem('userDetails');
  },
  clearReviewInputDetails() {
    sessionStorage.removeItem('reviewInputDetails');
  },
  clearValidateData() {
    sessionStorage.removeItem('validateData');
  },
  getVEOSQueryParam() {
    return sessionStorage.getItem('veosQueryParam');
  },
  setVEOSQueryParam(veosQueryParam: string) {
    sessionStorage.setItem('veosQueryParam', veosQueryParam);
  },
  clearVEOSQueryParam() {
    sessionStorage.removeItem('veosQueryParam');
  },
  getPlateNumber() {
    return sessionStorage.getItem('plateNumber');
  },
  setPlateNumber(plateNumber: string) {
    sessionStorage.setItem('plateNumber', plateNumber);
  },
  clearPlateNumber() {
    sessionStorage.removeItem('plateNumber');
  },
  getPlateType() {
    return sessionStorage.getItem('plateType');
  },
  setPlateType(plateType: string) {
    sessionStorage.setItem('plateType', plateType);
  },
  clearPlateType() {
    sessionStorage.removeItem('plateType');
  },
  getRegistrationId() {
    return sessionStorage.getItem('registrationId');
  },
  setRegistrationId(registrationId: string) {
    sessionStorage.setItem('registrationId', registrationId);
  },
  clearRegistrationId() {
    sessionStorage.removeItem('registrationId');
  },
  getRegistrationScheme() {
    return sessionStorage.getItem('registrationScheme');
  },
  setRegistrationScheme(registrationScheme: string) {
    sessionStorage.setItem('registrationScheme', registrationScheme);
  },
  clearRegistrationScheme() {
    sessionStorage.removeItem('registrationScheme');
  },
  clearAll() {
    sessionStorage.removeItem('userDetails');
    sessionStorage.removeItem('reviewInputDetails');
    sessionStorage.removeItem('validateData');
    sessionStorage.removeItem('plateNumber');
    sessionStorage.removeItem('plateType');
    sessionStorage.removeItem('registrationId');
    sessionStorage.removeItem('registrationScheme');
    sessionStorage.removeItem('veosQueryParam');
  },
};
