import axios from 'axios';
import { Config } from 'config/env';
import { debugLogger } from './LoggerService';
import { IPaymentRequest } from '../types/Transaction';
import {
  UserToken,
  UserSession,
  HttpRequestHandler,
} from '@rmstransactions/components';
import { ValidateRenewalRequest } from '../types/ValidateRenewalRequest';
import { IRenewalRequestData } from '../types/RenewalRequestData';
import {
  MASK_BILLING_NUMBER_PREFIX,
  MISSING_BILLING_NUMBER,
  SCHEME_CONDITIONAL,
  SCHEME_FULL,
  TRANSACTION_STATE,
  VALID_PLATE_TYPES,
} from '../constants/constants';
import { transactionCleanupEvents } from 'helpers/commonHelper';

HttpRequestHandler.setCommonApiKeyHeader();

const {
  apiHost,
  apiRenewRegoSummaryPath,
  apiInitiateRenewalPath,
  apiSilentLoginPath,
  apiCheckLoginPath,
  apiUserDetails,
  apiUserTypePath,
  apiRegistrationsByPlateOrBillingNumberAnonPath,
  apiRegistrationsByPlateOrBillingNumberAuthPath,
  apiValidateRenewalAuthPath,
  apiValidateRenewalAnonPath,
  apiRenewalAuthPath,
  apiRenewalAnonPath,
} = Config;

export const getRegoRenewalDetails = async (
  plateNumber: string,
  registrationID: string,
  registrationScheme: string
) => {
  const url =
    apiHost +
    apiRenewRegoSummaryPath +
    plateNumber +
    '/' +
    registrationID +
    '/' +
    registrationScheme;
  console.log('Url', url);
  return await axios.get(url, {
    withCredentials: true,
  });
};

export const initiateRenewal = async (paymentRequest: IPaymentRequest) => {
  const url = apiHost + apiInitiateRenewalPath;
  return await axios.post(url, paymentRequest, {
    withCredentials: true,
    headers: { 'x-token': UserToken.getValue() },
  });
};

export const renewal = async (renewalRequest: IRenewalRequestData) => {
  const url =
    apiHost +
    (UserSession.isLoginUser() ? apiRenewalAuthPath : apiRenewalAnonPath);
  return await axios.post(url, renewalRequest, {
    withCredentials: true,
    headers: { 'x-token': UserToken.getValue() },
  });
};

export const checkLogin = async (): Promise<any> => {
  const url = `${Config.apiHost}${apiCheckLoginPath}`;
  const res = await fetch(url, {
    credentials: 'include',
  });
  return res;
};

export const silentLogin = () => {
  transactionCleanupEvents();
  const loginUrl = `${apiHost}${apiSilentLoginPath}?state=${TRANSACTION_STATE}`;
  debugLogger('loginUrl invoked $loginUrl');
  UserSession.silentLogin(loginUrl);
};

export const getUserDetails = async (): Promise<any> => {
  const url = apiHost + apiUserDetails;
  console.log('Url', url);
  return await axios.get(url, {
    withCredentials: true,
  });
};

export const getUserType = async (): Promise<any> => {
  const url = apiHost + apiUserTypePath + `?state=${TRANSACTION_STATE}`;
  return await axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const getRegistrationsByPlateOrBillingNumber = async (
  number: string,
  numberType: String
) => {
  const uri = UserSession.isLoginUser()
    ? apiRegistrationsByPlateOrBillingNumberAuthPath
    : apiRegistrationsByPlateOrBillingNumberAnonPath;
  const url = apiHost + uri + '/' + number + '/' + numberType;
  return await axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const callLogin = () => {
  debugLogger('loginUrl invoked ');
  const loginUrl = `${apiHost}/login?state=${TRANSACTION_STATE}`;
  debugLogger('loginUrl invoked--->' + loginUrl);
  UserSession.login(loginUrl);
};

export const validateRenewal = async (
  request: ValidateRenewalRequest
): Promise<any> => {
  const url =
    apiHost +
    (UserSession.isLoginUser()
      ? apiValidateRenewalAuthPath
      : apiValidateRenewalAnonPath);
  return await axios.post(url, request, {
    headers: {
      'x-token': UserToken.getValue(),
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
};

HttpRequestHandler.setCommonContentType('application/json');

axios.interceptors.request.use((x: any) => {
  debugLogger('axios request', x);
  return x;
});

axios.interceptors.response.use(
  async (x: any) => {
    debugLogger('axios response', x);
    return x;
  },
  (y: any) => {
    debugLogger('axios error', y);
    throw y;
  }
);

export const haveValidRegistrationParams = (
  plateNumber: any,
  registrationID: any,
  scheme: any
) => {
  return plateNumber && registrationID && scheme;
};

export const areRegistrationParamsValid = (
  plateNumber: any,
  registrationID: any,
  scheme: any,
  plateType: string
) => {
  let isParamsPresent = plateNumber && registrationID && scheme;
  if (isParamsPresent) {
    return (
      plateNumber.match(/^[A-Za-z0-9]{1,6}$/) &&
      (scheme === SCHEME_FULL || scheme === SCHEME_CONDITIONAL) &&
      (registrationID.match(/^[0-9]{1,8}$/) ||
        ((registrationID?.startsWith(MASK_BILLING_NUMBER_PREFIX) ||
          registrationID?.startsWith(MISSING_BILLING_NUMBER)) &&
          ((scheme === SCHEME_FULL && VALID_PLATE_TYPES.includes(plateType)) ||
            (scheme === SCHEME_CONDITIONAL && !plateType)))) &&
      (!plateType || VALID_PLATE_TYPES.includes(plateType))
    );
  } else return isParamsPresent;
};

export const removeOtherElementsFromArray = (
  array: Array<any> | null | undefined,
  index: number
) => {
  if (array && array.length) {
    array?.splice(0, index);
    array?.splice(1, array?.length - 1);
  }
};
