import React, { useState, useEffect } from 'react';
import { AccordionTitleContainer } from './../../Styled/Accordion';
import {
    IconChevronUp,
    IconChevronDown,
    Row,
    Col
  } from '@snsw/react-component-library';
  import { Spacer } from '@rmstransactions/components';


const Accordion = (props) => {
    const {accordionTitle, accordionContent, isVeosEditSelected, resetAmountsIfNotApplied} = props;
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        isVeosEditSelected ?  setIsActive(true) : setIsActive(false)
    }, [isVeosEditSelected]);

    const handleClickAccordion = () => {
        if(isActive){
            resetAmountsIfNotApplied();
        }
        setIsActive(!isActive);
    }

    return (
        <Spacer mb={[1, 0]}>
            <Row>
                <Col span={10}>
                <AccordionTitleContainer onClick={handleClickAccordion}>
                    <div className='accordion-title'>{accordionTitle}</div>
                    <div>
                    {isActive ? (
                        <IconChevronUp color='secondaryBlue' data-testid='accordionUpArrow'/>
                    ) : (
                        <IconChevronDown color='secondaryBlue' data-testid='accordionDownArrow'/>
                    )}
                    </div>
                </AccordionTitleContainer>
                </Col>
            </Row>

            {isActive && (
                <>
                     {accordionContent}
                </>

            )}
         </Spacer>
    )
}

export default Accordion
