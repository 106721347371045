import { debugLogger } from '../../services/LoggerService';
import {
  getErrorIdentifier,
  getErrorMessageByCode,
  inlineErrorOrWarningPresent,
  nowrapTelephone,
  stripTelForNonMobileDevices,
} from '../../helpers/error-processor';
import { isMobileOnly } from 'react-device-detect';
import { fullPageHeaders } from '../../config/error-messages';
import { helpers } from '@rmstransactions/components';
import * as Constants from '../../constants/constants';

const DRIVES_TECHNICAL_ERRORS = [
  'SYSTEM_FAILURE',
  'INVALID_MSG_SOLN',
  'SCHEMA_VALIDATION_ERROR',
];

export const handleDRIVESSystemMessages = (
  messages: any,
  pageActionID: any,
  setInlineErrorOrWarnings: any,
  history: any
) => {
  if (inlineErrorOrWarningPresent(messages, pageActionID)) {
    const errorOrWarningMessages = messages?.filter(
      (message: any) => message.identifier !== 'SUCCESS'
    );
    errorOrWarningMessages?.map(
      (message: any) => (message.pageActionID = pageActionID)
    );
    setInlineErrorOrWarnings(errorOrWarningMessages);
  } else {
    const errorCode = getErrorIdentifier(messages);
    const message = getErrorMessageByCode(messages, errorCode);
    if (helpers.isNotEmptyObject(message)) {
      message.pageActionID = pageActionID;
    }
    if (errorCode && message) {
      debugLogger('Business Error occurred in backend call', errorCode);
      if (DRIVES_TECHNICAL_ERRORS.indexOf(errorCode) >= 0) {
        history.push({
          pathname: '/error',
          state: {
            errorMessageObject: message,
            header: Constants.TECHNICAL_ERROR_PAGE_HEADER,
          },
        });
        return;
      }
      history.push({
        pathname: '/error/fullpage',
        state: {
          errorMessageObject: message,
          header: getFullPageHeader(pageActionID),
        },
      });
      return;
    }
  }
};

export const getDeviceBasedDisplayMessage = (message: string) => {
  return !isMobileOnly
    ? stripTelForNonMobileDevices(nowrapTelephone(message))
    : nowrapTelephone(message);
};

export const getAccessibleMessage = (message: string) => {
  if (message) {
    return addAccessibleTextBeforeOpeningNewPages(getDeviceBasedDisplayMessage(message));
  } else {
    return message;
  }
};

export const addAccessibleTextBeforeOpeningNewPages = (message: string) => {
  if (message) {
    let newMessage = message.slice();
    let anchorNewPageLinkStartPosition = newMessage.indexOf('_blank')
    if (anchorNewPageLinkStartPosition >= 3) {
      let anchorTagSearchPosition = 0;
      let anchorNewPageEndPosition = -1;
      let continueLoop: boolean = true;
      while (continueLoop) {
        anchorNewPageLinkStartPosition = newMessage.indexOf('_blank', anchorTagSearchPosition)
        if (anchorNewPageLinkStartPosition >= 0) {
          const anchorOpentagStartPosition = newMessage.indexOf('<a', anchorTagSearchPosition);
          const nextAnchorOpentagStartPosition = newMessage.indexOf('<a', anchorOpentagStartPosition + 1);
          const foundBlankInAnchorOpentagStartPosition = nextAnchorOpentagStartPosition === -1 || anchorNewPageLinkStartPosition < nextAnchorOpentagStartPosition
          if (foundBlankInAnchorOpentagStartPosition) {
            anchorNewPageEndPosition = newMessage.indexOf('</a>', anchorOpentagStartPosition);
            newMessage = newMessage.substring(0, anchorNewPageEndPosition) +
                "<div class=\"sr-only\">Opens a new window</div>" +
                newMessage.substring(anchorNewPageEndPosition);
          }
          continueLoop = nextAnchorOpentagStartPosition >= 0;
          if (foundBlankInAnchorOpentagStartPosition) {
            anchorTagSearchPosition = anchorNewPageEndPosition + 31;
          } else {
            anchorTagSearchPosition = nextAnchorOpentagStartPosition;
          }
        } else {
          continueLoop = false;
        }
      }
      ;
      return newMessage;
    }
  }
  return message;
};

export const getFullPageHeader = (pageActionID: string) => {
  // @ts-ignore
  const header = fullPageHeaders[pageActionID];
  return header ? header : 'Registration renewal incomplete';
};
