import styled from 'styled-components';

export const LinkButton = styled.a`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  word-break:break-word;
  display: inline;
  margin: 0;
  padding: 0;
  color: #2e5299;
`;
