import styled from 'styled-components';

export const PanelContent = styled.div`
  display: none;
  overflow: hidden;

  &.panelContent-open {
    display: block;
  }

`;

export const PanelToggleButton = styled.button`
  color: rgb(46, 82, 153);
  font-weight: bold;
  text-decoration: underline;
  padding-right: 25px;
  position: relative;
  border: 0;
  background: 0;
  cursor: pointer;

  &.panelToggle:after {
    position: absolute;
    top: 30%; 
    right: 6px;
    content: '';
    display: block;
    border-width: 2px 2px 0 0 ;
    border-style: solid;
    border-color: rgb(46, 82, 153); 
    width: 12px; 
    height: 12px;
    transform: rotate(135deg) translateY(-20%) translateX(-80%);
  }    

  &.panelToggle-open:after {
    transform: rotate(315deg) translateY(50%) translateX(50%);
  } 
`;
