import React, { useEffect, useState } from 'react';
import { FormInput } from '@snsw/react-component-library';
import { Constants } from '../Constants/Constants';

const EmailInput = ({
  emailLabel = Constants.EMAIL_INPUT_LABEL,
  onSaveEmailValue,
  hasEmailValidationError,
  emailErrorMessage,
  defaultValue = '',
}) => {
  const [email, setEmail] = useState(defaultValue);

  useEffect(() => {
    if (onSaveEmailValue) {
      onSaveEmailValue(email);
    }
  }, []);

  const handleChangeInput = (e) => {
    setEmail(e.target.value);
  };

  const handleOnBlur = () => {
    onSaveEmailValue(email);
  };

  return (
    <FormInput
      id={Constants.DOC_ID_EMAIL_INPUT}
      name='email'
      type='email'
      data-testid='email'
      label={emailLabel}
      value={email}
      onChange={handleChangeInput}
      hasError={hasEmailValidationError}
      errorMessage={emailErrorMessage}
      onBlur={handleOnBlur}
    />
  );
};

export default EmailInput;
