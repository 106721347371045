import { analyticsServiceV2 } from '@rmstransactions/components';
const { defaultAnalyticsNullValue } = analyticsServiceV2;

const transactionName = 'Renew Registration';
const transactionType = 'renew';

const stepFindVehicle = 'Find a vehicle';
const stepConfirmation = 'Confirmation summary';

export const googleAnalyticsDefaultInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: 'unknown',
  transaction_sub_type: defaultAnalyticsNullValue,
};

const defaultErrorTransactionInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: 'Error',
  transaction_sub_type: defaultAnalyticsNullValue,
};

export const googleAnalyticsTransactionInfo = {
  '/find-vehicle': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepFindVehicle,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/confirmation': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepConfirmation,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/review-details': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: 'Review details',
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/bpay-info': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: 'BPAY info',
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/error': defaultErrorTransactionInfo,
  '/error/business': {
    ...defaultErrorTransactionInfo,
    transaction_sub_type: 'business',
  },
  '/error/confirmation-business': {
    ...defaultErrorTransactionInfo,
    transaction_sub_type: 'confirmation business',
  },
  '/error/fullpage': {
    ...defaultErrorTransactionInfo,
    transaction_sub_type: 'full page',
  },
  '/error/logged-out': {
    ...defaultErrorTransactionInfo,
    transaction_sub_type: 'logged out',
  },
};

export type StaticPathsGoogleAnalyticsTransactionInfo =
  keyof typeof googleAnalyticsTransactionInfo;

/**
 * /view-receipt/:uuid
 */
export const viewReceiptTransactionInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: stepConfirmation,
  transaction_sub_type: 'view receipt',
};
/**
 * /confirmation/:uuid
 */
export const confirmationUuidRedirectTransactionInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: 'payment gateway redirect',
  transaction_sub_type: defaultAnalyticsNullValue,
};

/**
 * /error/business/:errorCode
 */
export const errorBusinessCodeTransactionInfo = {
  ...defaultErrorTransactionInfo,
  transaction_sub_type: 'error business code',
};

/**
 * /renew-rego-orchestration/:plateNumber/:registrationID/:registrationScheme/:plateType
 */
export const myAccountWithPlateTypeTransactionInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: stepFindVehicle,
  transaction_sub_type: 'from my account',
};

/**
 * /renew-rego-orchestration/:plateNumber/:registrationID/:registrationScheme
 */
export const myAccountWithoutPlateTypeTransactionInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: stepFindVehicle,
  transaction_sub_type: 'from my account w/o platetype',
};

/**
 * /conditional-registration-summary/:plateNumber/:registrationID/:registrationScheme
 * unused but still a route
 */
export const myAccountConditionalRegoInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: stepFindVehicle,
  transaction_sub_type: 'from my account conditional vehicle',
};
