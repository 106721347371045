import React from 'react';
import InlineNotification from '../../../ErrorPage/components/InlineNotification';

const InformationSection = () => {

  let informationObject = {
      severity: 'INFORMATION',
      message: 'If the ‘Operating conditions’, ‘CTP Class’ or ‘Area of operation’ of your vehicle have changed since its last renewal, please visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a> to update your details.',
      messageTitle: 'Contact Service NSW if you need to update your registration details',
      messageDescription:
        'If the ‘Operating conditions’, ‘CTP Class’ or ‘Area of operation’ of your vehicle have changed since its last renewal, please visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a> to update your details.',
    };

  return (
    <InlineNotification errorMessageObject={informationObject}/>
  );
};

export default InformationSection;
