import React, { createContext, Dispatch, useReducer } from 'react';
import { reducer } from './reducer';

const initialState: AppState = {
  loading: 'NOT_STARTED',
  regoData: null,
  userId: '',
  transactionStartTime: new Date(),
  transactionType: '',
  reviewDetailsInput: null,
  multipleVehicleList: null,
  userDetails: null,
  validateData: null,
  eligibilityData: null,
  selectedTerm: '',
  selectedCTPPolicy: null,
  usageType: null,
  eligibleForTollRelief: null,
  pensionerDetails: null,
  veosQueryParamValue: null,
  plateType: null,
};

interface AppContext {
  globalState: AppState;
  dispatch?: Dispatch<any>;
}

export const store = createContext<AppContext>({} as AppContext);

const { Provider } = store;

const GlobalStateProvider = ({ children }: { children: any }) => {
  const [globalState, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ globalState, dispatch }}>{children}</Provider>;
};

export default GlobalStateProvider;

export interface AppState {
  regoData?: any;
  loading: LoadingState;
  userId: String;
  transactionStartTime: Date;
  transactionType: String;
  reviewDetailsInput: any;
  multipleVehicleList: any;
  userDetails: any;
  validateData: any;
  eligibilityData: any;
  selectedTerm: any;
  selectedCTPPolicy: any;
  usageType: any;
  eligibleForTollRelief: any;
  pensionerDetails: any;
  veosQueryParamValue: any;
  plateType: any;
}

export type LoadingState =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'EXTERNAL_CALL_IN_PROGRESS'
  | 'COMPLETED';
