import styled from 'styled-components';
import { Size, Breakpoint } from '@rmstransactions/components/Styled';

export const VEOSContainer = styled.div`
  background-color: #f4f7f9;
  box-shadow: 0px 4px 5px 0px #dee3e5;
  padding: 1.3rem 1rem;

  @media only screen and (max-width: ${Size.sm}px) {
    padding: 0.688rem;
  }
`;

export const HeadingContainer = styled.div`
  font-size: 1.25rem;
  margin-top: 0.2rem
  font-weight: bold;
  ${Breakpoint('xs')`
    margin: 0.5rem 0;
  `}
`;

export const FlexCenterStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flexdirection: row;
`;

export const FlexEndStyle = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  @media only screen and (max-width: ${Size.sm}px) {
    justify-content: start;
    margin: 2rem 0;
  }
`;
export const EditAndRemoveStyle = styled.span`
  a {
    cursor: pointer;
  }
  .editLink {
    text-decoration: none;
    margin-right: 0.625rem;
    margin-left: 0rem;
  }
  .removeLink {
    text-decoration: none;
    margin-left: 0.625rem;
  }
`;
export const ApplyButtonContainer = styled.span`
  display: flex;
  justify-content: left;
  align-items: center;
  ${Breakpoint('md')`
    justify-content: right;
  `}
  .lg {
    display: none;
    ${Breakpoint('md')`
      display: block;
    `}
  }
  .sm {
    display: block;
    ${Breakpoint('md')`
      display: none;
    `}
  }

  .applyButton {
    margin: 0.5rem 0 0;
    outline: none;
    margin: 0.5rem 0 0;
    ${Breakpoint('md')`
      width: 270px; 
      justify-content: right;
    `}
    &:hover {
      border: 2px solid #2e5299;
    }
  }

  .applyButton.lg.error,
  .applyButton.sm.error {
    border: 2px solid #d7153a;
    background-color: #ffffff;
    color: #d7153a;
  }
`;
export const DollarButtonContainer = styled.span`
  display: flex;
  gap: 9px;
  justify-content: space-between;
  ${Breakpoint('sm')`
        gap: 10px;
        justify-content: unset;
      `}
  .dollarButton {
    background-color: #ffffff;
    height: 48px;
    min-height: 48px;
    border: 2px solid #dee3e5;
    outline: none;
    padding: 0;
    ${Breakpoint('sm')`
        width: 84px;
        min-width: 84px;
      `}
    &:hover {
      border: 2px solid #2e5299;
      background-color: #2e5299;
      color: #ffffff;
    }
  }
  .dollarButton.active {
    background-color: #2e5299;
    color: #fff;
    border: 2px solid #2e5299;
  }
`;

export const FormContainer = styled.div`

    .inputTextBox {
      border: 0
      font-size: 18px;
      color: #2e5299;
      height: 48px;
      width: 80px;
      margin-top: 0px;
      padding-left: 0;
      font-weight: 500;
        &:focus
        {
          outline: none;
        }
        
      }
      
      .inputTextBox.selected {
        focus: none;
      }
      
      .formGroup {
        height: 48px;
        margin-top: 0;
      }
      
      .formGroup > div {
        border: 2px solid #dee3e5;
        border-radius: 8px;
        width: 113px;
      }

      .formGroup > div > div > span {
        background-color: #ffffff;
        border: 2px solid #ffffff;
        color: #2e5299;
        align-items: right;
        padding: 6px 0 6px 14px
        margin: 0;
    }
      }
      
      .formGroup.selected > div {
        border: 2px solid #2e5299;
        width: 113px;
        border-radius: 8px;
    }
    
     .formGroup.error > div > div > span {
     color: #d7153a;
     }
    
     .formGroup.error > div , .formGroup.selected.error > div {
        border: 2px solid #d7153a;
        width: 113px;
        border-radius: 8px;
        color: #d7153a;
    }
    .inputTextBox.error , .inputTextBox.selected.error {
       focus: none;
       color: #d7153a;
    }
`;
export const NonRefundableNoteContainer = styled.div`
  margin: 1.5rem 0;
  color: rgb(36, 41, 52);
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;

  ${Breakpoint('xxs')`
    margin: 0.8rem 0;
  `}
  ${Breakpoint('md')`
    margin: 0.25rem 0.6rem 1rem 0.6rem;
  `}
`;
export const EnterAmountContainer = styled.span`
  display: flex;
  justify-content: space-between;
  ${Breakpoint('xs')`
    justify-content: unset;
      `}
  .enterAmountText {
    max-width: 175px;
    font-size: 14px;
    margin-right: 10px;
    ${Breakpoint('xs')`
      font-size:16px;
      margin-right: 31px;
      max-width: 226px;
      `}
  }
  .inputBoxContainer {
    width: 110px;
  }
  .applyButtonError {
    width: 350px;
  }
  .applyButtonError > span {
    margin-top: 0;
    width: 225px;
    float: right;
    margin-right: 25px;
    @media only screen and (max-width: ${Size.md}px) {
      display: none;
      float: left;
      width: 100%;
    }
  }

  .applyButtonError.md {
    display: block;
    @media only screen and (max-width: ${Size.md}px) {
      display: none;
    }
  }

  .applyButtonError.sm {
    display: none;
    @media only screen and (max-width: ${Size.lg}px) {
      display: block;
      width: 100%;
      padding: 0;
    }
  }
  .inputBoxContainer {
    width: 110px;
  }
  .applyButtonError > span {
    margin-top: 0;
    margin-right: 0;
    width: 250px;
    @media only screen and (max-width: ${Size.md}px) {
      float: left;
      width: 100%;
    }
  }
  .applyButtonError.lg {
    display: block;

    @media only screen and (max-width: ${Size.lg}px) {
      display: none;
    }
  }
  .applyButtonError.sm {
    display: none;
    @media only screen and (max-width: ${Size.lg}px) {
      width: 100%;
      padding: 0;
    }
  }
  .applyButtonError.sm > span {
    margin-top: 0.75rem;
  }
`;

export const ApplyButtonErrorSmall = styled.span`
  width: 100%;
  display: block;
  @media only screen and (min-width: ${Size.md}px) {
    display: none;
  }
`;

export const KnowMoreContainer = styled.span`
  margin: 0 0.6rem 1rem;
  inline-size: 100%;
  overflow-wrap: break-word;
  ${Breakpoint('xxs')`
    margin:0;
  `}
  ${Breakpoint('md')`
    inline-size: 650px;
  `}
`;
export const ErrorContainer = styled.div`
  margin: 1rem 0;
`;

export const TreeAnimationStyle = styled.div`
  height: 160px;
  width: 300px;
  margin-bottom: 20px;
  ${Breakpoint('lg')`
    position: absolute;
    top: 94px;
    right: 0;
    transform: translate(-7%, -50%);
    `}
`;

export const VEOSPanelContentDes = styled.div`
  width: 100%;
  ${Breakpoint('md')`
    width: 400px;
  `};

  & > p {
    margin-bottom: 0;
  }
`;

export const VEOSContainerContent = styled.div`
  display: flex;
  flex-direction: column;

  ${Breakpoint('md')`
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    column-gap: 1rem;
  `}
`;

export const VEOSVideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > video {
    margin-right: 1.5rem;

    ${Breakpoint('md')`
      margin-right: 0;
    `};
  }
`;

export const VEOSAccordianContainer = styled.div`
  margin-top: 1rem;

  ${Breakpoint('md')`
    margin-top: -2rem;
  `};
`;
