import React, {useContext, useEffect} from 'react';
import ErrorLayout from './ErrorLayout';
import * as Constants from '../../../constants/constants';
import { isMobileOnly } from 'react-device-detect';
import { stripTelForNonMobileDevices } from '../../../helpers/error-processor';
import TimeoutPage from '../../TimeoutPage/TimeoutPage';
import { AnalyticsService } from '@rmstransactions/components';
import { AppState, store } from 'providers/globalState';
import { useLocation } from 'react-router-dom';
import {ErrorMessageContainer} from "../../../styled";
import {getAccessibleMessage} from "../ErrorDetails.helpers";


const TechnicalError = (props: any) => {
  const { globalState } = useContext(store) as {
    globalState: AppState;
  };
  window.scrollTo(0, 0);

  const location: any = useLocation();
  const errorMessageObject = location?.state?.errorMessageObject;
  const header = location?.state?.header;

  let message = getAccessibleMessage(errorMessageObject?.messageDescription
    ? errorMessageObject?.messageDescription
    : Constants.TECHNICAL_ERROR_PAGE_MESSAGE);
  message =
    message && !isMobileOnly ? stripTelForNonMobileDevices(message) : message;
  AnalyticsService.ErrorReport(
    'System_error',
    location.state + ' Technical Error',
    message,
    globalState?.transactionStartTime
  );

    useEffect(() => {
        document.title = 'Service NSW - Renew registration - Error';
    }, []);

  return (
    <>
      <TimeoutPage />
      <ErrorLayout
        heading={header ? header : Constants.TECHNICAL_ERROR_PAGE_HEADER}
        messageHeadline={
          errorMessageObject?.messageTitle
            ? errorMessageObject?.messageTitle
            : Constants.TECHNICAL_ERROR_PAGE_MESSAGE_HEADLINE
        }
      >
        <ErrorMessageContainer dangerouslySetInnerHTML={{ __html: message }} />
      </ErrorLayout>
    </>
  );
};

export default TechnicalError;
