import React from 'react';
import { Modal } from '@snsw-gel/react';

const TollReliefModal = (props: any) => {
  let {
    setShowTollReliefModal,
    setToggleChangeUsage,
    doSfRedirect,
    handleShowTollReliefOnCancel,
    plateNumber,
    registrationID,
    registrationScheme,
  }: {
    setShowTollReliefModal: any;
    setToggleChangeUsage: any;
    doSfRedirect: any;
    handleShowTollReliefOnCancel: any;
    plateNumber: any;
    registrationID: any;
    registrationScheme: any;
  } = props;
  const handleCancleTollRelief = () => {
    setShowTollReliefModal(false);
    setToggleChangeUsage(false);
    handleShowTollReliefOnCancel();
  };

  const handleContinueToTollRelief = () => {
    doSfRedirect(plateNumber, registrationID, registrationScheme);
    setShowTollReliefModal(false);
  };
  return (
    <Modal
      title='Claim your Toll Relief discount'
      buttons={[
        {
          text: 'Continue to Toll Relief',
          onClick: handleContinueToTollRelief,
        },
        {
          text: 'No thanks',
          onClick: handleCancleTollRelief,
        },
      ]}
    >
      <p>
        You've selected ‘Private (PRIV)’ usage and are now eligible for a Toll
        Relief discount.{' '}
      </p>
    </Modal>
  );
};

export default TollReliefModal;
