import styled, { css } from 'styled-components';
import { FontWeight } from '@rmstransactions/components/Styled';
import { tokens } from '@snsw-gel/react';

export const ChangeTermsContainer = styled.div`
  .add-border {
    border-width: 2px 0;
    border-color: rgb(222, 227, 229);
    border-style: solid;
  }
`;

export const TermContainer = styled.div`
  margin-bottom: 1rem;
`;

export const TermDetails = styled.span`
  padding-left: 1rem;
`;

export const DisplayContent = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FlagsLine = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
`;

export const CTPSafetyCheckNotificationContainer = styled.span`
    .externalLink {
      background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg height='12' width='12' fill='%232e5299' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0v12h12V0H0zm10.9 10.9H1.1V1.1h9.8v9.8z'/%3E%3Cpath d='M4.4 2.7v1.1h3L2.3 8.9l.8.8 5.1-5.1v3h1.1V2.7H4.4z'/%3E%3C/svg%3E");
      background-repeat:no-repeat;
      background-position: right
      padding-right: 16px;
    }
`;

export const CTPMonthWrapper = styled.span`
  font-weight: ${FontWeight.regular};
`;

export const HeadingDetails = styled.h2`
  margin-top: 1 rem;
  margin-bottom: 1rem;
  font-size: 1.625rem;
`;

export const RenewalTermDetails = styled.p`
  font-weight: bold;
  font-size: medium;
`;

export const MoreInfoModalMobileAdjustments = css`
  div[role='dialog'] {
    @media screen and (max-width: ${tokens.breakpoints.xl}px) {
      left: calc(20% + 48px);
      width: calc(100% - 20% - 48px);
    }
    @media screen and (max-width: ${tokens.breakpoints.md}px) {
      left: 48px;
      width: calc(100% - 48px);
    }
  }
`;

export const ModalWrapper = styled.span`
  top: 0.125rem;
  position: relative;
  padding-left: 0.25rem;

  @media screen and (max-width: ${tokens.breakpoints.md}px) {
    margin-top: 1rem;
  }
  ${MoreInfoModalMobileAdjustments}
`;
