import styled from 'styled-components';

export const NationalPassWrapper = styled.div`
  display: flex;
  color: rgb(36, 41, 52);
  background-color: white;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 8px 0px;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  margin-bottom: 3rem;
  position: relative;
  
  svg {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    margin-top: 22px;
  }
`;

export const Content = styled.div``;
