import styled from 'styled-components';

export const TermsAndConditionsLine = styled.div`
    padding-top:20px;
    .button-terms {
        margin: 0;
        padding: 0;
        text-decoration: underline;
        fontWeight: '300',
        color: 'rgb(46, 82, 153)',

        :hover { background: none; }
        :active { background: none; }
    }
    .pointer {
        cursor: pointer;
    }
`;

export const PrivacyStatement = styled.span`
  .fontColor {
    color: #2e5299;
    text-decoration: underline;
  }
`;
