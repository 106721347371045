import React from 'react';
import { helpers } from '@rmstransactions/components';
import RenewalTermToolTip from './RenewalTermToolTip';
import * as Styled from './styled';

const TermDisplay = (props: any) => {
  const { term, ctpPolicy }: { term: any; ctpPolicy: any } = props;
  const termInMonths = helpers.ctpMonths(term);

  return (
    <Styled.TermContainer>
      <strong>Renewal term: </strong> {termInMonths} months
      {ctpPolicy && (
        <span> (Based on your {termInMonths}-month CTP insurance)</span>
      )}
      <RenewalTermToolTip />
    </Styled.TermContainer>
  );
};
export default TermDisplay;
