import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  helpers,
  AnalyticsService,
  UserSession,
  ErrorService,
  FormWrapper,
  Header,
  Quickfeed,
  Spacer,
} from '@rmstransactions/components';
import { Button, ContentContainer, IconSignOut } from '@snsw-gel/react';
import ConfirmationPageContent from './components/ConfirmationPageContent/ConfirmationPageContent';
import { QuickfeedStyleOverride } from '@rmstransactions/components/Styled';
import { getConfirmationDetails } from 'services/ConfirmationService';
import { getUserDetails } from 'services/RegistrationService';
import ConfirmationUUIDManager from 'session/ConfirmationUUIDManager';
import TimeoutPage from '../TimeoutPage/TimeoutPage';
import { AppState, store } from 'providers/globalState';
import {
  DRUPAL_RENEW_REGISTRATION_URL,
  TRANSACTION_STATE,
  TUTD_APPLICATION_NAME,
} from '../../constants/constants';
import { handleDRIVESSystemMessages } from '../ErrorPage/ErrorDetails.helpers';
import InlineNotificationConfirmation from '../ErrorPage/components/InlineNotificationConfirmation';
import { ButtonGroup, MoreInfoContainer } from '../../styled';
import {
  gotoHelpUsImprove,
  gotoNSWHomePage,
  gotoStartPage,
  logout,
  transactionCleanupEvents,
} from '../../helpers/commonHelper';
import { Config } from '../../config/env';
import { RowButtonGroup } from './styled';
import DownloadReceipt from './components/DownloadReceipt/DownloadReceipt';
import { MoreService } from './components/MoreService/MoreService';

type confirmationHistoryState = undefined | Record<string, any>;

const ConfirmationPage = () => {
  const { globalState, dispatch } = useContext(store) as {
    globalState: AppState;
    dispatch: any;
  };
  const [loading, setLoading] = useState(false);
  const [inlineErrorOrWarnings, setInlineErrorOrWarnings] = useState<any>(null);
  const history = useHistory();
  const [confirmationData, setconfirmationData] = useState({
    registration: { expiryDate: null, plateNumber: null },
    transactionDetails: {
      transactionDate: null,
      droughtReliefRebate: null,
      adminAmountRebate: null,
      totalRebate: null,
    },
    customer: { mailingAddress: '' },
    usage: '',
    registrationScheme: '',
    pdfBase64: '',
    googleLink: '',
    outlookLink: '',
    yahooLink: '',
    receiptUUID: '',
  });

  const urlParams = useParams<{ uuid?: string }>();
  const location = useLocation();
  const locationState = location?.state as confirmationHistoryState;
  const receiptUUID =
    urlParams.uuid ?? ConfirmationUUIDManager.getValue() ?? locationState?.uuid;

  useEffect(() => {
    document.title = 'Service NSW - Renew registration - Confirmation summary';
    if (UserSession.isLoginUser()) {
      getUserDetails().then((response) => {
        if (response?.data !== '') {
          var userId = response.data;
          dispatch({
            type: 'set_userId',
            payload: userId,
          });
          callConfirmationAPI();
        }
      });
    } else {
      callConfirmationAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callConfirmationAPI = () => {
    setLoading(true);
    getConfirmationDetails(receiptUUID)
      .then((response: any) => {
        setconfirmationData(response.data);
        if (response.data?.receiptUUID) {
          ConfirmationUUIDManager.setValue(response.data.receiptUUID);
        }
        setLoading(false);
        if (response.data?.systemMessages?.message?.length > 0) {
          handleDRIVESSystemMessages(
            response.data.systemMessages.message,
            'CONFIRMATION_PAGE',
            setInlineErrorOrWarnings,
            history
          );
        }
      })
      .catch((error: any) => {
        window.scrollTo(0, 0);
        const { apiHost } = Config;
        ErrorService.handleAPIError(
          error,
          history,
          apiHost,
          TRANSACTION_STATE,
          DRUPAL_RENEW_REGISTRATION_URL
        );
      });
  };

  const filename =
    'Registration Renewal (' +
    confirmationData?.registration?.plateNumber +
    ') ' +
    helpers.dateFormat(
      confirmationData?.transactionDetails?.transactionDate,
      'YYYY-MM-DD'
    );
  const handleClick = (
    eventAction: String,
    eventName: String,
    fn?: () => void
  ) => {
    AnalyticsService.ButtonClick(
      eventAction,
      eventName,
      globalState.transactionStartTime
    );
    fn && fn();
  };
  return (
    <>
      <TimeoutPage />
      <Header
        data-testid='header'
        title='Registration renewal complete'
        headingIcon='TickCircle'
      />
      <ContentContainer>
        <FormWrapper maxWidth='770px'>
          <h2 style={{ fontSize: '2rem', margin: '0 0 0.5rem 0' }}>Summary</h2>
          {inlineErrorOrWarnings?.length > 0 &&
            inlineErrorOrWarnings?.map((errorOrWarning: any) => {
              return (
                <InlineNotificationConfirmation
                  errorMessageObject={errorOrWarning}
                  confirmationData={confirmationData}
                />
              );
            })}
          <ConfirmationPageContent
            data={confirmationData}
            isLoading={loading}
            handleClick={handleClick}
          />
          <DownloadReceipt
            pdfBase64={confirmationData.pdfBase64}
            downloadFileName={filename}
            handleClick={handleClick}
            receptUUID={receiptUUID}
          />
          <Spacer mb={3}>
            <RowButtonGroup>
              {UserSession.isLoginUser() ? (
                <Button
                  className='buttons'
                  variant='primary'
                  onClick={() => handleClick('Button Click', 'Log out', logout)}
                  iconEnd={<IconSignOut />}
                >
                  Log out
                </Button>
              ) : (
                <Button
                  className='buttons'
                  variant='primary'
                  onClick={() =>
                    handleClick(
                      'Button Click',
                      'Return to homepage',
                      gotoNSWHomePage
                    )
                  }
                  iconEnd={<IconSignOut />}
                >
                  Return to homepage
                </Button>
              )}
              <Button
                className='buttons'
                variant='secondary'
                onClick={() =>
                  handleClick(
                    'Button Click',
                    'Help us improve',
                    gotoHelpUsImprove
                  )
                }
              >
                Help us improve
              </Button>
            </RowButtonGroup>
          </Spacer>
          <MoreService confirmationData={confirmationData} />
          <ButtonGroup>
            <Spacer mb={3}>
              <Button
                variant='secondary'
                type='button'
                onClick={(e: any) =>
                  handleClick(
                    'Button Click',
                    'Renew another registration',
                    () => {
                      transactionCleanupEvents();
                      gotoStartPage();
                    }
                  )
                }
              >
                Renew another registration
              </Button>
            </Spacer>
          </ButtonGroup>
          <Spacer mb={3}>
            <QuickfeedStyleOverride>
              <Quickfeed
                sourceApplication={TUTD_APPLICATION_NAME}
                label='How was your registration experience?'
                environment={process.env.REACT_APP_TUTD}
              />
            </QuickfeedStyleOverride>
          </Spacer>
        </FormWrapper>
      </ContentContainer>
    </>
  );
};

export default ConfirmationPage;
