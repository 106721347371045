import { computeSafetyCheckFlag } from './ComputeSafetyCheckFlag';

export const isFlagsEligibleForRenewal = (validRenewalTerm: any) => {
  return (
    computeSafetyCheckFlag(
      validRenewalTerm.safetyInspectionFlag,
      validRenewalTerm.hvInspectionFlag
    ) !== 'I' && validRenewalTerm.ctpFlag !== 'I'
  );
};
