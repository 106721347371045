import React, { useState } from 'react';
import { Modal } from '@snsw-gel/react';
import { TermsAndConditionsLine } from './styled';

interface TAndCsProps {
  handleAgreeTAndCs: () => void;
}

const TermsAndConditions: React.FunctionComponent<TAndCsProps> = ({
  handleAgreeTAndCs,
}: TAndCsProps) => {
  const [tAndCmodal, toggleTAndCModal] = useState(false);

  const acceptTAndCs = () => {
    handleAgreeTAndCs();
    toggleTAndCModal(false);
  };
  const hideTAndCs = () => {
    toggleTAndCModal(false);
  };
  return (
    <TermsAndConditionsLine>
      Please read the{' '}
      <span
        data-testid='showTAndCsModal'
        className='button-terms pointer'
        role='button'
        tabIndex={0}
        style={{
          color: 'rgb(46, 82, 153)',
        }}
        onClick={() => toggleTAndCModal(true)}
      >
        Terms and Conditions
      </span>{' '}
      before proceeding.
      {tAndCmodal && (
        <Modal
          title='Terms and Conditions'
          isScrollable={true}
          onClose={hideTAndCs}
          buttons={[
            { text: 'Accept', id: 'acceptTAndCs', onClick: acceptTAndCs },
            { text: 'Close', id: 'closeTAndCs', onClick: hideTAndCs },
          ]}
        >
          <div>
            <p>
              These Terms and Conditions, together with the Terms of Use,
              Copyright Statement and Privacy Statement published on the Service
              NSW website (“Terms”), set out the conditions that apply to your
              use of the service allowing online application for a vehicle
              registration (“Service”).
            </p>
            <p>
              By using the Service, you agree to comply with these Terms
              together with all relevant guidelines, instructions and procedures
              from Service NSW and Transport for NSW ("Instructions"). Nothing
              in these Terms limits or qualifies your rights or obligations
              under any legislation, including legislation relating to motor
              vehicles, traffic, roads or road transport.
            </p>
            <h3>Changing these Terms</h3>
            <p>
              Transport for NSW and Service NSW may change these Terms or their
              Instructions from time to time by republishing them online. You
              agree that by doing this, you have been provided with sufficient
              notice of the variation. You should read the Terms and
              Instructions each time before you use the Service in case they
              have changed. If you do not agree to any change, you should not
              continue to use the Service.
            </p>
            <h3>Preconditions – clear funds</h3>
            <p>
              You acknowledge and agree that your vehicle registration will not
              be renewed until the registration fee is received in clear funds.
              Your vehicle's registration may not be renewed if your funds have
              not cleared before the expiry date of your current registration.
              If you are paying via BPAY, you must allow 3 business days for
              Transport for NSW to receive your cleared funds. You warrant that
              you are entitled to use any credit card for which you have
              provided details.
            </p>
            <h3>Payment</h3>
            <p>
              Where payment is required, all amounts are stated in Australian
              dollars and are inclusive of GST. You acknowledge that if you do
              not pay the correct vehicle registration fees (including where
              your financial institution declines or reverses payment or has not
              made the payment because you have not complied with its terms and
              conditions), Transport for NSW may cancel your vehicle
              registration or Service NSW may not process your claim.
            </p>
            <h3>Preconditions – concession eligibility declaration</h3>
            <p>
              If you are claiming a concession on your registration fees, you
              declare that you are eligible for that concession. You acknowledge
              and agree that your application for a concession is subject to
              Transport for NSW verifying your concession entitlement and you
              agree to promptly provide all necessary information to assist
              Transport for NSW with verification. If you do not do so, you
              acknowledge that Transport for NSW may cancel your registration.
            </p>
            <h3>Preconditions – Toll Relief eligibility</h3>
            <p>
              You acknowledge and agree that you can only claim under the Toll
              Relief if:
            </p>
            <ol type='I'>
              <li>
                <span lang='EN'>You are a NSW resident;</span>
              </li>
              <li>
                <span lang='EN'>Your registration is for an annual term;</span>
              </li>
              <li>
                <span lang='EN'>
                  Your vehicle is registered for the Toll Relief Scheme Service
                  with your Toll Service Provider;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  You paid for the eligible toll that has contributed towards
                  the entitlement to benefit from the Scheme with a NSW issued
                  electronic or tag-less account;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  The toll spend was accrued on an eligible vehicle, and the
                  entitlement is being applied to an eligible vehicle. A vehicle
                  will be deemed eligible if:
                  <ol type='A'>
                    <li>
                      <span lang='EN'>It is registered in NSW;</span>
                    </li>
                    <li>
                      <span lang='EN'>
                        It is used solely or principally by the applicant;
                      </span>
                    </li>
                    <li>
                      <span lang='EN'>
                        It is used substantially for social or domestic purposes
                        or for pleasure including private, charitable or
                        religious purposes – but not for business purposes;
                      </span>
                    </li>
                    <li>
                      <span lang='EN'>
                        It is registered for private use – for example, your
                        current registration papers show that the vehicle is
                        used for “private general”, “charitable” or “pensioner”.
                        Trips made using government or business registered
                        vehicles (including vehicles as part of a salary
                        package), leased vehicles, rented vehicles, smash repair
                        courtesy vehicles, car dealership loan vehicles,
                        interstate registered vehicles or any other vehicle
                        where business usage is shown on the registration papers
                        are ineligible;
                      </span>
                    </li>
                    <li>
                      <span lang='EN'>
                        The vehicle does not exceed 2794kgs TARE weight; and
                      </span>
                    </li>
                    <li>
                      <span lang='EN'>
                        The licence plate number is linked to the respective
                        toll account;
                      </span>
                    </li>
                  </ol>
                </span>
              </li>
              <li>
                <span lang='EN'>
                  The accrued toll spend which deems eligibility to claim under
                  the Scheme has resulted from an ‘eligible trip’, meaning: The
                  toll was incurred on a NSW toll road;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  This includes any NSW toll roads which may exist in the
                  future;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  You have not been reimbursed for the eligible toll spend by an
                  employer or throughout the course of business operations
                  (including, for example, ride-sharing or business usage or; by
                  a federal or state government agency or any other third party
                  via the M5 Cashback Scheme);
                </span>
              </li>
              <li>
                <span lang='EN'>
                  You have not previously claimed a registration or
                  reimbursement benefit within the same financial year under the
                  Toll Relief Scheme with the same electronic tolling account;
                  and
                </span>
              </li>
              <li>
                <span lang='EN'>
                  You understand that it is an offence to provide false and
                  misleading information under the Crimes Act 1900.
                </span>
              </li>
            </ol>
            <h3>Exclusions under the Toll Relief program</h3>
            <p>You acknowledge and understand that:</p>
            <ol type='I'>
              <li>
                <span lang='EN'>
                  Any corporate, commercial and/or business tolling accounts are
                  excluded from this Scheme;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  Only toll spend that has accrued after 1 July 2017 will be
                  eligible to count towards a claim made under this Scheme;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  Interstate tolling accounts are excluded from this Scheme;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  Tolls paid outside of an account are excluded from this
                  Scheme;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  Trips made on Interstate toll roads are excluded from this
                  Scheme;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  Trips made with an eRider product are excluded from this
                  Scheme;
                </span>
              </li>
              <li>
                <span lang='EN'>
                  A free or 50% discount registration or reimbursement cannot be
                  applied to a Pension usage vehicle under the Scheme, due to
                  Pension usage vehicles being exempt from registration fees.
                </span>
              </li>
              <li>
                <span lang='EN'>
                  A free or 50% discounted registration will not apply to
                  personalised plate fees on registration
                </span>
              </li>
            </ol>

            <h3>Claim lodgement</h3>
            <p>
              You acknowledge and understand that all claims made under the Toll
              Relief Scheme must be lodged with Service NSW within 12 months of
              the end of the respective financial year of which the eligible
              toll spend has accrued. Claims received outside of this period
              will not be accepted.
            </p>
            <h3>
              Method of Calculation – Toll Accounts with Multiple Electronic
              Tags under the Toll Relief Program
            </h3>
            <p>
              Applicants with multiple electronic tags which are linked to the
              one account can combine the accrued eligible toll spend from the
              multiple tags. This includes where a tag has been replaced with
              another (for example, where a tag has been lost, stolen, damaged
              or deemed faulty). Where this combined accrued amount meets the
              relevant thresholds, the applicant will be entitled to the
              relevant discount benefit.
            </p>
            <h3>Method of Calculation - Relevant Thresholds</h3>
            <p>
              To be eligible for a free annual registration or reimbursement
              (100% registration discount), you will have accrued $1,300 (GST
              inclusive) or more of eligible toll spend within the relevant
              period.
            </p>
            <p>
              To be eligible for a 50% discount or reimbursement on your annual
              registration, you will have accrued between $780 to $1,299.99 of
              eligible toll spend within the relevant period. Note: The above
              threshold amounts apply to the 2018 and 2019 financial year. The
              threshold amounts will increase by 4% each financial year,
              starting from 1 July 2019.
            </p>
            <h3>
              Method of Calculation – Toll Accounts without Electronic Tags
              under the Toll Relief Program
            </h3>
            <p>
              In calculating the eligible toll spend for a previous financial
              year in respect of a tolling account with no electronic tags for
              the account, the eligible toll spend is to be calculated by
              counting the tolls paid in respect of any photograph matched
              vehicles for the tolling account during the financial year
              concerned. However, any amount in tolls paid during that year by a
              registered participant in the M5 Cashback Scheme in connection
              with the use of any part of the M5 South-West Motorway to which
              the Scheme applies cannot be counted towards the calculation of
              the eligible toll spend.
            </p>
            <h3>Discontinuance of Service – Toll Relief Program</h3>
            <p>
              Transport for NSW and Service NSW reserves the right at any time
              and without notice to discontinue or modify the Toll Relief in
              whole or in part, and/or restrict access to it.
            </p>
            <h3>Correct Information</h3>
            <p>
              By submitting a claim under the Toll Relief Scheme, you warrant
              that:
            </p>
            <ol type='I'>
              <li>
                <span lang='EN'>
                  You are the account holder of the respective electronic tag/s;
                  or
                </span>
              </li>
              <li>
                <span lang='EN'>
                  You have been authorised by the account holder to claim the
                  benefit under the Scheme.
                </span>
              </li>
            </ol>
            <p>
              Further, by submitting a claim under the Toll Relief Scheme, you
              warrant that:
            </p>
            <ol type='I'>
              <li>
                <span lang='EN'>
                  All information included in your claim is true, accurate and
                  complete;
                </span>
              </li>
              <li>
                <span lang='EN'>You are entitled to submit the claim;</span>
              </li>
              <li>
                <span lang='EN'>
                  To the extent that it includes information about other people,
                  that you are authorised to disclose it to Service NSW.
                </span>
              </li>
            </ol>
            <h3>Incorrect Information</h3>
            <p>
              In circumstances where you submit inaccurate, incomplete or
              incorrect information in your Toll Relief online claim which
              Service NSW acts upon, resulting in additional expenses being
              incurred by Service NSW that would not usually be incurred:
            </p>
            <ol type='I'>
              <li>
                <span lang='EN'>
                  Service NSW reserves the right to seek reimbursement from you;
                  and/or
                </span>
              </li>
              <li>
                <span lang='EN'>Service NSW will not process your claim.</span>
              </li>
            </ol>
            <h3>Conditional Registration</h3>
            <p>
              In agreeing to the terms and conditions outlined, you declare
              that:
            </p>
            <ol type='I'>
              <li>
                <span lang='EN'>
                  You are the Registered Operator of the vehicle or the agent
                  registering the vehicle on behalf of the Registered Operator.
                </span>
              </li>
              <li>
                <span lang='EN'>
                  There are no changes to the Registered Operator’s personal
                  details.
                </span>
              </li>
              <li>
                <span lang='EN'>
                  There are no changes to the usage of the vehicle, or changes
                  which would require Transport for NSW to amend the vehicle’s
                  operating conditions.
                </span>
              </li>
              <li>
                <span lang='EN'>
                  The vehicle complies with the applicable Conditional
                  Registration Vehicle Sheet and the vehicle and all equipment
                  and fittings are in safe operating condition (the vehicle
                  sheets can be found online).
                </span>
              </li>
              <li>
                <span lang='EN'>
                  There are no changes to the vehicle’s dimensions and/or weight
                  limits (note: operators of oversize and overmass vehicles must
                  also comply with the conditions of the relevant access notice
                  or permit. For more information contact Special Permits Unit
                  on 1300 656 371 for intrastate permits, and the National Heavy
                  Vehicle Regulator on 1300 696 487 for interstate permits).
                </span>
              </li>
              <li>
                <span lang='EN'>
                  There is no change to the area of operation of the vehicle.
                </span>
              </li>
              <li>
                <span lang='EN'>
                  The Registered Operator will be responsible for maintaining
                  the vehicle in a safe operating condition until the expiry of
                  the registration.
                </span>
              </li>
              <li>
                <span lang='EN'>This information is true and complete.</span>
              </li>
            </ol>
            <p>
              Your Personal Information is collected and held by Transport for
              NSW, 20-33 Ennis Road, Milsons Point NSW 2061. You must supply the
              information in accordance with the road transport legislation.
            </p>
            <h3>Liability</h3>
            <p>
              You acknowledge and agree that subject to any responsibilities
              implied by law and which cannot be excluded, Service NSW and
              Transport for NSW are not liable to you for any losses, expenses,
              damages, liabilities and claims whatsoever, whether direct,
              indirect or consequential, arising out of or in reference to the
              use of the Service or its discontinuance, however caused
              (including your reliance on any inaccurate or incomplete
              information or data contained within or displayed by the Service)
              whether in contract, tort (including negligence), statute or
              otherwise.
            </p>
            <p>
              Service NSW and Transport for NSW’s liability for breach of a
              condition or warranty implied by law which cannot be excluded is
              limited to the extent possible warranted by the supply of the
              Service.
            </p>
            <h3>Indemnity</h3>
            <p>
              You indemnify Service NSW and Transport for NSW in respect of any
              liability, loss, damage, cost or expense suffered or incurred by
              Service NSW arising from or in connection with your breach of
              these Terms.
            </p>
            <h3>Security of Information</h3>
            <p>
              You are responsible for taking reasonable care in how you access
              the Service, including using appropriate firewalls and anti-virus
              software. No data transmission over the Internet can be guaranteed
              as totally secure and communications to this Service may be
              intercepted or altered in transit. Although Transport for NSW and
              Service NSW strive to protect such information, they do not
              represent or warrant and cannot ensure the security of any
              information which is transmitted when you use the Service. When
              using this Service you transmit and receive information at your
              own risk and Transport for NSW and Service NSW bear no liability.
            </p>
            <h3>Privacy</h3>
            <p>
              Service NSW is obliged to maintain strict privacy of your details
              under the Privacy and Personal Information Protection Act 1999
              (NSW), and not to use the information supplied by you other than
              for the purpose for which it was supplied. This information
              however may be disclosed in accordance with the exemptions
              provided in the Privacy and Personal Protection Act 1998 (NSW). In
              participating in the Toll Relief Scheme, you authorise Service NSW
              to disclose and utilise your information, in accordance with the
              Privacy and Personal Information Protection Act 1998, to Transport
              for NSW and relevant banking institutions to access, verify and
              process your application.
            </p>
            <h3>Unavailability of Service</h3>
            <p>
              You acknowledge and agree that from time to time the Service may
              be unavailable. Transport for NSW and Service NSW make no
              representation or warranty in relation to the information
              available on the Service, including without limitation, that the
              Service will be free from any delay in operation or transmission,
              virus, communications failure, internet access difficulties or
              malfunction in hardware and software.
            </p>
            <h3>No Warranty</h3>
            <p>
              Service NSW makes no representation or warranty in relation to the
              information available on the Service, including without
              limitation:
            </p>
            <ol type='I'>
              <li>
                <span lang='EN'>
                  That the Service will be continuously available or free from
                  any delay in operation or transmission, virus, communications
                  failure, internet access difficulties or malfunction in
                  hardware or software; and
                </span>
              </li>
              <li>
                <span lang='EN'>
                  That Service NSW endorses any entity or internet site linked
                  to the Service or any third party products or services
                  referred to on the Toll Relief Scheme Service site. By linking
                  to a third party website Service NSW is not accepting
                  responsibility for the content of that website or your use of
                  it and makes no warranty as to its suitability. Use of any
                  third party website is subject to the terms of that website.
                </span>
              </li>
            </ol>
            <h3>Age</h3>
            <p>
              You warrant that you are aged 18 or over. If you are under 18, you
              must use the Service only with the involvement of a parent or
              guardian.
            </p>
            <h3>No Waiver</h3>
            <p>
              Failure by Transport for NSW or Service NSW to enforce any of its
              rights at any stage does not constitute a waiver of those rights.
            </p>
            <h3>Severability</h3>
            <p>
              If any provision of these Terms shall be deemed unlawful, void or
              for any reason unenforceable, then that provision shall be deemed
              severable from these Terms and shall not affect the validity and
              enforceability of any remaining provisions.
            </p>
            <h3>Survival</h3>
            <p>
              Any and all provisions or obligations contained in these Terms
              which by their nature or effect are required or intended to be
              observed, kept or performed after termination of these Terms will
              survive the termination of these Terms and remain binding upon and
              for the benefit of the relevant persons.
            </p>
            <h3>Jurisdiction</h3>
            <p>
              These Terms and your use of the Service are governed by the laws
              by New South Wales, Australia. You agree to submit to the
              exclusive jurisdiction of New South Wales. A reference to any
              legislation includes all delegated legislation made under it,
              including regulations, rules and amendments, consolidations,
              replacements or re-enactments of any of them.
            </p>
            <h3>General</h3>
            <p>
              A reference to any legislation includes all delegated legislation
              made under it, including regulations and rules, and amendments,
              consolidations, replacements or re-enactments of any of them. A
              reference to an entity, whether statutory or not, which ceases to
              exist or whose powers or functions are transferred to another body
              is a reference to the body which replaces it or which
              substantially succeeds to its powers or functions. Specifying
              anything in these Terms after the words 'include' or 'for example'
              or similar expressions does not limit what else is included.
            </p>
            <h3>Disclaimer</h3>
            <p>
              The services are limited to issuing the Toll Relief Scheme. Any
              enquiries relating to the Toll Relief Scheme should be directed to
              Transport for NSW. Transport for NSW may have their own protocols,
              terms and conditions and disclaimers. We urge you to familiarise
              yourself with these.
            </p>
            <h3>Complaints Handling and Feedback</h3>
            <p>
              Service NSW welcomes all forms of feedback, including complaints
              on its services. We view effective complaint management as part of
              our commitment to improving service provisions and recurring
              problems.
            </p>

            <p></p>
            <p>You may leave your feedback or make a complaint by:</p>
            <p>Calling: 13 77 88</p>
            <p>
              Emailing:{' '}
              <a href='mailto:info@service.nsw.gov.au'>
                info@service.nsw.gov.au
              </a>
            </p>
            <p>
              Visiting:{' '}
              <a href='https://www.service.nsw.gov.au/contact-us'>
                https://www.service.nsw.gov.au/contact-us
              </a>
            </p>
          </div>
        </Modal>
      )}
    </TermsAndConditionsLine>
  );
};

export default TermsAndConditions;
