import React, { forwardRef, ForwardRefRenderFunction, Props, Ref } from 'react';
import UsageToolTip from '../UsageType/UsageToolTip';
import { UsageDisplay } from './styled';

const UsageType = forwardRef<HTMLDivElement, UsageTypeProps>((props, ref) => {
  const { usageType, allowedRegistrationUsage } = props;

  const anyAllowedRegistrationUsagesExists =
    allowedRegistrationUsage && allowedRegistrationUsage.length > 0;

  return (
    <UsageDisplay id='usageType' tabIndex={0} ref={ref}>
      <strong>Usage:</strong> {usageType?.description} ({usageType?.code}){' '}
      {anyAllowedRegistrationUsagesExists && (
        <UsageToolTip
          allowedRegistrationUsage={allowedRegistrationUsage}
          currentUsage={usageType}
        />
      )}
    </UsageDisplay>
  );
});

export default UsageType;

interface UsageTypeProps {
  usageType: UsageTypeValueProps;
  allowedRegistrationUsage: UsageTypeValueProps[];
}

interface UsageTypeValueProps {
  code: string;
  description?: string;
  pensionerConcessionFlag?: string;
}
