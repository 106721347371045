import React from 'react';
import { InPageAlert } from '@snsw-gel/react';
import {
  treatErrorWarningCodesAsInfo,
  treatErrorWarningCodesAsSuccess,
} from '../../../config/error-messages';
import {
  getAccessibleMessage,
} from '../ErrorDetails.helpers';
import {ErrorMessageContainer} from "../../../styled";

const InlineNotification = (props: any) => {
  const {
    errorMessageObject,
  }: {
    errorMessageObject: any;
  } = props;

  let severity = errorMessageObject?.severity?.toLowerCase();

  if (
    treatErrorWarningCodesAsSuccess[errorMessageObject?.pageActionID]?.filter(
      (errorCode: string) => errorCode === errorMessageObject.identifier
    )?.length > 0
  ) {
    severity = 'success';
  } else if (
    treatErrorWarningCodesAsInfo[errorMessageObject?.pageActionID]?.filter(
      (errorCode: string) => errorCode === errorMessageObject.identifier
    )?.length > 0
  ) {
    severity = 'info';
  }

  severity = severity === 'information' ? 'info' : severity;

  let displayMessage = getAccessibleMessage(
    errorMessageObject?.messageDescription
      ? errorMessageObject?.messageDescription
      : errorMessageObject?.message
  );

  return (
    <InPageAlert
      variant={severity}
      title={errorMessageObject?.messageTitle}
      data-testid={severity}
    >
      <ErrorMessageContainer dangerouslySetInnerHTML={{ __html: displayMessage }} />
    </InPageAlert>
  );
};

export default InlineNotification;
