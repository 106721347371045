import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ConfirmationUUIDManager from 'session/ConfirmationUUIDManager';

const ConfirmationRedirect: React.FC = () => {
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();

  if (uuid) {
    ConfirmationUUIDManager.setValue(uuid);
  }

  useEffect(() => {
    history.push({
      pathname: '/confirmation',
      state: {
        uuid: uuid,
      },
    });
  }, [uuid]);

  return <></>;
};

export default ConfirmationRedirect;
