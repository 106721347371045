import {
  BrandIconCalendar,
  BrandIconPetrol, BrandIconRebatesSavings,
  BrandIconRoadsTollReliefCTP,
  Card,
  CardTitle,
  Heading,
} from '@snsw-gel/react';
import React from 'react';
import { CardContainer } from './styled';
import { getIsNationalPass } from '../../../../services/NationalParkPassService';
import { helpers } from '@rmstransactions/components';
import NationalPassCard from '../NationalPass/NationalPassCard';

type CardInfo = {
  icon: React.ReactNode
  title: string
  url: string
  description: string
}

const createCard = (card: CardInfo) => {
  return <Card className={'card'} isClickable>
    <CardTitle level={4} icon={card.icon}>
      <a href={card.url} >{card.title}</a>
    </CardTitle>
    <p>{card.description}</p>
  </Card>;
}

export const MoreService = (props: any) => {
  const { confirmationData }: { confirmationData: any } = props;
  const tollReliefCard = createCard({
    icon: <BrandIconRoadsTollReliefCTP />,
    title: 'Toll Relief Rebate',
    url: 'https://www.service.nsw.gov.au/transaction/claim-the-toll-relief-rebate',
    description: 'Eligible motorists who spend more than $402 a year on tolls can claim a 40% rebate.',
  });
  const drnCard = createCard({
    icon: <BrandIconCalendar />,
    title: 'Digital Renewal Notice',
    url: 'https://www.service.nsw.gov.au/transaction/digital-vehicle-registration',
    description: 'Receive a digital notification when your registration is due and access your Certificate of Registration anytime.',
  });
  const cheaperFuelCard = createCard({
    icon: <BrandIconPetrol />,
    title: 'Find cheaper fuel near you',
    url: 'https://www.service.nsw.gov.au/transaction/check-fuel-prices',
    description: 'Use the FuelCheck online tool to find the cheapest fuel at NSW service stations near you.',
  });
  const savingFinderCard = createCard({
    icon: <BrandIconRebatesSavings />,
    title: 'Savings Finder tool',
    url: 'https://www.service.nsw.gov.au/campaign/savings-finder',
    description: ' By answering tailored questions in our savings finder tool, we can help you find rebates and vouchers relevant to you.',
  });


  return (
    <>
      <Heading level={3}>
        More services
      </Heading>
      <CardContainer>
        {tollReliefCard}
        {!helpers.isNotEmptyValue(confirmationData?.drnConsent) && drnCard}
        {getIsNationalPass(confirmationData)? <NationalPassCard confirmationData={confirmationData}/> : cheaperFuelCard}
        {savingFinderCard}
      </CardContainer>
    </>
  );
};
