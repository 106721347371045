import React from 'react';
import {SkeletonContainer, SkeletonShape} from '@snsw-gel/react';
import {Spacer} from '@rmstransactions/components';

const TermsSkeleton = (props: any) => {
  const { registrationScheme }: { registrationScheme: string } = props;
  return (
    <>
      {
        registrationScheme ===  'Conditional' &&
        <Spacer mb={51} >
        <SkeletonContainer>
          <span style={{ float: 'right', width: '790px' }}>
            <div style={{ marginTop: '8px', marginBottom: '7px' }}>
            <SkeletonShape height={16} width='790' />
            </div>
            <div style={{ marginBottom: '7px' }}>
            <SkeletonShape height={16} width='790'   />
            </div>
            <SkeletonShape height={16} width='624' noMargin />
          </span>
          <SkeletonContainer height={200}>
            <SkeletonShape height={34} width={34}  />
          </SkeletonContainer>
        </SkeletonContainer>
        </Spacer>
      }
      <SkeletonContainer>
        <SkeletonShape height={16} width={471} noMargin />
        <Spacer mt={'15'} />
        <SkeletonContainer mb={8}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <SkeletonShape width={34} height={34} noMargin />
            &nbsp;&nbsp;
            <SkeletonShape height={16} width={272} noMargin />
          </div>
        </SkeletonContainer>
      </SkeletonContainer>
    </>
  );
};

export default TermsSkeleton;
