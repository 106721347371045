import React, { useState, useContext } from 'react';
import { PanelContent, PanelToggleButton } from './styled';
import { AnalyticsService } from '@rmstransactions/components';
import { AppState, store } from 'providers/globalState';

interface IContentPanelProps {
  toggleButtonText: any;
  children: any;
  toggleButtonClass: string;
  id: string;
  panelContentClass: string;
}

const ContentPanel: React.FunctionComponent<IContentPanelProps> = ({
  toggleButtonText,
  children,
  id,
  toggleButtonClass,
  panelContentClass,
}) => {
  const { globalState } = useContext(store) as {
    globalState: AppState;
  };
  const [panelOpen, setPanelOpen] = useState(false);
  const accordionEventPushToGa = (panelOpen: Boolean) => {
    let action = panelOpen ? 'Accordion Close' : 'Accordion Open';
    let eventName = toggleButtonText;
    if (toggleButtonText === 'Can I change the term?') {
      let eventName = 'Can I change the term';
      AnalyticsService.Accordion(
        action,
        eventName,
        globalState.transactionStartTime
      );
      setPanelOpen(!panelOpen);
      return;
    }
    AnalyticsService.Accordion(
      action,
      eventName,
      globalState.transactionStartTime
    ); // record Accordion click event in GA
    setPanelOpen(!panelOpen);
  };

  return (
    <>
      <PanelToggleButton
        aria-controls={id}
        aria-expanded={panelOpen ? 'true' : 'false'}
        onClick={() => accordionEventPushToGa(panelOpen)}
        className={`${toggleButtonClass} panelToggle ${
          panelOpen ? 'panelToggle-open' : ''
        }`}
        data-testid={`${id}Button`}
      >
        {toggleButtonText}
      </PanelToggleButton>
      <PanelContent
        role="region"
        id={id}
        className={`${panelContentClass} ${
          panelOpen
            ? 'panelContent panelContent-open'
            : 'panelContent panelContent-closed'
        }`}
      >
        {children}
      </PanelContent>
    </>
  );
};

export default ContentPanel;
