export const Config: AppConfig = {
  apiHost: process.env.REACT_APP_API_HOST || '',
  globalNavEnv: process.env.REACT_APP_GLOBAL_NAV_ENV || ',',
  apiRenewRegoSummaryPath: '/auth/get-rego-renewal-details/',
  apiTransactionPath: '/api/transaction/',
  apiInitiateRenewalPath: '/auth/initiate-renewal/',
  apiSilentLoginPath: '/silentLogin',
  apiCheckLoginPath: '/auth/checkLogin',
  conditionalRegoSummaryActionURL:
    process.env.REACT_APP_CONDITIONAL_ACTION_URL || '',
  myAccDashboardUrl: process.env.REACT_APP_MYACC_DASHBOARD || '',
  sfRenewRegoUrl: process.env.REACT_APP_SF_RENEW_REGO_URL || '',
  sfAutoRedirectRenewRegoUrl:
    process.env.REACT_APP_SF_AUTO_REDIRECT_RENEW_REGO_URL || '',
  veosFeatureEnabled: process.env.REACT_APP_VEOS_FEATURE || 'N',
  veosFeatureEnabledServiceCenterIDS: process.env.REACT_APP_VEOS_SERVICE_CENTER_IDS || '',
  logLevel: process.env.REACT_APP_LOG_LEVEL || 'INFO',
  apiConfirmationPath: '/auth/get-rego-renewal-confirmation',
  apiViewReceiptPath: '/anon/get-rego-renewal-confirmation',
  apiSupportConfirmationPath: '/support/get-rego-renewal-confirmation',
  apiiCalPath: '/auth/generate-calendar/',
  apiViewReceiptiCalPath: '/anon/generate-calendar/',
  apiSupportiCalPath: '/support/generate-calendar/',
  apiUserDetails: '/auth/userDetails',
  apiUserTypePath: '/anon/userType',
  apiRegistrationsByPlateOrBillingNumberAnonPath:
    '/anon/registrationsByPlateOrBillingNumber',
  apiRegistrationsByPlateOrBillingNumberAuthPath:
    '/auth/registrationsByPlateOrBillingNumber',
  apiValidateRenewalAuthPath: '/auth/validateRenewal',
  apiValidateRenewalAnonPath: '/anon/validateRenewal',
  apiRenewalAuthPath: '/auth/renewal',
  apiRenewalAnonPath: '/anon/renewal',
  apiCheckTomeeEligibilityAuthPath: '/auth/checkTollReliefEligibility',
  apiCheckTomeeEligibilityAnonPath: '/anon/checkTollReliefEligibility',
  nationalAnnualPassUI: process.env.REACT_APP_NATIONAL_ANNUAL_PASS_UI || '',
};

export interface AppConfig {
  apiHost: string;
  globalNavEnv: String;
  apiRenewRegoSummaryPath: string;
  apiTransactionPath: string;
  apiInitiateRenewalPath: string;
  apiSilentLoginPath: string;
  apiCheckLoginPath: string;
  conditionalRegoSummaryActionURL: string;
  myAccDashboardUrl: string;
  sfRenewRegoUrl: string;
  sfAutoRedirectRenewRegoUrl: string;
  veosFeatureEnabled: string;
  veosFeatureEnabledServiceCenterIDS: string,
  logLevel: string;
  apiConfirmationPath: string;
  apiSupportConfirmationPath: string;
  apiiCalPath: string;
  apiViewReceiptiCalPath: string;
  apiSupportiCalPath: string;
  apiViewReceiptPath: string;
  apiUserDetails: String;
  apiUserTypePath: String;
  apiRegistrationsByPlateOrBillingNumberAnonPath: string;
  apiRegistrationsByPlateOrBillingNumberAuthPath: string;
  apiValidateRenewalAuthPath: string;
  apiValidateRenewalAnonPath: string;
  apiRenewalAuthPath: string;
  apiRenewalAnonPath: string;
  apiCheckTomeeEligibilityAuthPath: string;
  apiCheckTomeeEligibilityAnonPath: string;
  nationalAnnualPassUI: string;
}
