import React from 'react';
import {
  TransactionDetailsContainer,
  TransactionPriceDetails,
  PriceItemContainer,
  NegativeAmountContainer,
  HeadingDetails,
} from './styled';
import { IRenewRegistrationValidation } from 'types/RenewRegistrationValidation';
import getFormattedPrice from 'helpers/getFormattedPrice';
import { helpers, Spacer } from '@rmstransactions/components';
import { SCHEME_FULL } from '../../../../constants/constants';

interface ITransactionDetailsProps {
  transactionDetails: IRenewRegistrationValidation;
  registrationTerm?: string;
}

function cleanString(s: string) {
  return s.replace(/[^\w!?]/g, '');
}

const PriceDetails: React.FunctionComponent<ITransactionDetailsProps> = ({
  transactionDetails,
  registrationTerm,
}) => {
  let registrationPriceDetails = null;
  if (transactionDetails.registrationScheme === SCHEME_FULL) {
    registrationPriceDetails =
      transactionDetails?.registrationPriceDetails?.filter(
        (price) => price?.term === registrationTerm
      )[0];
  } else {
    registrationPriceDetails = transactionDetails?.registrationPriceDetails[0];
  }
  if (!helpers.isNotEmptyObject(registrationPriceDetails)) {
    return <></>;
  }
  return (
    <TransactionDetailsContainer>
      <TransactionPriceDetails>
        <Spacer mt={32} />
        <HeadingDetails>Renewal fees</HeadingDetails>
        {registrationPriceDetails?.priceItem[0]?.glItem?.map((item) =>
          item.amount.indexOf('-') ? (
            <div className='positiveAmount' key={item.name.replace(' ', '')}>
              <PriceItemContainer
                data-testid={`transactionName${cleanString(item.name)}`}
              >
                {item.name}
              </PriceItemContainer>
              <div data-testid={`transactionAmount${cleanString(item.name)}`}>
                {getFormattedPrice(parseFloat(item.amount))}
              </div>
            </div>
          ) : (
            <div
              className='negativeAmount'
              key={item.name.replace(' ', '') + item.amount}
            >
              <div className='showDiscount'></div>
              <div
                data-testid={`transactionName${cleanString(item.name)}`}
                className='addMarginTopNBottom'
              >
                <NegativeAmountContainer
                  data-testid={`transactionName${cleanString(item.name)}`}
                >
                  &nbsp;&nbsp;{item.name}
                </NegativeAmountContainer>
                <div
                  data-testid={`transactionAmount${cleanString(item.name)}`}
                  className='keepRight'
                >
                  {getFormattedPrice(parseFloat(item.amount))}
                </div>
              </div>
            </div>
          )
        )}
        {transactionDetails.registrationScheme === SCHEME_FULL &&
          (registrationPriceDetails.transactionPrice.gstAmount === null ||
            registrationPriceDetails.transactionPrice.gstAmount === '0') && (
            <div className='positiveAmount'>
              <PriceItemContainer>GST total</PriceItemContainer>
              <div>
                {getFormattedPrice(
                  parseFloat(
                    registrationPriceDetails.transactionPrice.gstAmount
                  )
                )}
              </div>
            </div>
          )}
        <div className='positiveAmount'>
          <PriceItemContainer emphasisedFontSize>
            <strong>Total amount</strong>
          </PriceItemContainer>
          <div>
            {transactionDetails.registrationScheme === SCHEME_FULL ? (
              <strong className='totalAmount' data-testid='totalAmount'>
                {getFormattedPrice(
                  parseFloat(
                    registrationPriceDetails.transactionPrice.grossAmount
                  )
                )}
              </strong>
            ) : (
              <strong className='totalAmount' data-testid='totalAmount'>
                {getFormattedPrice(
                  parseFloat(registrationPriceDetails.priceItem[0].amount)
                )}
              </strong>
            )}
          </div>
        </div>
      </TransactionPriceDetails>
    </TransactionDetailsContainer>
  );
};

export default PriceDetails;
