import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    ComponentLoader
} from '@snsw-gel/react';
import {handleDRIVESSystemMessages} from '../ErrorDetails.helpers';
import {getConfirmationDetails} from "../../../services/ConfirmationService";
import {window} from "../../../helpers/window";
import {Config} from "../../../config/env";
import {ErrorService} from "@rmstransactions/components";
import {DRUPAL_RENEW_REGISTRATION_URL, TRANSACTION_STATE} from "../../../constants/constants";

const ConfirmationBusinessError = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const loadingText = (
        <span>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </span>
    );
    useEffect(() => {
        document.title = 'Service NSW - Renew registration - Error';
        setLoading(true);
        getConfirmationDetails("")
            .then((response: any) => {
                setLoading(false);
                if (response.data?.systemMessages?.message?.length > 0) {
                    handleDRIVESSystemMessages(
                        response.data.systemMessages.message,
                        'CONFIRMATION_ERROR_PAGE',
                        "",
                        history
                    );
                }
            })
            .catch((error: any) => {
                window.scrollTo(0, 0);
                const { apiHost } = Config;
                ErrorService.handleAPIError(error, history, apiHost, TRANSACTION_STATE, DRUPAL_RENEW_REGISTRATION_URL);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history
    ]);

    return (
        <ComponentLoader fullPage label={loadingText} active={loading} />
    );
};

export default ConfirmationBusinessError;
