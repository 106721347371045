import React, {useEffect, useState} from 'react';
import TermsModal from './Components/TermsModal';
import {FormCheckbox} from '@snsw-gel/react';
import { LinkButton } from './styled';
import {
  DOC_ID_T_AND_C_S, DOC_ID_T_AND_C_S_VEOS,
  ERROR_MESSAGE_TERMS_AND_CONDTIONS, ERROR_MESSAGE_TERMS_AND_CONDTIONS_VEOS,
} from '../../constants/constants';
import TermsVEOSModal from "./Components/TermsVEOSModal";

const TermsAndConditions = (props: any) => {
  const { onAcceptTAndC, termsValidationError, onAcceptTAndCVEOS, termsVEOSValidationError, isVEOSAmountContributed } = props;
  const [isChecked, setCheckedState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isCheckedVEOS, setCheckedVEOSState] = useState(false);
  const [showVEOSModal, setShowVEOSModal] = useState(false);

  const acceptTAndCs = () => {
    setShowModal(false);
    setCheckedState(true);
    onAcceptTAndC(true);
  };

  const closeTAndCs = () => {
    setShowModal(false);
  };
  const handleCheckbox = () => {
    setCheckedState(!isChecked);
    onAcceptTAndC(!isChecked);
  };


  const acceptTAndCsVEOS = () => {
    setShowVEOSModal(false);
    setCheckedVEOSState(true);
    onAcceptTAndCVEOS(true);
  };

  const closeTAndCsVEOS = () => {
    setShowVEOSModal(false);
  };
  const handleCheckboxVEOS = () => {
    setCheckedVEOSState(!isCheckedVEOS);
    onAcceptTAndCVEOS(!isCheckedVEOS);
  };

  useEffect(() => {
    if (isVEOSAmountContributed === false) {
      setCheckedVEOSState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVEOSAmountContributed]);

  return (
    <>
      <span>
        Please read the{' '}
        <LinkButton
          role='button'
          className='link-button'
          data-testid='showTAndCsModal'
          onClick={() => {
            setShowModal(true);
          }}
        >
          Registration renewal Terms and Conditions
        </LinkButton>
        {isVEOSAmountContributed && <span> and the{' '}
          <LinkButton
              role='button'
              className='link-button'
              onClick={() =>setShowVEOSModal(true)}
              data-testid='showTAndCsVEOSModal'
          >
            Carbon offset contribution Terms and Conditions
          </LinkButton>
        </span>}{' '}
        before proceeding.
      </span>
      <FormCheckbox
        id={DOC_ID_T_AND_C_S}
        name='FormCheckbox name'
        label='I accept the Registration renewal Terms and Conditions'
        value='FormCheckbox value'
        errorMessage={ERROR_MESSAGE_TERMS_AND_CONDTIONS}
        hasError={termsValidationError}
        data-testid='tAndCCheckBox'
        onChange={handleCheckbox}
        checked={isChecked}
      />
      {showModal && (
        <TermsModal acceptTAndCs={acceptTAndCs} closeTAndCs={closeTAndCs} />
      )}
      {isVEOSAmountContributed &&
        <>
          <FormCheckbox
            id={DOC_ID_T_AND_C_S_VEOS}
            name='FormCheckbox name VEOS'
            label='I accept the Carbon offset contribution Terms and Conditions'
            value='FormCheckbox value VEOS'
            errorMessage={ERROR_MESSAGE_TERMS_AND_CONDTIONS_VEOS}
            hasError={termsVEOSValidationError}
            data-testid='tAndCVEOSCheckBox'
            onChange={handleCheckboxVEOS}
            checked={isCheckedVEOS}
          />
          {showVEOSModal && (
            <TermsVEOSModal acceptTAndCs={acceptTAndCsVEOS} closeTAndCs={closeTAndCsVEOS} />
          )}
        </>
      }
    </>
  );
};

export default TermsAndConditions;
