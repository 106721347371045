interface IProductItem {
  amount: number;
  name: string;
}

interface ITransactionDetailsSummary {
  totalAmount: number;
  items: IProductItem[];
}

interface IRegistrationPriceDetail {
  priceItem: ITransactionDetailsSummary;
  registrationTermMonths: number;
}

interface ITransactionDetailsComplete {
  receiptNumber: string;
  totalAmount: number;
  carbonOffsetAmount: number;
  transactionDate: string;
  adminAmountRebate: number;
  droughtReliefRebate: number;
  totalRebates: number;
}

export type { ITransactionDetailsComplete, ITransactionDetailsSummary, IRegistrationPriceDetail };

export function isTransactionDetailsCompleteData(
  data: any
): data is ITransactionDetailsComplete {
  return (
    !!data &&
    typeof data.transactionDate === 'string' &&
    typeof data.totalAmount === 'number'
  );
}

