import React, { FunctionComponent } from 'react';
import { VehicleRegistrationCardContainer } from './styled';
import { ScreenReaderText } from '@rmstransactions/components/Styled';
import { IRegistrationSummary } from 'types/Registration';

import { getFormattedDate } from 'helpers/dateFunctions';
import { isDateTodayOrInFuture } from 'helpers/dateFunctions';

interface IVehicleRegistrationCard {
  registrationDetails: IRegistrationSummary;
}

const VehicleRegistrationCard: FunctionComponent<IVehicleRegistrationCard> = ({
  registrationDetails,
}) => {
  return (
    <VehicleRegistrationCardContainer>
      <div className="plateNumber">
        <ScreenReaderText>Plate number</ScreenReaderText>{' '}
        <span data-testid="plateNumber">{registrationDetails.plateNumber}</span>
      </div>
      <div data-testid="vehicleDescription">
        {registrationDetails.description}
      </div>
      <div>
        VIN/Chassis/Serial No:{' '}
        <span data-testid="vinNumber">{registrationDetails.vinNumber}</span>
      </div>
      <div>
        {isDateTodayOrInFuture(registrationDetails.expiryDate)
          ? 'Current expiry:'
          : 'Expired:'}{' '}
        <span data-testid="expiryDate">
          {getFormattedDate(registrationDetails.expiryDate)}
        </span>
      </div>
    </VehicleRegistrationCardContainer>
  );
};

export default VehicleRegistrationCard;
