import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const ButtonContainer = styled.div`
    margin-top: 1rem;

    @media screen and (min-width: ${tokens.breakpoints.md}px) {
        margin-top: 0;  
    }
`;

export const HelpTextBottom = styled.span`
  font-size: 0.875rem;
  line-height: 1.4;
  color: ${tokens.colors.forms.helpText};
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  display: block;
`;
