import React from 'react';
import { H2 } from '@snsw/react-component-library';
import { helpers } from '../Helpers/helpers';
import {AccessibleNumber}  from '../index';

const VehicleCardDetails = ({
  plateNumber = '',
  billingNumber = '',
  registeredVehicle = {},
  expiryDate = '',
  registrationScheme = '',
  isRegisteredOperator = false,
  searchResults = false,
}) => {
  var manufacturer = '';
  var model = '';
  var bodyShape = '';
  var primaryColour = '';
  var manufactureYear = '';
  var vinChassisLabel =
    registrationScheme === 'Full' ? 'VIN/Chassis:' : 'VIN/Chassis/Serial No:';
  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.manufacturer) &&
    helpers.isNotEmptyValue(
      registeredVehicle.vehicleModel.manufacturer.description
    )
  ) {
    manufacturer =
      registeredVehicle.vehicleModel.manufacturer.description + ' ';
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.model) &&
    helpers.isNotEmptyValue(registeredVehicle.vehicleModel.model.description)
  ) {
    model = registeredVehicle.vehicleModel.model.description + ' ';
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.bodyShape) &&
    helpers.isNotEmptyValue(
      registeredVehicle.vehicleModel.bodyShape.description
    )
  ) {
    bodyShape = registeredVehicle.vehicleModel.bodyShape.description + ' ';
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.primaryColour) &&
    helpers.isNotEmptyValue(registeredVehicle.primaryColour.description)
  ) {
    primaryColour = '- ' + registeredVehicle.primaryColour.description + ' ';
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.manufactureYear) &&
    helpers.isNotEmptyValue(registeredVehicle.vehicleModel.manufactureYear)
  ) {
    manufactureYear = '- ' + registeredVehicle.vehicleModel.manufactureYear;
  }

  const vehicleInfo =
    manufacturer + model + bodyShape + primaryColour + manufactureYear;

  const currentExpiryText = searchResults
    ? 'Registration expires:'
    : 'Current expiry:';

  const vehicleExpiry =
    (helpers.isDateTodayOrInFuture(expiryDate)
      ? currentExpiryText
      : 'Registration expired:') +
    ' ' +
    helpers.dateFormat(expiryDate, 'D MMMM YYYY');

  return (
    <div data-testid='vehicle-info'>
      <H2>{plateNumber}</H2>
      {searchResults ? (
        <div>{vehicleInfo}</div>
      ) : (
        <strong>{vehicleInfo}</strong>
      )}
      <div>
        {vinChassisLabel}{' '}
        {isRegisteredOperator
          ? registeredVehicle.vin && registeredVehicle.vin.vinNumber
              ? registeredVehicle.vin.vinNumber
              : registeredVehicle.chassisNumber
          : '****' + registeredVehicle.last4DigitalOfVINChassisNumber}
      </div>
      {registrationScheme === 'Full' && (
          <div>
            Billing number: <AccessibleNumber value={billingNumber} />
          </div>
      )}
      {searchResults ? (
        <strong>{vehicleExpiry}</strong>
      ) : (
        <div>{vehicleExpiry}</div>
      )}
    </div>
  );
};

export default VehicleCardDetails;
