import React from 'react';
import { MoreInfoModal } from '@snsw-gel/react';
import UsageToolTipModal from './UsageToolTipModal';
import { MoreInfoContainer } from '../../../../styled';

const UsageToolTip = (props: any) => {
  const {
    allowedRegistrationUsage,
    currentUsage,
  }: { allowedRegistrationUsage: any; currentUsage: any } = props;
  return (
    <MoreInfoContainer
      data-testid='usage-type-more-info'
      style={{ top: '2px', position: 'relative' }}
    >
      <MoreInfoModal
        helpText={
          <UsageToolTipModal
            allowedRegistrationUsage={allowedRegistrationUsage}
            currentUsage={currentUsage}
          />
        }
        title='What is vehicle usage?'
        questionIconFlag
      />
    </MoreInfoContainer>
  );
};

export default UsageToolTip;
