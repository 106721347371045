import React from 'react';
import ReactDOM from 'react-dom';
//import * as serviceWorker from 'serviceWorker';
import { debugLogger } from 'services/LoggerService';
import App from 'App';

function Root() {
  const html = <App />;
  return html;
}

debugLogger(
  'process.env.REACT_APP_USE_MOCK_API',
  process.env.REACT_APP_USE_MOCK_API
);
if (process.env.REACT_APP_USE_MOCK_API) {
  debugLogger('Inside mock', process.env.REACT_APP_USE_MOCK_API);
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(<Root />, document.getElementById('root'));

//serviceWorker.unregister();
