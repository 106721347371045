import React, { useEffect, useRef, useState } from 'react';
import { AllowedUsageType } from '../../../../types/AllowedUsageTypes';
import {
  Button,
  FormGroup,
  Input,
  IconNotificationError,
  Select,
} from '@snsw-gel/react';
import UsageToolTip from './UsageToolTip';
import { Spacer } from '@rmstransactions/components';
import {
  ChangeUsageConfirmeError,
  Heading,
  UsageListContainer,
  ChangeUsageNotesHeading,
  ChangeUsageNotesContainer,
} from './styled';
import {
  DOC_ID_CHANGE_USAGE_APPLY_BTN,
  ERROR_MESSAGE_CONFIRM_CHANGE_USAGE,
  INITIAL_QUOTE,
  NO_CARD_ENTERED,
  INVALID_CARD_MAX10CHAR,
  PENSIONER_USAGE_TYPE_CODES,
  PENSIONER_DESCRIPTION,
} from 'constants/constants';
import InlineNotification from '../../../ErrorPage/components/InlineNotification';
import { LinkButton } from 'components/TermsAndConditions/styled';

const ChangeUsageDisplay = (props: any) => {
  let {
    usageType,
    allowedUsageTypes,
    pensionerCard,
    setUsageChange,
    callValidateApi,
    registration,
    eligibility,
    selectedUsage,
    setSelectedUsage,
    changeUsageInlineErrorOrWarnings,
    setChangeUsageInlineErrorOrWarnings,
    setShowModalPPG,
    safetyCheckFlag,
    ctpFlag,
    safetyOrCTPIsMissing,
    isChangeUsageConfirmed,
    setIsChangeUsageConfirmed,
    setPensionerCard,
  }: {
    usageType: any;
    allowedUsageTypes: AllowedUsageType[];
    pensionerCard: boolean;
    setUsageChange: any;
    callValidateApi: any;
    registration: any;
    eligibility: any;
    selectedUsage: any;
    setSelectedUsage: any;
    changeUsageInlineErrorOrWarnings: any;
    setChangeUsageInlineErrorOrWarnings: any;
    setShowModalPPG: any;
    safetyCheckFlag: any;
    ctpFlag: any;
    safetyOrCTPIsMissing: any;
    isChangeUsageConfirmed: any;
    setIsChangeUsageConfirmed: any;
    setPensionerCard: any;
  } = props;
  let usageOptions: AllowedUsageType[] = allowedUsageTypes;
  const [hasInputError, setHasInputError] = useState(false);
  const [pensionerCardNumber, setPensionerCardNumber] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const usageDropDownRef = useRef<HTMLSelectElement | null>(null);
  let usageDescriptions = usageOptions.map((option) => option.description);

  const handleCancel = () => {
    setSelectedUsage('');
    setPensionerCardNumber('');
    setPensionerCard(false);
    setIsChangeUsageConfirmed(true);
    setUsageChange(false);
    setChangeUsageInlineErrorOrWarnings([]);
  };

  const extractDescriptionsAndSort = () => {
    const sortedUsageOptions = usageDescriptions
      .map((option: string) => ({
        text: option,
        value: option,
      }))
      .sort((a: any, b: any) =>
        a.text > b.text ? 1 : b.text > a.text ? -1 : 0
      );
    const uniqueSortedUsageTypes: any[] = [];
    sortedUsageOptions.forEach((sortedUsage: any, index) => {
      if (
        index === 0 ||
        (index > 0 &&
          sortedUsage?.value !== sortedUsageOptions[index - 1]?.value)
      ) {
        uniqueSortedUsageTypes.push(sortedUsage);
      }
    });
    const placeHolder = setPlaceHolderText();
    const finalUsageTypes: any[] = [];
    uniqueSortedUsageTypes.forEach((sortedUsage: any) => {
      if (placeHolder.indexOf(sortedUsage?.value) < 0) {
        finalUsageTypes.push(sortedUsage);
      }
    });
    if (
      pensionerCard &&
      PENSIONER_USAGE_TYPE_CODES.indexOf(usageType?.code) <= -1
    ) {
      const currentUsageDescription = usageType?.description + ' (current)';
      finalUsageTypes.push({
        text: currentUsageDescription,
        value: usageType?.description,
      });
    }
    finalUsageTypes.sort((a: any, b: any) =>
      a.text > b.text ? 1 : b.text > a.text ? -1 : 0
    );
    return finalUsageTypes;
  };

  const setPlaceHolderText = () => {
    let placeHolderText: string | undefined =
      usageType?.description + ' (current)';
    if (pensionerCard) {
      placeHolderText = PENSIONER_DESCRIPTION;
      if (['PNSR', 'PNIC'].indexOf(usageType?.code) >= 0) {
        placeHolderText = 'Pensioner Concession (current)';
      }
    }
    return placeHolderText;
  };

  const validatePensionerCard = () => {
    if (pensionerCardNumber === '') {
      setErrorMsg(NO_CARD_ENTERED);
    } else if (pensionerCardNumber.length > 10) {
      setErrorMsg(INVALID_CARD_MAX10CHAR);
    } else {
      setErrorMsg('');
    }
  };

  const pensionerUsageTypeCalculator = () => {
    let usageCode;
    if (pensionerCardNumber.length === 10) {
      usageCode = allowedUsageTypes?.filter((usage) => usage?.code === 'PNSR');
      if (usageCode.length <= 0) {
        usageCode = [
          {
            code: 'PNSR',
            description: PENSIONER_DESCRIPTION,
          },
        ];
      }
    } else {
      usageCode = allowedUsageTypes?.filter((usage) => usage?.code === 'PNIC');
      if (usageCode.length <= 0) {
        usageCode = [
          {
            code: 'PNIC',
            description: PENSIONER_DESCRIPTION,
          },
        ];
      }
    }
    return usageCode;
  };

  const onCardNumberChange = (e: any) => {
    setChangeUsageInlineErrorOrWarnings([]);
    setPensionerCardNumber(
      e.target.value.replace(/-|\.|!|@|#|\$|%|\^|&|\*|\s/g, '')
    );
  };

  const handleChangeUsage = () => {
    setChangeUsageInlineErrorOrWarnings([]);
    let currentUsage;
    let pensionerDetails;
    if (selectedUsage === PENSIONER_DESCRIPTION) {
      validatePensionerCard();
      if (pensionerCardNumber === '' || pensionerCardNumber.length > 10) {
        setHasInputError(true);
        return;
      } else {
        if (pensionerCardNumber !== '') {
          currentUsage = pensionerUsageTypeCalculator();
          pensionerDetails = {
            number: pensionerCardNumber,
            type:
              currentUsage[0]?.code === 'PNSR'
                ? 'Centrelink'
                : 'Department of Veteran Affairs',
          };
        } else {
          currentUsage = allowedUsageTypes.filter((usage) => {
            return usage.description === selectedUsage;
          });
        }
      }
    } else {
      currentUsage = allowedUsageTypes.filter((usage) => {
        return usage.description === selectedUsage;
      });
    }

    let validateRequest: any = {
      registrationID: eligibility?.registrationEligibilityInfo?.registrationID,
      plate: registration?.plate,
      registrationScheme:
        eligibility?.registrationEligibilityInfo?.registrationScheme,
      validationType: INITIAL_QUOTE,
      registrationUsage: currentUsage[0],
    };
    if (pensionerDetails) {
      validateRequest.pensionerDetails = pensionerDetails;
    }
    callValidateApi(validateRequest);
  };

  const handleChangeForm = (e: any) => {
    setSelectedUsage(e.target.value);
    if (pensionerCard && e.target.value === '') {
      setSelectedUsage(PENSIONER_DESCRIPTION);
    }
    setPensionerCardNumber('');
    setChangeUsageInlineErrorOrWarnings([]);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setShowModalPPG(true);
  };

  const safetyCheckNotice = () => {
    let warningMessage = {
      severity: 'WARNING',
      messageDescription:
        'Booking Service Vehicles require a safety check, even if the vehicle is less than 5 years old. ' +
        '<a href="https://www.service.nsw.gov.au/transaction/find-authorised-safety-checkinspection-station" target="_blank"' +
        ' rel="noopener noreferrer">Find a safety check station.</a>' +
        '<br />' +
        '<br />' +
        'If the vehicle has not had a safety check, you can continue with the' +
        "registration renewal under the vehicle's previous renewal usage. Once" +
        'you have a safety check, you can change the usage to ‘Booking Service' +
        'Vehicle’ by calling us on <a href="tel:137788">13 77 88</a> or visiting a ' +
        '<a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>. Additional fees may apply.',
      messageTitle: 'Safety check required for Booking Service Vehicle',
    };
    return <InlineNotification errorMessageObject={warningMessage} />;
  };

  const isNewUsageSelected =
    selectedUsage !== '' &&
    (selectedUsage?.indexOf(usageType?.description) < 0 ||
      usageType?.description?.indexOf(PENSIONER_DESCRIPTION) >= 0);

  const applyButton = (
    <>
      <Button
        id={DOC_ID_CHANGE_USAGE_APPLY_BTN}
        variant='secondary'
        data-testid='applyButton'
        onClick={handleChangeUsage}
      >
        Apply
      </Button>
      <p>
        {!isChangeUsageConfirmed && (
          <p>
            <IconNotificationError />
            <ChangeUsageConfirmeError>
              {ERROR_MESSAGE_CONFIRM_CHANGE_USAGE}
            </ChangeUsageConfirmeError>
          </p>
        )}
      </p>
    </>
  );

  const changeUsageNotes = () => {
    if (selectedUsage !== '') {
      if (
        selectedUsage === 'Primary Producer' ||
        selectedUsage === PENSIONER_DESCRIPTION
      ) {
        return (
          <>
            <ChangeUsageNotesHeading>Notes:</ChangeUsageNotesHeading>
            <ChangeUsageNotesContainer>
              {selectedUsage === 'Primary Producer' && (
                <li>
                  {' '}
                  to apply this usage, you must be a{' '}
                  <LinkButton
                    role='button'
                    className='link-button'
                    data-testid='showPrimaryProducerModal'
                    onClick={handleClick}
                  >
                    primary producer
                  </LinkButton>{' '}
                  and use this vehicle for primary producer purposes.
                </li>
              )}
              {selectedUsage === PENSIONER_DESCRIPTION && (
                <li>
                  {' '}
                  eligible card holders are entitled to free registration for
                  one vehicle{' '}
                </li>
              )}
              <li>
                make sure your CTP insurance covers the usage type you select.
              </li>
            </ChangeUsageNotesContainer>
          </>
        );
      } else if (ctpFlag !== 'N') {
        return (
          <>
            <p>
              Note: make sure your CTP insurance covers the usage type you
              select.
            </p>
          </>
        );
      }
    }
  };

  const showPensionerCardInput = () => {
    if (selectedUsage === PENSIONER_DESCRIPTION) {
      return (
        <p>
          <FormGroup
            label='Enter a Centrelink or Veteran Card (DVA) number'
            errorMessage={errorMsg}
            hasError={hasInputError}
            id='labelPensionerCardNumber'
          >
            <Input
              onChange={onCardNumberChange}
              data-testid='pensionerCardNumber'
              inputWidth='xl'
            />
          </FormGroup>
        </p>
      );
    } else {
      return;
    }
  };

  const CancelButton = () => {
    return (
      <Button
        variant='link'
        tabIndex={0}
        data-testid='cancelButton'
        onClick={handleCancel}
      >
        Cancel
      </Button>
    );
  };

  return (
    <>
      <Spacer marginTop={'24px'} marginBottom={0}>
        <Heading>
          <span>
            <strong>Select usage&nbsp;</strong>
            <UsageToolTip allowedRegistrationUsage={allowedUsageTypes} />
          </span>
          <span className='hideOnLargerDevice keepLinkRight'>
            <CancelButton />
          </span>
        </Heading>
      </Spacer>
      <UsageListContainer>
        <span className='select-item'>
          <Select
            options={extractDescriptionsAndSort()}
            id='usageDropDown'
            isRequired
            name='usageDropDown'
            inputRef={usageDropDownRef}
            autoFocus='autoFocus'
            inputWidth='xl'
            onChange={handleChangeForm}
            placeholder={setPlaceHolderText()}
            data-testid='usageDropDown'
          />
        </span>
        <span className='hideOnSmallerDevice cancelButton'>
          <CancelButton />
        </span>
      </UsageListContainer>
      {selectedUsage === 'Booking Service Vehicle' &&
        safetyCheckFlag === 'N' &&
        safetyCheckNotice()}
      {showPensionerCardInput()}
      {isNewUsageSelected && changeUsageNotes()}
      {changeUsageInlineErrorOrWarnings?.length > 0 &&
        changeUsageInlineErrorOrWarnings?.map((inlineErrorOrWarning: any) => {
          return (
            <InlineNotification errorMessageObject={inlineErrorOrWarning} />
          );
        })}
      {isNewUsageSelected && applyButton}
    </>
  );
};

export default ChangeUsageDisplay;
