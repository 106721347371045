import React, { useContext } from 'react';
import { InPageAlert } from '@snsw-gel/react';
import { IEligibilityFlagsResult } from '../../../../types/Registration';
import { AppState, store } from '../../../../providers/globalState';
import { isMobileOnly } from 'react-device-detect';
import {
  nowrapTelephone,
  stripTelForNonMobileDevices,
} from '../../../../helpers/error-processor';
import { CTPSafetyCheckNotificationContainer } from './styled';
import { Spacer } from '@rmstransactions/components';
import { HVIS_CONDITION_CATEGORY_CODES } from '../../../../constants/constants';

const CTPSafetyCheckNotifications = (props: any) => {
  const {
    eligibilityFlagsResult,
  }: { eligibilityFlagsResult: IEligibilityFlagsResult } = props;
  const { globalState } = useContext(store) as {
    globalState: AppState;
  };

  const lightBodyContent =
    'Most light vehicles more than 5 years old require a safety inspection. <a href="https://www.service.nsw.gov.au/transaction/find-authorised-safety-checkinspection-station" target="_blank" rel="noopener noreferrer">Find a safety inspection station</a>.';

  const hvisBodyContent =
    'You need to complete an HVIS inspection. You can <a class="externalLink" href="https://myrta.com/ibs/" target="_blank" rel="noopener noreferrer">book an HVIS inspection</a> online.' +
    '<div style="line-height:0.5"><br/></div>' +
    '<div style="margin-bottom: 14px">If there are no HVIS bookings available, you can provide an <a href="https://www.service.nsw.gov.au/transaction/find-authorised-safety-checkinspection-station" target="_blank" rel="noopener noreferrer">HVAIS inspection</a> report and then complete an HVIS inspection within 28 days after your renewal.</div>';

  let hvAISBodyContent =
    'You need to complete an HVAIS inspection. You’ll have 42 days to renew your registration after completing the inspection. <a href="https://www.service.nsw.gov.au/transaction/find-authorised-safety-checkinspection-station" target="_blank" rel="noopener noreferrer">Find an HVAIS station</a>.' +
    '<div style="line-height:0.6"><br/></div>' +
    '<div style="margin-bottom: 14px">If there are no HVAIS stations in your area, or if you are unable to book an inspection before the registration expires, contact the Heavy Vehicle Support Team on <a href="tel:1300364847">1300 364 847</a>.</div>';

  hvAISBodyContent = !isMobileOnly
    ? stripTelForNonMobileDevices(nowrapTelephone(hvAISBodyContent))
    : nowrapTelephone(hvAISBodyContent);

  const isCTPMissing = eligibilityFlagsResult.ctpFlag === 'I';
  const isSafetyInspectionMissing =
    eligibilityFlagsResult.computedSafetyCheckFlag === 'I';
  const isHVInspectionMissing =
    eligibilityFlagsResult.hvInspectionFlag === 'I' &&
    eligibilityFlagsResult.safetyInspectionFlag === 'I';

  const isHVIS =
    isHVInspectionMissing &&
    globalState?.reviewDetailsInput?.registration?.conditions?.registrationCondition?.filter(
      (condition: { code: string; codeCategory: string }) => {
        return (
          HVIS_CONDITION_CATEGORY_CODES.indexOf(
            condition?.codeCategory + condition?.code
          ) >= 0
        );
      }
    )?.length > 0;

  const heading =
    isCTPMissing && isSafetyInspectionMissing
      ? 'Safety check and CTP (Green Slip) insurance required to continue'
      : isCTPMissing && !isSafetyInspectionMissing
      ? 'CTP (Green Slip) insurance required to continue'
      : !isCTPMissing && isSafetyInspectionMissing
      ? 'Safety check required to continue'
      : '';
  let safetyBodyContent = isHVIS
    ? hvisBodyContent
    : isHVInspectionMissing
    ? hvAISBodyContent
    : isSafetyInspectionMissing
    ? lightBodyContent
    : '';

  return (
    <CTPSafetyCheckNotificationContainer>
      {heading !== '' && (
        <InPageAlert variant="error" title={heading}>
            {isSafetyInspectionMissing && (
              <p>
                {isCTPMissing && <strong>Safety check/inspection: </strong>}
                <span
                  dangerouslySetInnerHTML={{ __html: safetyBodyContent }}
                ></span>
                <Spacer mb={'6px'} />
              </p>
            )}
            {isCTPMissing && (
              <>
                <p>
                  {isSafetyInspectionMissing && (
                    <strong>CTP (Green Slip) insurance: </strong>
                  )}
                  The CTP insurance term must exactly match the term you wish to
                  renew for. For example, if you want to renew for 6 months,
                  you’ll need to purchase a 6-month CTP. You can compare
                  insurance costs by using{' '}
                  <a
                    href="https://www.service.nsw.gov.au/transaction/use-green-slip-price-check"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Green Slip Price Check
                  </a>
                  .
                </p>{' '}
                <Spacer mb={'10px'} />
                <p>
                  If you have just bought CTP insurance, please allow one hour for the details to reach us
                  electronically, and then try again. If you bought your CTP insurance days or weeks in advance, contact
                  your insurer to check your policy is current and then try again. </p>
              </>
            )}
        </InPageAlert>
      )}
    </CTPSafetyCheckNotificationContainer>
  );
};

export default CTPSafetyCheckNotifications;
