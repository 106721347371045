import React from 'react';
import { LinkButton } from '../../../../components/TermsAndConditions/styled';
import { PipeContainer } from './styled';
import { PENSIONER_DESCRIPTION } from '../../../../constants/constants';

const ChangeUsageSection = (props: any) => {
  let {
    allowUsageChange,
    isPensioner,
    setUsageChange,
    pensionerCard,
    setPensionerCard,
    setSelectedUsage,
  }: {
    allowUsageChange: boolean;
    isPensioner: boolean;
    setUsageChange: any;
    pensionerCard: boolean;
    setPensionerCard: any;
    setSelectedUsage: any;
  } = props;

  const handleChangeUsage = () => {
    setUsageChange(true);
    if (pensionerCard === true) {
      setPensionerCard(false);
    }
  };

  const handleAddPensionerCard = () => {
    setUsageChange(true);
    setPensionerCard(true);
    setSelectedUsage(PENSIONER_DESCRIPTION);
  };

  return (
    <span>
      {allowUsageChange && (
        <LinkButton
          href='javascript:void(0)'
          tabIndex={0}
          data-testid='changeUsage'
          onClick={handleChangeUsage}
        >
          Change usage
        </LinkButton>
      )}
      {allowUsageChange && isPensioner && (
        <>
          <PipeContainer>|</PipeContainer>
          <span>
            <LinkButton
              tabIndex={0}
              href='javascript:void(0)'
              data-testid='addPensionerCard'
              onClick={handleAddPensionerCard}
            >
              Add pensioner card
            </LinkButton>
          </span>
        </>
      )}
    </span>
  );
};

export default ChangeUsageSection;
