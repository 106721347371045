import React from 'react';
import { helpers } from '@rmstransactions/components/src/Helpers/helpers';
import { AccessibleNumber } from '@rmstransactions/components/src';
import { getVehicleIcon } from './VehicleIconMapping';
import * as Styled from './EnhancedVehicleCardDetails.styled';

const EnhancedVehicleCardDetails = (props: any) => {
  const {
    plateNumber,
    billingNumber,
    registeredVehicle,
    expiryDate,
    registrationScheme,
    isRegisteredOperator,
    searchResults,
  } = props;

  var manufacturer = '';
  var model = '';
  var bodyShape = '';
  var primaryColour = '';
  var manufactureYear = '';
  var vinChassisLabel =
    registrationScheme === 'Full' ? 'VIN/Chassis:' : 'VIN/Chassis/Serial No:';
  var bodyShapeCode = '';

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.manufacturer) &&
    helpers.isNotEmptyValue(
      registeredVehicle.vehicleModel.manufacturer.description
    )
  ) {
    manufacturer =
      registeredVehicle.vehicleModel.manufacturer.description + ' ';
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.model) &&
    helpers.isNotEmptyValue(registeredVehicle.vehicleModel.model.description)
  ) {
    model = registeredVehicle.vehicleModel.model.description + ' ';
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.bodyShape) &&
    helpers.isNotEmptyValue(
      registeredVehicle.vehicleModel.bodyShape.description
    )
  ) {
    bodyShape = registeredVehicle.vehicleModel.bodyShape.description + ' ';
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.bodyShape) &&
    helpers.isNotEmptyValue(registeredVehicle.vehicleModel.bodyShape.code)
  ) {
    bodyShapeCode = registeredVehicle.vehicleModel.bodyShape.code;
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.primaryColour) &&
    helpers.isNotEmptyValue(registeredVehicle.primaryColour.description)
  ) {
    primaryColour = '- ' + registeredVehicle.primaryColour.description + ' ';
  }

  if (
    helpers.isNotEmptyObject(registeredVehicle) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel) &&
    helpers.isNotEmptyObject(registeredVehicle.vehicleModel.manufactureYear) &&
    helpers.isNotEmptyValue(registeredVehicle.vehicleModel.manufactureYear)
  ) {
    manufactureYear = '- ' + registeredVehicle.vehicleModel.manufactureYear;
  }

  const vehicleInfo = bodyShape + primaryColour + manufactureYear;

  const currentExpiryText = searchResults
    ? 'Registration expires:'
    : 'Current expiry:';

  const vehicleExpiry =
    (helpers.isDateTodayOrInFuture(expiryDate)
      ? currentExpiryText
      : 'Registration expired:') +
    ' ' +
    helpers.dateFormat(expiryDate, 'D MMMM YYYY');

  return (
    <div data-testid='enhanced-vehicle-info'>
      <Styled.GridContainer>
        <Styled.VehicleDetailsCardContainer>
          {getVehicleIcon(bodyShapeCode)}
          <div>
            <Styled.HeadingDetails>{plateNumber}</Styled.HeadingDetails>
            <Styled.ParagraphDetails>
              {manufacturer + model}
              <br />
              {vehicleInfo}
            </Styled.ParagraphDetails>
          </div>
        </Styled.VehicleDetailsCardContainer>
        <Styled.HorizontalDivider />
        <Styled.RegistrationDetails>
          <Styled.RowText>
            {vinChassisLabel}{' '}
            {isRegisteredOperator
              ? registeredVehicle.vin && registeredVehicle.vin.vinNumber
                ? registeredVehicle.vin.vinNumber
                : registeredVehicle.chassisNumber
              : '****' + registeredVehicle.last4DigitalOfVINChassisNumber}
          </Styled.RowText>
          {registrationScheme === 'Full' && (
            <Styled.RowText>
              Billing number: <AccessibleNumber value={billingNumber} />
            </Styled.RowText>
          )}
          <Styled.RowText>{vehicleExpiry}</Styled.RowText>
        </Styled.RegistrationDetails>
      </Styled.GridContainer>
    </div>
  );
};

export default EnhancedVehicleCardDetails;
