import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const notificationIconSize = 'xl';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 1rem;
  background-color: ${tokens.colors.backgrounds.info};
  border-radius: 0.5rem;
  padding: 1.5rem;
  gap: 1.5rem;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-template-columns: auto auto auto;
  }
`;

export const VehicleDetailsCardContainer = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: auto;
  align-items: start;
  grid-column-gap: 1rem;
`;

export const HeadingDetails = styled.h2`
  margin-top: 0;
  margin-bottom: 0rem;
`;

export const ParagraphDetails = styled.p`
  grid-column: 1 / span 2;
  margin-top: 0rem;
  margin-bottom: 0rem;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-column: 2 / span 2;
  }
`;

export const HorizontalDivider = styled.div`
  border-top: 1px solid ${tokens.colors.grey.geyser};

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    border-left: 1px solid ${tokens.colors.grey.geyser};
    border-top: none;
  }
`;

export const RegistrationDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const RowText = styled.div`
  margin-bottom: 0.25rem;
`;
