import React from 'react';
import { Modal } from '@snsw-gel/react';
import { Spacer } from '@rmstransactions/components';

const PrimaryProducerModal = (props: any) => {
  let {
    setShowModalPPG,
  }: {
    setShowModalPPG: any;
  } = props;

  const handleCloseButton = () => {
    setShowModalPPG(false);
  };
  return (
    <Modal
      title="Primary Producer"
      buttons={[
        {
          text: 'Close',
          onClick: handleCloseButton,
        },
      ]}
    >
      <p>
        A primary producer is a person who cultivates or uses their own land or
        the land of another for their own benefit:
        <ul>
          <li>
            for the production of fruit, grain, flowers, vegetables, tobacco or
            farm or agricultural produce of any description, or
            <li>
              for dairy farming, poultry or other bird farming, pig farming, bee
              keeping or oyster or fish culture, or
            </li>
            <li> for a nursery, or </li>
            <li>
              as a pastoralist for the rearing or grazing of horses, cattle or
              sheep, or
            </li>
            <li>
              for gathering leaves from which eucalyptus or other oil is to be
              distilled.
            </li>
          </li>
        </ul>
        Companies who are in the transport/haulage business and cart other
        people’s primary produce for hire or reward are not eligible for the
        concession.
      </p>
      <p>
        <Spacer mt={23}>
          <h3>Primary Producer Vehicle</h3>
        </Spacer>
        A primary producer vehicle means a <strong>Light Vehicle</strong> that
        is owned by a primary producer which is not used or let for hire, and is
        used solely or principally:
        <ul>
          <li>
            to cart primary products that I or another primary producer has
            produced, or{' '}
          </li>
          <li>
            to cart leaves which I or another primary producer has gathered and
            from which eucalyptus or other oil is to be distilled, or{' '}
          </li>
          <li>
            to cart goods of any kind for use in my primary production business
            of the owner or of another primary producer, or{' '}
          </li>
          <li>
            to cart goods of any kind for use in my household, or that of a
            member of the rural society who is a primary producer or of another
            primary producer, or
          </li>
          <li>
            {' '}
            purposes connected with the clearing of land that I or another
            primary producer proposes to use for primary production.
          </li>
        </ul>
        <h3
          style={{
            textAlign: 'center',
          }}
        >
          <strong> OR </strong>
        </h3>
        A primary producer vehicle means a <strong>Heavy Vehicle</strong> that
        is owned by a primary producer which is not used or let for hire, and is
        used solely:
        <ul>
          <li>
            to cart primary products that I or another primary producer has
            produced, or{' '}
          </li>
          <li>
            to cart leaves which I or another primary producer has gathered and
            from which eucalyptus or other oil is to be distilled, or
          </li>
          <li>
            {' '}
            to cart goods of any kind for use in my primary production business
            or of another primary producer, or
          </li>
          <li>
            {' '}
            purposes connected with the clearing of land that I or another
            primary producer proposes to use for primary production.
          </li>
        </ul>
        A <strong>rural society</strong> means a co-operative or a participating
        co-operative, within the meaning of the Co-operatives National Law
        (NSW), in which at least 75 per cent of the shares are held by primary
        producers.
      </p>
    </Modal>
  );
};

export default PrimaryProducerModal;
