import React from 'react';
import {
  isTransactionCompleteData,
  isTransactionCompleteWithCustomerData,
} from 'types/Transaction';
import {
  SkeletonItemRectangle,
  SkeletonLoader,
  Spacer,
  Size,
} from '@rmstransactions/components';
import { window } from 'helpers/window';
import { Link } from 'react-scroll';
import { CarbonOffsetContainer, IconLabelWrapper } from './styled';
import { ReactComponent as VEOSLeaf } from 'assets/images/veos-leaf.svg';
import { BrandIconMobile, Card, CardTitle } from '@snsw-gel/react';

const maxWidthSkeleton = Size.md;
const pagePadding = window.innerWidth <= Size.sm ? 40 : 60;
const windowWidth = window.innerWidth - pagePadding;
const skeletonWidth =
  maxWidthSkeleton < windowWidth
    ? String(maxWidthSkeleton)
    : String(windowWidth);

const ConfirmationPageContent = ({
  data,
  isLoading,
  handleClick,
}: {
  data: any;
  isLoading: boolean;
  handleClick: any;
}) => {
  if (isLoading) {
    return (
      <Spacer mb={3}>
        <SkeletonLoader id='1' width={skeletonWidth} height='144'>
          <SkeletonItemRectangle width={skeletonWidth} height='16' />
          <SkeletonItemRectangle y={24} width={skeletonWidth} height='16' />
          <SkeletonItemRectangle y={64} width='471' height='16' />
          <SkeletonItemRectangle y={96} width='471' height='16' />
          <SkeletonItemRectangle y={128} width='471' height='16' />
        </SkeletonLoader>

        <h3>Keep a receipt for your records</h3>
        <SkeletonLoader id='2' width='273' height='136'>
          <SkeletonItemRectangle width='24' height='24' />
          <SkeletonItemRectangle width='24' height='24' y={56} />
          <SkeletonItemRectangle width='24' height='24' y={112} />
          <SkeletonItemRectangle width='239' height='16' x={33} y={4} />
          <SkeletonItemRectangle width='239' height='16' x={33} y={60} />
          <SkeletonItemRectangle width='239' height='16' x={33} y={116} />
        </SkeletonLoader>
      </Spacer>
    );
  } else if (isTransactionCompleteData(data)) {
    var hasAdminRebate = false;
    var hasDroughtRebate = false;
    var droughtRebates = 0.0;
    var adminRebates = 0.0;
    if (data?.transactionDetails.droughtReliefRebate != null) {
      hasDroughtRebate = true;
      droughtRebates = data?.transactionDetails.droughtReliefRebate;
    }
    if (data?.transactionDetails.adminAmountRebate != null) {
      if (!hasDroughtRebate) hasAdminRebate = true;
      droughtRebates += data?.transactionDetails.adminAmountRebate;
      adminRebates = data?.transactionDetails.adminAmountRebate;
      droughtRebates = 0 - droughtRebates;
      adminRebates = 0 - adminRebates;
    }
    return (
      <>
        <Spacer mb={'1.5rem'}>
          <p style={{ margin: '0 0 0.25rem' }}>
            You have successfully renewed the registration for{' '}
            <strong data-testid='plateNumber'>
              {data?.registration.plateNumber}
            </strong>{' '}
            until{' '}
            <strong>
              <span data-testid='expDate'>{data?.registration.expiryDate}</span>
            </strong>
            .
          </p>

          {data?.registrationScheme === 'Conditional' && hasDroughtRebate && (
            <p style={{ margin: '0 0 0.25rem' }}>
              You{' '}
              <strong>
                saved ${(Math.round(droughtRebates * 100) / 100).toFixed(2)}
              </strong>{' '}
              with the Drought Relief rebate.
            </p>
          )}

          {data?.registrationScheme === 'Conditional' && hasAdminRebate && (
            <p style={{ margin: '0 0 0.25rem' }}>
              You{' '}
              <strong>
                saved ${(Math.round(adminRebates * 100) / 100).toFixed(2)}
              </strong>{' '}
              with the concession rebate.
            </p>
          )}

          {isTransactionCompleteWithCustomerData(data) &&
            data.customer.emailAddress !== null &&
            data.customer.emailAddress !== '' && (
              <p style={{ margin: 0 }}>
                A receipt has been emailed to{' '}
                <strong
                  style={{ wordBreak: 'break-word' }}
                  data-testid='receiptEmailInfo'
                >
                  {data.customer.emailAddress}
                </strong>{' '}
                <Link
                  className='link'
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  activeClass='active'
                  to='emailform'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={800}
                  onClick={() =>
                    handleClick('Button Click', 'Send to another email')
                  }
                >
                  Send to another email
                </Link>
              </p>
            )}

          {data?.registrationScheme === 'Conditional' &&
            !data?.usage.includes('TRLR') && (
              <p style={{ margin: '10 0 0.25rem' }}>
                A CTP insurance tax invoice will be mailed to the address of the
                registered operator by Transport for NSW.
              </p>
            )}
        </Spacer>

        <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
          {data?.transactionDetails.receiptNumber != null && (
            <li style={{ paddingLeft: 0 }}>
              Receipt number:{' '}
              <span data-testid='receiptNumber'>
                {data?.transactionDetails.receiptNumber}
              </span>
            </li>
          )}
          <li style={{ paddingLeft: 0 }}>
            Date of transaction:{' '}
            <span data-testid='transactionDate'>
              {data?.transactionDetails.transactionDate}
            </span>
          </li>

          <li style={{ paddingLeft: 0 }}>
            <b data-testid='totalAmount'>
              Total amount paid:{' '}
              <span>
                $
                {(
                  Math.round(data?.transactionDetails.totalAmount * 100) / 100
                ).toFixed(2)}
              </span>
            </b>
          </li>
        </ul>
        {data?.drnConsent == false && switchToDRNCard}
        {data?.transactionDetails?.carbonOffsetAmount && (
          <CarbonOffsetContainer>
            <IconLabelWrapper>
              <div className='iconWrapper'>
                <VEOSLeaf />
              </div>
              <div className='thank-you-text'>
                <i>
                  <strong>
                    Thank you for contributing to a greener future by offsetting
                    your vehicle's carbon emissions.
                    <br />
                    Visit the{' '}
                    <a
                      href='https://www.energy.nsw.gov.au/vehicle-emissions-offset-scheme'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Office of Energy and Climate Change
                    </a>{' '}
                    to learn more about our programs.
                  </strong>
                </i>
              </div>
            </IconLabelWrapper>
          </CarbonOffsetContainer>
        )}
      </>
    );
  }

  // this should not be happened, must redirect to error page
  return <span data-testid='error'>Error</span>;
};

const switchToDRNCard = (
  <Spacer mt='36px' mb='20px'>
    <Card isClickable ctaText='Switch now'>
      <CardTitle level={4} icon={<BrandIconMobile />}>
        <a
          href={
            process.env.REACT_APP_MY_ACCOUNT_BASE_URL +
            '/manage/notification-preferences'
          }
        >
          Switch to digital registration reminders
        </a>
      </CardTitle>
      <p>
        Receive an email and digital notification on your MyServiceNSW app when
        your rego is due. You'll get access to your Certificate of Registration
        immediately. Opt out anytime.
      </p>
    </Card>
  </Spacer>
);
export default ConfirmationPageContent;
