import { helpers } from '@rmstransactions/components';
import dayjs from 'dayjs';

function getFormattedDate(dateString: string) {
  return helpers.dateFormat(dateString, 'DD MMMM YYYY');
}

function isDateTodayOrInFuture(date = '') {
  return (
    dayjs(date).isAfter(new Date(), 'day') ||
    dayjs(date).isSame(new Date(), 'day')
  );
}

export { getFormattedDate, isDateTodayOrInFuture };
