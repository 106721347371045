import styled from 'styled-components';

export const VehicleRegistrationCardContainer = styled.div`
  //background: red;
  //padding: 30px;

  & .plateNumber {
    font-size: 28px;
    font-weight: bold;
  }
`;
