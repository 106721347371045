import React from 'react';
import {
  LT_VEHICLE,
  LT_COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE,
  MOTOR_BIKE,
  HEAVY_VEHICLE,
  GENERIC_TRAILER,
  TRAILER_WITH_COVER,
  AGRICULTURAL,
  SNOW,
  GOLF,
  GENERIC_VEHICLE,
  BULLDOZER_CONSTRUCTION,
  CRANE_CONSTRUCTION,
  TRAILER_CARAVAN,
} from 'constants/constants';
import {
  BrandIconTruck,
  BrandIconDrivingTransport,
  BrandIconMotorbike,
  BrandIconRoadFreight,
  BrandIconMSCBuses,
} from '@snsw-gel/react';
import { ReactComponent as GenericTrailerIcon } from 'assets/images/Trailer_Icon.svg';
import { ReactComponent as TrailerWithCoverIcon } from 'assets/images/Trailer_Custom_Icon.svg';
import { ReactComponent as TrailerCaravanIcon } from 'assets/images/Caravan_Icon.svg';
import { ReactComponent as AgriculturalIcon } from 'assets/images/Tractor_Icon.svg';
import { ReactComponent as SnowIcon } from 'assets/images/Snow_seasonal_Icon.svg';
import { ReactComponent as GolfIcon } from 'assets/images/Golf_Buggy_Icon.svg';
import { ReactComponent as CraneConstructionIcon } from 'assets/images/Crane_Icon.svg';
import { ReactComponent as BulldozerCostructionIcon } from 'assets/images/Bulldozer_DZP.svg';
import { ReactComponent as GenericVehicleIcon } from 'assets/images/Key_chain.svg';
import * as Styled from './EnhancedVehicleCardDetails.styled';

const VehicleCodeToTypeMap: { [key: string]: string } = {
  CON: LT_VEHICLE,
  COU: LT_VEHICLE,
  FCV: LT_VEHICLE,
  HRS: LT_VEHICLE,
  PVN: LT_VEHICLE,
  PVW: LT_VEHICLE,
  PVF: LT_VEHICLE,
  PVS: LT_VEHICLE,
  SED: LT_VEHICLE,
  WAG: LT_VEHICLE,
  UTE: LT_VEHICLE,
  BUS: LT_COMMERCIAL_VEHICLE,
  SBS: LT_COMMERCIAL_VEHICLE,
  CPA: LT_COMMERCIAL_VEHICLE,
  MH: LT_COMMERCIAL_VEHICLE,
  MWP: LT_COMMERCIAL_VEHICLE,
  RVP: LT_COMMERCIAL_VEHICLE,
  PVA: LT_COMMERCIAL_VEHICLE,
  MPT: LT_COMMERCIAL_VEHICLE,
  VAN: LT_COMMERCIAL_VEHICLE,
  BC: COMMERCIAL_VEHICLE,
  CC: COMMERCIAL_VEHICLE,
  RVT: COMMERCIAL_VEHICLE,
  AC: COMMERCIAL_VEHICLE,
  PAN: COMMERCIAL_VEHICLE,
  RV: COMMERCIAL_VEHICLE,
  PTP: COMMERCIAL_VEHICLE,
  PTT: COMMERCIAL_VEHICLE,
  PUT: COMMERCIAL_VEHICLE,
  CYC: MOTOR_BIKE,
  CYS: MOTOR_BIKE,
  AMC: MOTOR_BIKE,
  VC: HEAVY_VEHICLE,
  PMV: HEAVY_VEHICLE,
  TTF: HEAVY_VEHICLE,
  TT: HEAVY_VEHICLE,
  TNK: HEAVY_VEHICLE,
  TPR: HEAVY_VEHICLE,
  TOW: HEAVY_VEHICLE,
  DLT: HEAVY_VEHICLE,
  APT: GENERIC_TRAILER,
  DTT: GENERIC_TRAILER,
  EPT: GENERIC_TRAILER,
  BTT: GENERIC_TRAILER,
  ELT: GENERIC_TRAILER,
  OPT: GENERIC_TRAILER,
  RPT: GENERIC_TRAILER,
  RWT: GENERIC_TRAILER,
  SMT: GENERIC_TRAILER,
  TPT: GENERIC_TRAILER,
  PTR: GENERIC_TRAILER,
  BOL: GENERIC_TRAILER,
  CMT: GENERIC_TRAILER,
  CCT: GENERIC_TRAILER,
  JKT: GENERIC_TRAILER,
  BCT: GENERIC_TRAILER,
  IPT: GENERIC_TRAILER,
  LOT: GENERIC_TRAILER,
  VCT: GENERIC_TRAILER,
  TTT: GENERIC_TRAILER,
  TFT: GENERIC_TRAILER,
  BLT: GENERIC_TRAILER,
  BXT: GENERIC_TRAILER,
  PCT: TRAILER_WITH_COVER,
  ACT: TRAILER_WITH_COVER,
  TKT: TRAILER_WITH_COVER,
  VNT: TRAILER_WITH_COVER,
  BST: TRAILER_WITH_COVER,
  HPT: TRAILER_WITH_COVER,
  PAT: TRAILER_WITH_COVER,
  AIT: TRAILER_WITH_COVER,
  CVT: TRAILER_CARAVAN,
  AGP: AGRICULTURAL,
  TRP: AGRICULTURAL,
  TRT: AGRICULTURAL,
  ARG: SNOW,
  OSA: SNOW,
  SOSB: SNOW,
  OSG: SNOW,
  OSP: SNOW,
  OSS: SNOW,
  SNO: SNOW,
  OST: SNOW,
  OSV: SNOW,
  GB: GOLF,
  LLP: CRANE_CONSTRUCTION,
  MCP: CRANE_CONSTRUCTION,
  DZP: BULLDOZER_CONSTRUCTION,
  ELP: GENERIC_VEHICLE,
  FFP: GENERIC_VEHICLE,
  HEP: GENERIC_VEHICLE,
  INP: GENERIC_VEHICLE,
  REP: GENERIC_VEHICLE,
  TWP: GENERIC_VEHICLE,
  ATV: GENERIC_VEHICLE,
  LWN: GENERIC_VEHICLE,
  BSP: GENERIC_VEHICLE,
  FLP: GENERIC_VEHICLE,
  INV: GENERIC_VEHICLE,
  TWC: GENERIC_VEHICLE,
  BCP: GENERIC_VEHICLE,
  CMP: GENERIC_VEHICLE,
  DTP: GENERIC_VEHICLE,
};

const getVehicleType = (bodyShapeCode: string): string => {
  const vehicleType = VehicleCodeToTypeMap[bodyShapeCode];
  if (vehicleType !== undefined) {
    return vehicleType;
  } else {
    return 'Default';
  }
};

export const getVehicleIcon = (bodyShapeCode: string): JSX.Element => {
  const vehicleType = getVehicleType(bodyShapeCode);

  switch (vehicleType) {
    case LT_VEHICLE:
      return (
        <BrandIconDrivingTransport
          data-testid={LT_VEHICLE}
          size={Styled.notificationIconSize}
        />
      );
    case LT_COMMERCIAL_VEHICLE:
      return (
        <BrandIconMSCBuses
          data-testid={LT_COMMERCIAL_VEHICLE}
          size={Styled.notificationIconSize}
        />
      );
    case COMMERCIAL_VEHICLE:
      return (
        <BrandIconRoadFreight
          data-testid={COMMERCIAL_VEHICLE}
          size={Styled.notificationIconSize}
        />
      );
    case MOTOR_BIKE:
      return (
        <BrandIconMotorbike
          data-testid={MOTOR_BIKE}
          size={Styled.notificationIconSize}
        />
      );
    case HEAVY_VEHICLE:
      return (
        <BrandIconTruck
          data-testid={HEAVY_VEHICLE}
          size={Styled.notificationIconSize}
        />
      );
    case GENERIC_TRAILER:
      return <GenericTrailerIcon data-testid={GENERIC_TRAILER} />;
    case TRAILER_WITH_COVER:
      return <TrailerWithCoverIcon data-testid={TRAILER_WITH_COVER} />;
    case TRAILER_CARAVAN:
      return <TrailerCaravanIcon data-testid={TRAILER_CARAVAN} />;
    case AGRICULTURAL:
      return <AgriculturalIcon data-testid={AGRICULTURAL} />;
    case SNOW:
      return <SnowIcon data-testid={SNOW} />;
    case GOLF:
      return <GolfIcon data-testid={GOLF} />;
    case CRANE_CONSTRUCTION:
      return <CraneConstructionIcon data-testid={CRANE_CONSTRUCTION} />;
    case BULLDOZER_CONSTRUCTION:
      return <BulldozerCostructionIcon data-testid={BULLDOZER_CONSTRUCTION} />;
    case GENERIC_VEHICLE:
      return <GenericVehicleIcon data-testid={GENERIC_VEHICLE} />;
    default:
      return <GenericVehicleIcon data-testid={GENERIC_VEHICLE} />;
  }
};
