import { AppState } from './globalState';

export const reducer = (state: AppState, action: any) => {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        ...action.payload,
        loading: 'IN_PROGRESS',
      };
    case 'external_call':
      return {
        ...state,
        ...action.payload,
        loading: 'EXTERNAL_CALL_IN_PROGRESS',
      };
    case 'set_userId':
      return {
        ...state,
        userId: action.payload,
      };
    case 'set_registrationDetails':
      return {
        ...state,
        registrationDetails: action.payload,
      };
    case 'end':
      return {
        ...state,
        ...action.payload,
        loading: 'COMPLETED',
      };
    case 'set_userDetails':
      return {
        ...state,
        userDetails: action.payload,
        userId: action.payload?.myAccountId,
      };
    case 'set_reviewDetailsInput':
      return {
        ...state,
        reviewDetailsInput: action.payload,
      };
    case 'set_multipleVehicleList':
      return {
        ...state,
        multipleVehicleList: action.payload,
      };
    case 'set_validateData':
      return {
        ...state,
        validateData: action.payload,
      };
    case 'set_selectedTerm':
      return {
        ...state,
        selectedTerm: action.payload,
      };
    case 'set_EligibilityData':
      return {
        ...state,
        eligibilityData: action.payload,
      };
    case 'set_selectedCTPPolicy':
      return {
        ...state,
        selectedCTPPolicy: action.payload,
      };
    case 'set_usageType':
      return {
        ...state,
        usageType: action.payload,
      };
    case 'set_eligibleForTollRelief':
      return {
        ...state,
        eligibleForTollRelief: action.payload,
      };
    case 'set_pensionerDetails':
      return {
        ...state,
        pensionerDetails: action.payload,
      };
    case 'set_veosQueryParamValue':
      return {
        ...state,
        veosQueryParamValue: action.payload,
      };
    case 'set_plateType':
      return {
        ...state,
        plateType: action.payload,
      };
    default:
      return { ...state };
  }
};
