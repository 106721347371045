import { IConditions } from './Conditions';

interface IRegistration {
  expiryDate: string;
  plateNumber: string;
}

interface IRegistrationSummary extends IRegistration {
  description: string;
  vinNumber: number;
  conditions: IConditions;
  registrationTermMonths?: number;
}

interface IMapping {
  code: string;
  description: string;
}

interface IEligibilityFlags {
  ctpRequired: boolean;
  validCTPTerms: number;
  validSafetyCheckAvailableTerms: number;
  validSafetyCheckNotRequiredTerms: number;
  renewalTerms: IRenewalTerm[];
  eligibleTerms: number;
}

interface IRenewalTerm {
  term: string;
  termInMonths: number;
  ctpAvailable: boolean;
  safetyCheckAvailable: string;
  eligibleTerm: boolean;
  ctpPolicies: ICTPPolicy[];
}

interface ICTPPolicy {
  policyID: IPolicyID;
  policyNumber: string;
  checkDigit: string;
  validUntilDate: string;
  policyTerm: string;
  insurer: IInsurer;
}

interface IPolicyID {
  id: string;
  sourceSystem: string;
}

interface IInsurer {
  insurerCode: string;
  insurerName: string;
}

interface IEligibilityFlagsResult {
  term: string;
  ctpFlag: string;
  safetyInspectionFlag: string;
  hvInspectionFlag: string;
  computedSafetyCheckFlag: string;
  eligible: boolean;
}

function isRegistrationData(data: any): data is IRegistration {
  return (
    !!data &&
    typeof data.expiryDate === 'string' &&
    typeof data.plateNumber === 'string'
  );
}

export type {
  IRegistration,
  IRegistrationSummary,
  IMapping,
  IEligibilityFlags,
  IRenewalTerm,
  ICTPPolicy,
  IEligibilityFlagsResult,
};
export { isRegistrationData };
