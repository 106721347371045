import { helpers } from '@rmstransactions/components';
import { RenewRegoSession } from '../session/RenewRegoSession';
import {
  usageTypeCodeList,
  bodyShapeCodeList,
  SCHEME_FULL,
} from '../constants/constants';
import { Config } from '../config/env';
const { nationalAnnualPassUI } = Config;

export const getIsNationalPass = (confirmationData: any) => {
  var usageTypeCode = '';
  var bodyShapeCode = '';
  const reviewInputDetails = RenewRegoSession.getReviewInputDetails()!;

  if (reviewInputDetails !== null) {
    const reviewDetailsInput = JSON.parse(reviewInputDetails);
    let registration = reviewDetailsInput.registration;
    let eligibility = reviewDetailsInput.eligibility;
    if (
      helpers.isNotEmptyObject(registration.registrationUsage) &&
      helpers.isNotEmptyObject(registration.registrationUsage.usageType) &&
      helpers.isNotEmptyValue(registration?.registrationUsage?.usageType?.code)
    ) {
      usageTypeCode = registration?.registrationUsage?.usageType?.code;
    }
    if (
      helpers.isNotEmptyObject(registration?.registeredVehicle) &&
      helpers.isNotEmptyObject(registration?.registeredVehicle?.vehicleModel) &&
      helpers.isNotEmptyValue(
        registration?.registeredVehicle?.vehicleModel?.bodyShape
      ) &&
      helpers.isNotEmptyValue(
        registration?.registeredVehicle?.vehicleModel?.bodyShape?.code
      )
    ) {
      bodyShapeCode =
        registration?.registeredVehicle?.vehicleModel?.bodyShape?.code;
    }
    return isEligibleForNationalPass(
      eligibility?.registrationEligibilityInfo?.registrationScheme,
      usageTypeCode,
      bodyShapeCode
    );
  } else {
    return isEligibleForNationalPass(
      confirmationData?.registrationScheme,
      extractUsageTypeCode(confirmationData?.usage),
      confirmationData?.bodyShape?.code
    );
  }
};

const isEligibleForNationalPass = (
  registrationScheme: any,
  usageTypeCode: any,
  bodyShapeCode: any
) => {
  return (
    registrationScheme === SCHEME_FULL &&
    usageTypeCodeList.indexOf(usageTypeCode) >= 0 &&
    bodyShapeCodeList.indexOf(bodyShapeCode) >= 0
  );
};

const extractUsageTypeCode = (usage: string | null | undefined) => {
  return usage?.substring(usage?.indexOf('(') + 1, usage?.length - 1);
};

export const getNationalPassURL = () => {
  return nationalAnnualPassUI;
};
