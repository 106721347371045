import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TechnicalError from './components/TechnicalError';
import BusinessError from './components/BusinessError';
import ConfirmationBusinessError from './components/ConfirmationBusinessError';
import FullPageError from './components/FullPageError';
import TimeoutPage from '../TimeoutPage/TimeoutPage';
import { LoggedOutErrorPage } from '@rmstransactions/components';
import { errorBusinessCodePath } from 'constants/paths';

const ErrorPage = () => {
  return (
    <>
      <TimeoutPage />
      <Switch>
        <Route path={errorBusinessCodePath}>
          <BusinessError />
        </Route>
        <Route path={`/error/business`}>
          <BusinessError />
        </Route>
        <Route path={`/error/confirmation-business`}>
          <ConfirmationBusinessError />
        </Route>
        <Route path={`/error/fullpage`}>
          <FullPageError />
        </Route>
        <Route path={`/error/logged-out`}>
          <LoggedOutErrorPage />
        </Route>
        <Route
          path={`/error`}
          render={(props) => <TechnicalError {...props} />}
        ></Route>
      </Switch>
    </>
  );
};

export default ErrorPage;
