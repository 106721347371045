import { Spacer, Table } from '@rmstransactions/components';
import { IconExternalLink } from '@snsw-gel/react';
import React from 'react';
import { PENSIONER_CONCESSION_URL } from '../../../../constants/constants';

const UsageTable = (props: any) => {
  const {
    allowedRegistrationUsage,
    currentUsage,
  }: { allowedRegistrationUsage: any; currentUsage: any } = props;
  const isUsageAllowed = (usageCode: any) => {
    return (
      allowedRegistrationUsage?.filter(
        (usage: { code: any }) => usage?.code === usageCode
      )?.length > 0 || currentUsage?.code === usageCode
    );
  };
  const getUsageDescription = (usageCode: any) => {
    return allowedRegistrationUsage?.filter(
      (usage: { code: any }) => usage?.code === usageCode
    )[0]?.description;
  };
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <th>Usage</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {isUsageAllowed('BKSV') && (
            <tr>
              <td>
                <strong>
                  {getUsageDescription('BKSV') || currentUsage?.description}{' '}
                  (BKSV)
                </strong>
              </td>
              <td>A ride share vehicle for hire.</td>
            </tr>
          )}
          {isUsageAllowed('BUSG') && (
            <tr>
              <td>
                <strong>
                  {getUsageDescription('BUSG') || currentUsage?.description}{' '}
                  (BUSG)
                </strong>
              </td>
              <td>
                A usage applicable to vehicles used by organisations unless
                specified otherwise. Also applicable to individuals if the
                vehicle is not used for private purposes (excluding pensioners).
              </td>
            </tr>
          )}
          {(isUsageAllowed('PNSR') || isUsageAllowed('PNIC')) && (
            <tr>
              <td>
                <strong>Pensioner Concession</strong>
              </td>
              <td>
                <>
                  <p>
                    A ‘Pensioner Concession (PNSR)’ usage is available to
                    eligible Centrelink pensioner and Veteran Card holders.
                  </p>
                  <p>
                    A ‘Pensioner Concession (PNIC)’ usage is available to
                    eligible Veteran Card holders.
                  </p>
                  <p>
                    Eligible pensioners receive a{' '}
                    <a
                      href={PENSIONER_CONCESSION_URL}
                      className='externalLink'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      concession on one vehicle registration renewal.{' '}
                      <IconExternalLink />
                    </a>
                  </p>
                </>
              </td>
            </tr>
          )}
          {isUsageAllowed('PPG') && (
            <tr>
              <td>
                <strong>
                  {getUsageDescription('PPG') || currentUsage?.description}{' '}
                  (PPG)
                </strong>
              </td>
              <td>
                A usage available to a rural co-operative society or primary
                producer but not used or let for hire.
              </td>
            </tr>
          )}
          {isUsageAllowed('PRIV') && (
            <tr>
              <td>
                <strong>
                  {getUsageDescription('PRIV') || currentUsage?.description}{' '}
                  (PRIV)
                </strong>
              </td>
              <td>
                A usage primarily for vehicles used for social, pleasure or
                domestic purposes.
              </td>
            </tr>
          )}
          {isUsageAllowed('RENV') && (
            <tr>
              <td>
                <strong>
                  {getUsageDescription('RENV') || currentUsage?.description}{' '}
                  (RENV)
                </strong>
              </td>
              <td>
                A usage applicable to a vehicle let out for hire without a
                driver (excluding plant vehicles).
              </td>
            </tr>
          )}
          {isUsageAllowed('CHAR') && (
            <tr>
              <td>
                <strong>
                  {getUsageDescription('CHAR') || currentUsage?.description}{' '}
                  (CHAR)
                </strong>
              </td>
              <td>
                A usage for religious, charitable or benevolent organisations.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Table>
  );
};

const UsageToolTipModal = (props: any) => {
  const {
    allowedRegistrationUsage,
    currentUsage,
  }: { allowedRegistrationUsage: any; currentUsage: any } = props;

  return (
    <>
      <span>
        ‘Usage' is a way to describe what the vehicle is most often used for.
        Changing your usage type may affect the total cost of your registation
        renewal.
      </span>
      <p style={{ marginTop: '24px', marginBottom: '8px' }}>
        <strong>Which usage can I choose?</strong>
      </p>
      <span>
        This vehicle is eligible to apply the following usage types online.
      </span>
      <Spacer mt={34} />
      <UsageTable
        allowedRegistrationUsage={allowedRegistrationUsage}
        currentUsage={currentUsage}
      ></UsageTable>
    </>
  );
};

export default UsageToolTipModal;
