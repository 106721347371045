import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';
import { Size } from '@rmstransactions/components/Styled';

export const UsageListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 4px;
  .field-item {
    margin-top: 0px;
  }
  @media only screen and (min-width: ${Size.sm + 1}px) {
    .select-item {
      width: 374px;
    }
  }
  @media only screen and (max-width: ${Size.sm}px) {
    .select-item {
      width: 100%;
    }
    #FormSelectIDRequired {
      max-width: 100%;
    }
  }

  .hideOnSmallerDevice {
    @media only screen and (max-width: ${Size.sm}px) {
      display: none;
    }
  }
  .cancelButton {
    @media only screen and (min-width: ${Size.sm + 1}px) {
      margin-left: 17px;
      margin-top: 20px;
    }
  }
`;

export const PipeContainer = styled.span`
   {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
export const Heading = styled.span`
  @media only screen and (max-width: ${Size.sm}px) {
    .keepLinkRight {
      float: right;
    }
  }

  @media only screen and (min-width: ${Size.sm + 1}px) {
    .hideOnLargerDevice {
      display: none;
    }
  }
`;

export const ChangeUsageConfirmeError = styled.span`
  color: #b81237;
  padding-left: 0.8rem;
`;

export const ChangeUsageNotesHeading = styled.p`
  margin-block-end: 0rem;
`;

export const ChangeUsageNotesContainer = styled.ul`
  margin-block-start: 0.5rem;
`;

export const UsageDisplay = styled.div`
  &:focus {
    outline: 2px solid ${tokens.colors.brand.snswSecondaryBlue};
    outline-offset: 2px;
    border-radius: 4px;
  }
`;
