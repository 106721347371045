import { debugLogger } from '../../services/LoggerService';
import {helpers, UserSession} from '@rmstransactions/components';
import { Config } from '../../config/env';

const { sfRenewRegoUrl, sfAutoRedirectRenewRegoUrl } = Config;

export const doSfRedirect = (
  plateNumber: string,
  registrationID: string,
  registrationScheme: string,
  searchedValue: string = ''
) => {
  let redirectUrl = `${sfRenewRegoUrl}?billingNumber=${helpers.isNotEmptyValue(searchedValue) ? searchedValue : plateNumber}`;

  if (UserSession.isLoginUser()) {
    redirectUrl = `${sfAutoRedirectRenewRegoUrl}/${plateNumber}/${registrationID}/${registrationScheme}`;
  }

  debugLogger('Redirecting to: ', redirectUrl);
  window.location.href = redirectUrl;
};
