import styled from 'styled-components';

export const RemainderWrapper = styled.div`
  display: flex;
  color: rgb(36, 41, 52);
  background-color: white;
  box-shadow: rgb(0 0 0 / 7%) 0px 2px 8px 0px;
  border-radius: 4px;
  padding: 2rem 1.5rem;
  margin-bottom: 3rem;
  position: relative;

  svg {
    margin-top: 2px;
  }
`;

export const Content = styled.div``;
