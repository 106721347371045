import styled from 'styled-components';
import { Size } from '@rmstransactions/components/Styled';
import { ButtonGroup } from '../../styled';


export const IconLabelWrapper = styled.div`
    display: flex;
    margin-bottom: 1rem;

    .iconWrapper,
    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
    }

    p,
    a {
        margin: 0;
    }
`;

export const RowButtonGroup = styled(ButtonGroup)`
    .buttons {
        margin-right: 1.5rem;
    }
`

export const RemainderWrapper = styled.div`
  display: flex;
  color: rgb(36, 41, 52);
  background-color: white;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 8px 0px;
  border-radius: 0.25rem;
  padding: 2rem 1.5rem;
  margin-bottom: 3rem;
  position: relative;

  svg {
    margin-top: 0.125rem;
  }
  .iCalButton {
    margin-bottom: 0.313rem;
  }

  @media only screen and (min-width: ${Size.md + 1}px) {
    .iCalButton {
      min-width: 0;
    }
  }

  @media only screen and (max-width: ${Size.md}px) {
    .iCalButton {
      width: 15%;
    }
  }
`;
