import React, {useEffect} from 'react';
import ErrorLayout from './ErrorLayout';
import { useLocation } from 'react-router-dom';
import { DisableTelOnTabletDesktop } from '@rmstransactions/components';
import styled from 'styled-components';
import TimeoutPage from '../../TimeoutPage/TimeoutPage';
import {getAccessibleMessage} from '../ErrorDetails.helpers';
import {ErrorMessageContainer} from "../../../styled";

const ErrorMessageLayout = styled.span`
  .top-spacing {
    padding-top: 6px;
  }
`;

const FullPageError = () => {
  const location = useLocation();
  // @ts-ignore
  const errorMessageObject = location.state?.errorMessageObject;
  // @ts-ignore
  const header = location.state?.header;

  const displayMessage = getAccessibleMessage(
    errorMessageObject?.messageDescription
      ? errorMessageObject?.messageDescription
      : errorMessageObject?.message ? errorMessageObject?.message : ""
  );
  const showRenewAnotherVehicle = true;
  window.scrollTo(0, 0);

    useEffect(() => {
        document.title = 'Service NSW - Renew registration - Error';
    }, []);

  return (
    <>
      <TimeoutPage />
      <ErrorLayout
        heading={header ? header : 'Registration renewal incomplete'}
        showRenewAnotherVehicle={showRenewAnotherVehicle}
        messageHeadline={errorMessageObject?.messageTitle}
      >
        {displayMessage ? (
          <ErrorMessageLayout>
            <ErrorMessageContainer dangerouslySetInnerHTML={{ __html: displayMessage }} />
          </ErrorMessageLayout>
        ) : (
          <ErrorMessageContainer>
            We need to check a few details before we proceed. Please visit a{' '}
            <a href='https://www.service.nsw.gov.au/service-centre'>
              service centre
            </a>{' '}
            or call us on <DisableTelOnTabletDesktop phoneNumber='13 77 88' />.
          </ErrorMessageContainer>
        )}
      </ErrorLayout>
    </>
  );
};

export default FullPageError;
