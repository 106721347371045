import styled from 'styled-components';
import { Size } from '@rmstransactions/components/Styled';

export const RegistrationConditionsContainer = styled.div`
  border-width: 2px 0;
  border-color: rgb(222, 227, 229);
  border-style: solid;
  padding: 32px 0;
  margin: 0 0 32px;

  & .operatingConditionsPanel {
    margin-top: 32px;
    border-top: 2px solid rgb(222, 227, 229);
  }

  & .operatingConditions {
    padding-left: 0;

    @media only screen and (min-width: ${Size.md}px) {
      float: right;
      padding-top: 3px;
    }
  }

  & .operatingConditions.alignForlongCTPLabels {
    @media only screen and (min-width: ${Size.md}px) and (max-width: ${Size.lg}px) {
      line-height: 0px;
      padding-top: 0px;
    }
  }
`;

export const AreaContainer = styled.span`
  .wordWrapForDeviceLength {
    @media only screen and (max-width: 767px) {
      &::before {
        content: '\\A';
        white-space: pre;
      }
    }
    @media only screen and (max-width: 767px) {
      &::after {
        content: '\\A';
        white-space: pre;
      }
    }
  }
  .wordWrapForContentLength {
    @media only screen and (max-width: 1176px) {
      &::before {
        content: '\\A';
        white-space: pre;
      }
    }
    @media only screen and (max-width: 767px) {
      &::after {
        content: '\\A';
        white-space: pre;
      }
    }
  }
`;

export const OperatingConditionsTable = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    border-bottom: 4px solid rgb(222, 227, 229);
  }

  td,
  th {
    padding: 8px;
    text-align: left;
  }

  th:first-child,
  td:first-child {
    font-weight: bold;
    border-right: 2px solid rgb(222, 227, 229);
  }

  tbody > tr:nth-child(odd) {
    background: rgb(244, 247, 249);
  }
`;

export const CTPQuestion = styled.strong`
  font-size: 18px;
  font-weight: bold;
`;

export const CTPQuestionAnswer = styled.div`
    margin-top: 4px;
    
    .externalLink {
      background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg height='12' width='12' fill='%232e5299' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0v12h12V0H0zm10.9 10.9H1.1V1.1h9.8v9.8z'/%3E%3Cpath d='M4.4 2.7v1.1h3L2.3 8.9l.8.8 5.1-5.1v3h1.1V2.7H4.4z'/%3E%3C/svg%3E");
      background-repeat:no-repeat;
      background-position: right
      padding-right: 16px;
    }
`;

export const CTPQuestionAnswerExtraInfo = styled.div`
  margin-top: 24px;
`;
export const AreaName = styled.span`
  font-weight: 450;
`;

export const CTPDescriptionTable = styled.div`
  table {
    margin: 24px 0;
    border-collapse: collapse;
    width: 100%;
  }

  thead > tr {
    background: rgb(222, 227, 229);
    border-radius: 0px;
    height: 57px;
    border: 1px solid rgb(222, 227, 229);
    font-weight: 300;
  }

  td,
  th {
    padding: 12px;
    text-align: left;
    font-weight: 300;
  }

  th {
    vertical-align: middle;
    font-weight: 500;
  }
  td {
    vertical-align: top;
  }
  tbody > tr {
    background: none;
    border-radius: 0px;
    border: 1px solid rgb(222, 227, 229);
  }

  tr > td:nth-child(odd),
  th:nth-child(odd) {
    width: 30%;
  }
`;

export const RegistrationConditionsMoreInfoContainer = styled.div``;

export const MoreInfoOnCTPArea = styled.span`
  top: 2px;
  position: relative;
  padding-left: 4px;

  div[id='ctpArea-desc'] {
    overflow: auto;
  }

  @media only screen and (max-width: 768px) {
    div[role='dialog'] {
      left: 10%;
      width: 90%;
    }
  }
`;

export const SpecialCondition = styled.li`
  padding-bottom: 12px;
`;
