import { matchPath } from 'react-router-dom';
import {
  analyticsServiceV2,
  AnalyticsSessionManager,
} from '@rmstransactions/components';
import {
  googleAnalyticsDefaultInfo,
  googleAnalyticsTransactionInfo,
  viewReceiptTransactionInfo,
  confirmationUuidRedirectTransactionInfo,
  myAccountWithPlateTypeTransactionInfo,
  myAccountWithoutPlateTypeTransactionInfo,
  myAccountConditionalRegoInfo,
  StaticPathsGoogleAnalyticsTransactionInfo,
  errorBusinessCodeTransactionInfo,
} from 'constants/analytics';
import {
  confirmationRedirectPath,
  errorBusinessCodePath,
  myAccountRedirectConditionalRegoPath,
  myAccountRedirectWithPlateTypePath,
  myAccountRedirectWithoutPlateTypePath,
  viewReceiptUuidPath,
} from 'constants/paths';

const { getSessionID, getUserLoginType, getTransactionStartTime } =
  AnalyticsSessionManager;

const pushAnalyticsNewPage = (): void => {
  const hasRequiredSessionFields =
    getSessionID() && getUserLoginType() && getTransactionStartTime();
  if (!hasRequiredSessionFields) return;

  // ignore UUID redirect page
  const isUUIDConfirmationURL = matchPath(window.location.pathname, {
    path: confirmationRedirectPath,
  });
  if (isUUIDConfirmationURL) return;

  const transactionInfo = getNewPageTransactionInfo();
  const overridePageLocation = getOverridePageLocation();
  analyticsServiceV2.pushNewPageVisited({
    pageName: document?.title,
    transactionInfo: transactionInfo,
    overridePageLocation,
  });
};

export default pushAnalyticsNewPage;

export const getNewPageTransactionInfo = (): NewPageTransactionInfo => {
  const { pathname } = window.location;

  // URL param paths
  const isViewReceiptURL = matchPath(pathname, {
    path: viewReceiptUuidPath,
  });
  if (isViewReceiptURL) return viewReceiptTransactionInfo;

  const isFromMyAccountWithPlateType = matchPath(pathname, {
    path: myAccountRedirectWithPlateTypePath,
  });
  if (isFromMyAccountWithPlateType)
    return myAccountWithPlateTypeTransactionInfo;

  const isFromMyAccountWithoutPlateType = matchPath(pathname, {
    path: myAccountRedirectWithoutPlateTypePath,
  });
  if (isFromMyAccountWithoutPlateType)
    return myAccountWithoutPlateTypeTransactionInfo;

  const isFromMyAccountConditionalRego = matchPath(pathname, {
    path: myAccountRedirectConditionalRegoPath,
  });
  if (isFromMyAccountConditionalRego) return myAccountConditionalRegoInfo;

  const isErrorBusinessCodeURL = matchPath(pathname, {
    path: errorBusinessCodePath,
  });
  if (isErrorBusinessCodeURL) return errorBusinessCodeTransactionInfo;

  // static paths
  if (pathname in googleAnalyticsTransactionInfo) {
    return googleAnalyticsTransactionInfo[
      pathname as StaticPathsGoogleAnalyticsTransactionInfo
    ];
  }
  return googleAnalyticsDefaultInfo;
};

interface NewPageTransactionInfo {
  transaction_name: string;
  transaction_step: string;
  transaction_type: string;
  transaction_sub_type: string;
}

/**
 *  we don't want to send the "uuid" value to Google Analytics
 *  therefore we will replace it manually
 */
export const getOverridePageLocation = (): string | undefined => {
  const { origin, pathname } = window.location;

  const isViewReceiptURL = matchPath(pathname, {
    path: viewReceiptUuidPath,
  });
  if (isViewReceiptURL) return `${origin}/view-receipt/uuid`;

  const isFromMyAccountWithPlateType = matchPath(pathname, {
    path: myAccountRedirectWithPlateTypePath,
  });
  if (isFromMyAccountWithPlateType)
    return `${origin}/renew-rego-orchestration/plateNumber/registrationID/registrationScheme/plateType`;

  const isFromMyAccountWithoutPlateType = matchPath(pathname, {
    path: myAccountRedirectWithoutPlateTypePath,
  });
  if (isFromMyAccountWithoutPlateType)
    return `${origin}/renew-rego-orchestration/plateNumber/registrationID/registrationScheme`;

  const isFromMyAccountConditionalRego = matchPath(pathname, {
    path: myAccountRedirectConditionalRegoPath,
  });
  if (isFromMyAccountConditionalRego)
    return `${origin}/conditional-registration-summary/plateNumber/registrationID/registrationScheme`;

  return undefined;
};
