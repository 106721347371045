import { RenewRegoSession } from '../../session/RenewRegoSession';
import { anyTermHaveAValidCTP } from '../../calculator/eligibilityFlagsResult';
import {
  MASK_BILLING_NUMBER_PREFIX,
  MISSING_BILLING_NUMBER,
  PENSIONER_USAGE_TYPE_CODES,
  SCHEME_FULL,
  TRAILER_CODES,
} from '../../constants/constants';
import { HttpRequestHandler, KioskService } from '@rmstransactions/components';

export const redirectToOrchestrationPage = () => {
  const reviewDetailsFromSession = JSON.parse(
    RenewRegoSession.getReviewInputDetails()!
  );
  const plateNumber =
    reviewDetailsFromSession?.registration?.plate?.plateNumber;
  const plateType = reviewDetailsFromSession?.registration?.plate?.plateType;
  const registrationID =
    reviewDetailsFromSession?.eligibility?.registrationEligibilityInfo
      ?.registrationID;
  const registrationScheme =
    reviewDetailsFromSession?.eligibility?.registrationEligibilityInfo
      ?.registrationScheme;
  RenewRegoSession.clearReviewInputDetails();
  RenewRegoSession.clearValidateData();
  const currentUrl = window.location.href;
  const plateTypeSuffix = registrationID?.startsWith(MASK_BILLING_NUMBER_PREFIX) || registrationID?.startsWith(MISSING_BILLING_NUMBER) ? `/${plateType}` : '';
  const newurl = currentUrl.replace(
    '/review-details',
    `/renew-rego-orchestration/${plateNumber}/${registrationID}/${registrationScheme}${plateTypeSuffix}`
  );
  window.location.href = HttpRequestHandler.appendApiKeyAsParam(newurl);
};

export const isCTPPolicyUpdatedInDRIVES = (
  eligibilityValidRenewalTerms: any,
  validateResponseValidRenewalTerms: any
) => {
  const anyTermHaveAValidCTPInEligibilityResponse = anyTermHaveAValidCTP(
    eligibilityValidRenewalTerms
  );
  const anyTermHaveAValidCTPInValidateResponse = anyTermHaveAValidCTP(
    validateResponseValidRenewalTerms
  );
  return (
    !anyTermHaveAValidCTPInEligibilityResponse &&
    anyTermHaveAValidCTPInValidateResponse
  );
};

export const veosEligibilityCheck = (
  registrationScheme: any,
  gvm: any,
  vehicleTypeCode: any,
  usageCode: any
) => {
  return (
    registrationScheme === SCHEME_FULL &&
    (gvm === null || parseInt(gvm) <= 4500) &&
    !TRAILER_CODES.includes(vehicleTypeCode) &&
    !PENSIONER_USAGE_TYPE_CODES.includes(usageCode)
  );
};

export const registrationPriceDetail = (
  transactionDetails: any,
  registrationTerm: any
) => {
  if (transactionDetails?.registrationScheme === SCHEME_FULL) {
    return transactionDetails?.registrationPriceDetails?.filter(
      (price: any) => price?.term === registrationTerm
    )[0];
  } else {
    return (
      transactionDetails?.registrationPriceDetails &&
      transactionDetails.registrationPriceDetails[0]
    );
  }
};

export const canDisplayVEOSPanel = (
  veosQueryParamValue: any,
  veosFeatureEnabledServiceCenterIDS: any,
  veosFeatureEnabled: any,
  isVeosEligible: any
) => {
  return (
    getVEOSFeatureEnabled(
      veosQueryParamValue,
      veosFeatureEnabledServiceCenterIDS,
      veosFeatureEnabled
    ) && isVeosEligible
  );
};

export const getVEOSFeatureEnabled = (
  veosQueryParamValue: any,
  veosFeatureEnabledServiceCenterIDS: any,
  veosFeatureEnabled: any
) => {
  let requestingServiceCenterId = KioskService.getServiceCenterId();
  if (veosQueryParamValue !== null && veosQueryParamValue === 'true') {
    return true;
  } else if (
    veosFeatureEnabledServiceCenterIDS.length > 0 &&
    veosFeatureEnabledServiceCenterIDS
      .split(',')
      .includes(requestingServiceCenterId) &&
    veosFeatureEnabled === 'Y'
  ) {
    return true;
  } else if (
    veosFeatureEnabledServiceCenterIDS.length === 0 &&
    veosFeatureEnabled === 'Y'
  ) {
    return true;
  } else {
    return false;
  }
};
