//localisation
export const moment = require('moment-business-days');
export const momentLocalization = moment.updateLocale('en-AU', {
  workingWeekdays: [1, 2, 3, 4, 5],
});

export const isBpayAlertMsg = (expiryDate: String) => {
  const currentDate = moment().locale('en-AU').format('YYYY-MM-DD');
  const regoDate = expiryDate;
  const cancellationDate = moment(regoDate)
      .add(3, 'months')
      .format('YYYY-MM-DD');
  const threeBusDays = moment(cancellationDate, 'YYYY-MM-DD')
      .businessSubtract(3)
      .format('YYYY-MM-DD');
  if (
      moment(currentDate).isBetween(threeBusDays, cancellationDate,null,'[]')
  ) {
    return true;
  } else return false;
};

export const isBpayAlertMsgCtp = (validUntilDate: String) => {
  const currentDate = moment().locale('en-AU').format('YYYY-MM-DD');
  const ctpDate = validUntilDate;
  const threeBusDaysCTP = moment(ctpDate, 'YYYY-MM-DD')
      .businessSubtract(3)
      .format('YYYY-MM-DD');
  if (
      moment(currentDate).isBetween(threeBusDaysCTP, ctpDate, null, '[]')
  ) {
    return true;
  } else return false;
};

export const isBpayAlertMsgTill7Days = (validUntilDate: String) => {
  const currentDate = moment().locale('en-AU').format('YYYY-MM-DD');
  const ctpDate = validUntilDate;
  const sevenDaysCTP = moment(ctpDate).subtract(7, 'days').format('YYYY-MM-DD');
  if (
      moment(currentDate).isBetween(sevenDaysCTP, ctpDate,null,'[]')
  ) {
    return true;
  } else return false;
};

export const bpayAlerts = {
  isBpayAlertMsg,
  isBpayAlertMsgCtp,
  isBpayAlertMsgTill7Days,
};
