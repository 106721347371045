import styled from 'styled-components';
import { Size } from '@rmstransactions/components/Styled';

export const ButtonGroup = styled.div`
  .conditionalButton {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: ${Size.md + 1}px) {
    .conditionalButton-continue {
      margin-left: 24px;
    }

    .conditionalButton-back {
      min-width: 0;
    }
  }

  @media only screen and (max-width: ${Size.md}px) {
    .conditionalButton {
      width: 100%;
    }
    .conditionalButton-continue,
    .conditionalButton-back {
      width: 100%;
    }
  }
`;

export const RenewalPeriodContainer = styled.div`
  padding-bottom: 24px;
  .regoPeriod {
    font-size: 27px;
  }
`;

export const Nowrap = styled.span`
  white-space: nowrap;
`;

export const ConditionalDeclarations = styled.div`
  padding-bottom: 24px;
  span[class^='FormError'] {
    padding-bottom: 10px;
  }
  .removeBottomPadding {
    margin-bottom: 0px;
  }
`;

export const ServiceCentre = styled.span`
  .fontColor {
    color: #2e5299;
  }
`;

export const NoBPAYMessageContainer = styled.div`
  margin-bottom: 40px;
  margin-top: 0px;
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  color: rgb(36, 41, 52);
  background-color: white;
  padding: 1.5rem;
  position: relative;
  box-shadow: rgba(19, 36, 69, 0.1) 0px 2px 4px -1px, rgba(19, 36, 69, 0.05) 0px 4px 5px 0px, rgba(19, 36, 69, 0.05) 0px 1px 10px 0px;
  border-radius: 8px 8px 6px 6px;

    @media only screen and (max-width: ${Size.md}px) {
        flex-flow: row wrap;
        margin: 0px 0px 0px 0px
    }

      .iconWrapper, svg{
        width: 36px;
        height: 36px;
        margin-right: 0.5rem;
      }
`;
