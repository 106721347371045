import React, { useState } from 'react';
import { FormCheckbox } from '@snsw-gel/react';
import { Spacer } from '@rmstransactions/components';
import { DOC_ID_PPG_DECLARATION } from 'constants/constants';
import { LinkButton } from 'components/TermsAndConditions/styled';

const PrimaryProducerDeclaration = (props: any) => {
  const {
    setShowModalPPG,
    handleAcceptPPGDeclaration,
    pPGDeclarationValidationError,
  }: {
    setShowModalPPG: any;
    handleAcceptPPGDeclaration: any;
    pPGDeclarationValidationError: any;
  } = props;
  const [isChecked, setCheckedState] = useState(false);

  const handleCheckbox = () => {
    const newValue = !isChecked;
    setCheckedState(!isChecked);
    const currentTime = new Date().getTime().toString();
    handleAcceptPPGDeclaration(newValue, currentTime);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setShowModalPPG(true);
  };

  return (
    <>
      <span>
        <h4>I declare that</h4>
        <ul>
          <li>
            {' '}
            I am eligible for the{' '}
            <LinkButton
              role='button'
              className='link-button'
              data-testid='showPrimaryProducerModal'
              onClick={handleClick}
            >
              {' '}
              primary producer
            </LinkButton>{' '}
            concession{' '}
          </li>
          <li>
            {' '}
            I will notify Transport for NSW within 14 days if my eligibility for
            the concession ceases{' '}
          </li>
          <li>
            I understand the need to provide evidence of my concession
            eligibility when requested. If the evidence is not provided it may
            lead to me paying full vehicle registration charges and tax and the
            cancellation of my eligibility for the concession.{' '}
          </li>
          <li>
            I understand that Transport for NSW can suspend or cancel the
            vehicle registration if the vehicle is not eligible for the
            concession and the correct registration fees, tax and charges have
            not been paid.{' '}
          </li>
        </ul>
      </span>
      <FormCheckbox
        id={DOC_ID_PPG_DECLARATION}
        name='FormCheckbox name'
        label='I declare that I am a primary producer and this vehicle is used for primary producer purposes'
        value='FormCheckbox value'
        errorMessage='Please accept the declaration to continue.'
        hasError={pPGDeclarationValidationError}
        data-testid="pPGDeclarationCheckBox"
        onChange={handleCheckbox}
        checked={isChecked}
      />
      <Spacer mt={24} mb={24}>
        <p>
          Under road transport legislation, it is an offence to attempt to
          register or renew the registration of a vehicle by false statement or
          any misrepresentation or other dishonest means.
        </p>
      </Spacer>
    </>
  );
};

export default PrimaryProducerDeclaration;
