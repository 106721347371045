import React from 'react';
import { Modal } from '@snsw-gel/react';

const TermsVEOSModal = (props: any) => {
  const { closeTAndCs, acceptTAndCs } = props;
  return (
    <Modal
      title='Carbon Offset contribution Terms and Conditions'
      isScrollable={true}
      buttons={[
        { text: 'Accept', id: 'acceptTAndCs', onClick: acceptTAndCs },
        { text: 'Close', id: 'closeTAndCs', onClick: closeTAndCs },
      ]}
    >
      <div>
        <p>
          These Terms and Conditions, together with the Privacy Statement published on the Service NSW website ("Terms"), set out the conditions that apply to your use of the Vehicle Emissions Offset Scheme ("the Scheme").
        </p>
        <p>
          By using the Scheme, you agree to comply with these Terms together with all relevant guidelines, instructions and procedures from Service NSW and NSW Treasury ("Instructions"). Nothing in these Terms limits or qualifies your rights or obligations under any legislation, including legislation relating to motor vehicles, traffic, roads or road transport.
        </p>

        <h3>Changing these Terms</h3>
        <p>
          NSW Treasury and Service NSW may change these Terms or their Instructions from time to time by republishing them online. You agree that by doing this, you have been provided with sufficient notice of the variation. You should read the Terms and Instructions each time before you use the Scheme in case they have changed. If you do not agree to any change, you should not continue to use the Scheme.
        </p>

        <h3>Preconditions</h3>
        <p>
          You acknowledge and agree that you can only make a payment under the Scheme for a vehicle that:
        </p>
        <ol type='I'>
          <li>
            <span lang='EN'>meets all the eligibility requirements for full registration in NSW;</span>
          </li>
          <li>
            <span lang='EN'>does not have a conditional registration;</span>
          </li>
          <li>
            <span lang='EN'>is eligible for registration renewal in NSW;</span>
          </li>
          <li>
            <span lang='EN'>is an eligible light vehicle, in that it; </span>
          </li>
          <ol type='a'>
            <li>
              <span lang='EN'>has a gross vehicle mass of 4.5 tonnes or less;</span>
            </li>
            <li>
              <span lang='EN'>has an engine attached;</span>
            </li>
            <li>
              <span lang='EN'>does not have a trailer attached;</span>
            </li>
            <li>
              <span lang='EN'>is not a trailer, caravan or prime mover; and</span>
            </li>
            <li>
              <span lang='EN'>does not have a registration using a pensioner usage code i.e. PNSR or PNIC.</span>
            </li>
          </ol>
        </ol>

        <h3>Exclusions</h3>
        <p>You acknowledge and understand that:</p>
        <ol type='I'>
          <li>
            <span lang='EN'>
              The Scheme is only available for vehicle registration transactions conducted using the Service NSW website (including Service NSW Centre kiosks);
            </span>
          </li>
          <li>
            <span lang='EN'>
              A payment under the Scheme can only be made at the time of renewal of registration of a vehicle;
            </span>
          </li>
          <li>
            <span lang='EN'>
              A payment made under the Scheme is voluntary but full and final and not refundable or transferrable in any circumstances;
            </span>
          </li>
          <li>
            <span lang='EN'>
              Payments under the Scheme are based on average vehicle emissions in NSW and are not personalised to a specific vehicle;
            </span>
          </li>
          <li>
            <span lang='EN'>
              A payment under the Scheme is voluntary and does not create a binding or ongoing relationship or obligations between you and NSW Treasury; and
            </span>
          </li>
          <li>
            <span lang='EN'>
              A payment under the Scheme will not create an expectation from you or an entitlement for you to receive any carbon credit (including Australian Carbon Credit Unit) from the Clean Energy Regulator.
            </span>
          </li>
        </ol>

        <h3>Discontinuance of Service</h3>
        <p>
          NSW Treasury and Service NSW reserves the right at any time and without notice to discontinue or modify the Scheme in whole or in part, and/or restrict access to it.
        </p>

        <h3>Correct information </h3>
        <p>
          By making a payment under the Scheme, you warrant that:
        </p>
        <ol type='I'>
          <li>
            <span lang='EN'>You are the registered operator of the vehicle; or</span>
          </li>
          <li>
            <span lang='EN'>You have been authorised by the owner of the vehicle to make the payment under the Scheme.</span>
          </li>
        </ol>
        <p>
          Further, by making a payment under the Scheme, you warrant that:
        </p>
        <ol type='I'>
          <li>
            <span lang='EN'>
              All information included with your payment is true, accurate and complete;
            </span>
          </li>
          <li>
            <span lang='EN'>
              You are entitled to make the payment;
            </span>
          </li>
          <li>
            <span lang='EN'>
              To the extent that it includes information about other people, that you are authorised to disclose it to Service NSW.
            </span>
          </li>
        </ol>
        <h3>Liability</h3>
        <p>
          You acknowledge and agree that subject to any responsibilities implied by law and which cannot be excluded, Service NSW and NSW Treasury are not liable to you for any losses, expenses, damages, liabilities and claims whatsoever, whether direct, indirect or consequential, arising out of or in reference to the use of the Scheme or its discontinuance, however caused (including your reliance on any inaccurate or incomplete information or data contained within or displayed by the Scheme) whether in contract, tort (including negligence), statute or otherwise.
        </p>
        <p>
          Service NSW and NSW Treasury’s liability for breach of a condition or warranty implied by law which cannot be excluded is limited to the extent possible to the resupply of the Service.
        </p>

        <h3>Indemnity</h3>
        <p>
          You indemnify Service NSW and NSW Treasury in respect of any liability, loss, damage, cost or expense suffered or incurred by Service NSW or NSW Treasury arising from or in connection with your breach of these Terms.
        </p>

        <h3>Security of Information</h3>
        <p>
          You are responsible for taking reasonable care in how you access the Scheme, including using appropriate firewalls and anti-virus software. No data transmission over the Internet can be guaranteed as totally secure and communications to this Scheme may be intercepted or altered in transit. Although NSW Treasury and Service NSW strive to protect such information, they do not represent or warrant and cannot ensure the security of any information which is transmitted when you use the Scheme. When using the Scheme you transmit and receive information at your own risk and NSW Treasury and Service NSW bear no liability.
        </p>

        <h3>No Warranty</h3>
        <p>
          You acknowledge and agree that:
        </p>
        <ol type='I'>
          <li>
            <span lang='EN'>
              from time to time the Scheme may be unavailable. NSW Treasury and Service NSW make no representation or warranty in relation to the information available on the Scheme, including without limitation, that the Scheme will be free from any delay in operation or transmission, virus, communications failure, internet access difficulties or malfunction in hardware and software.
            </span>
          </li>
          <li>
            <span lang='EN'>
              Service NSW does not endorse any entity or internet site linked to the Scheme or any third party products or services referred to on the Office of Energy and Climate Change site. By linking to a third party website Service NSW is not accepting responsibility for the content of that website or your use of it and makes no warranty as to its suitability. Use of any third party website is subject to the terms of that website.
            </span>
          </li>
        </ol>

        <h3>Age</h3>
        <p>
          You warrant that you are aged 18 or over. If you are under 18, you must use the Scheme only with the involvement of a parent or guardian.
        </p>

        <h3>No Waiver</h3>
        <p>
          Failure by NSW Treasury or Service NSW to enforce any of its rights at any stage does not constitute a waiver of those rights.
        </p>

        <h3>Severability</h3>
        <p>
          If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.
        </p>

        <h3>Jurisdiction</h3>
        <p>
          These Terms and your use of the Scheme are governed by the laws of New South Wales, Australia. You agree to submit to the exclusive jurisdiction of the courts of New South Wales. A reference to any legislation includes all delegated legislation made under it, including regulations, rules and amendments, consolidations, replacements or re-enactments of any of them.
        </p>

        <h3>General</h3>
        <p>
          A reference to an entity, whether statutory or not, which ceases to exist or whose powers or functions are transferred to another body is a reference to the body which replaces it or which substantially succeeds to its powers or functions. Specifying anything in these Terms after the words 'include' or 'for example' or similar expressions does not limit what else is included.
        </p>

        <h3>Disclaimer</h3>
        <p>
          Any enquiries relating to the VEOS should be directed to NSW Treasury. NSW Treasury may have their own protocols, terms and conditions and disclaimers for the VEOS, which you should familiarise yourself with.
        </p>

        <h3>Complaints Handling and Feedback</h3>
        <p>
          Service NSW welcomes all forms of feedback, including complaints on its services. We view effective complaint management as part of our commitment to improving service provisions and recurring problems.
        </p>
        <p>You may leave your feedback or make a complaint by:</p>
        <p>Calling: 13 77 88</p>
        <p>
          Emailing:{' '}
          <a href='mailto:info@service.nsw.gov.au'>info@service.nsw.gov.au</a>
        </p>
        <p>
          Visiting:{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.service.nsw.gov.au/contact-us'
          >
            https://www.service.nsw.gov.au/contact-us
          </a>
        </p>
      </div>
    </Modal>
  );
};

export default TermsVEOSModal;
