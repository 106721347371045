import { Card, CardTitle, BrandIconCommunity } from '@snsw-gel/react';
import React, { useRef } from 'react';
import { helpers } from '@rmstransactions/components';
import { RenewRegoSession } from '../../../../session/RenewRegoSession';
import { getNationalPassURL } from '../../../../services/NationalParkPassService';

const NationalPassCard = (props: any) => {
  const { confirmationData }: { confirmationData: any } = props;
  const nationalPassForm = useRef(null);
  let plateNumber = '';
  let plateType = '';
  const reviewInputDetails = RenewRegoSession.getReviewInputDetails()!;

  if (reviewInputDetails) {
    const reviewDetailsInput = JSON.parse(reviewInputDetails);
    const registration = reviewDetailsInput.registration;
    if (helpers.isNotEmptyObject(registration?.plate)) {
      plateNumber = registration.plate.plateNumber;
      plateType = registration.plate.plateType;
    }
  } else {
    plateNumber = confirmationData?.registration?.plateNumber;
    plateType = confirmationData?.registration?.plateType;
  }
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (nationalPassForm.current) {
      // @ts-ignore
      nationalPassForm.current.submit();
    }
  };

  return (
    <>
      <Card className={'card'} isClickable>
        <CardTitle level={4} icon={<BrandIconCommunity />}>
          <a href='#' onClick={onClick}>National Park Pass discount </a>
        </CardTitle>
        <form
          action={getNationalPassURL()}
          method='POST'
          target='_blank'
          id='nationalPass'
          data-testid='nationalPass'
          ref={nationalPassForm}
        >
          <input type='hidden' name='rego' id='rego' data-testid='rego' value={plateNumber} />
          <input type='hidden' name='carType' id='carType' data-testid='carType' value={plateType} />
          <input type='hidden' name='state' id='state' data-testid='state' value='NSW' />
        </form>
        <p>Eligible customers can save $15 on NSW National Park passes all year round.</p>
      </Card>
    </>
  );
};

export default NationalPassCard;
