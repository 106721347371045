import React, { useEffect, useContext } from 'react';
import {
  Header,
  Quickfeed,
  Spacer,
  Svg,
  AnalyticsService,
} from '@rmstransactions/components';
import {
  Button,
  ContentContainer,
  SectionPage,
  tokens,
  InPageAlert,
  Accordion,
  AccordionItem,
} from '@snsw-gel/react';
import { Col, Row } from '@rmstransactions/components/Styled';
import { QuickfeedStyleOverride } from '@rmstransactions/components/Styled';
import { RemainderWrapper } from './styled';
import TimeoutPage from '../TimeoutPage/TimeoutPage';
import { AppState, store } from 'providers/globalState';
import { TUTD_APPLICATION_NAME } from '../../constants/constants';
import { NationalPass } from '../../components/NationalPass/NationalPass';
import { gotoHelpUsImprove, gotoStartPage } from '../../helpers/commonHelper';

const BPayInformationPage = () => {
  const { globalState } = useContext(store) as {
    globalState: AppState;
  };
  const pageUrl =
    window.location.protocol +
    window.location.hostname +
    window.location.pathname;
  const handleClick = (eventAction: String, eventName: String) => {
    AnalyticsService.ButtonClick(
      eventAction,
      eventName,
      globalState.transactionStartTime
    );
    if (eventName === 'Renew another registration') {
      gotoStartPage();
    }
    if (eventName === 'Help us improve') {
      gotoHelpUsImprove();
    }
  };
  useEffect(() => {
    document.title = 'Service NSW - Renew registration - BPAY info';
  });

  return (
    <>
      <TimeoutPage />
      <Header
        data-testid='header'
        title='BPAY: When will my vehicle be renewed?'
      />
      <ContentContainer>
        <SectionPage>
          <Row>
            <Col lg={8}>
              <section>
                <InPageAlert title="Processing time" variant="info">
                  Your vehicle registration will be renewed once full payment
                  has been received. BPAY payments can take up to{' '}
                  <b>3 business days</b> to process.
                </InPageAlert>
              </section>
              <p style={{ margin: '30 0 0.25rem' }}>
                A copy of the BPAY payment details has been emailed to the
                address provided during payment.
              </p>
              <p style={{ margin: '30 0 0.25rem' }}>
                Any changes to the vehicle usage will only apply when Transport
                for NSW receives the payment in full.
              </p>
              <p style={{ margin: '50 0 0.25rem' }}></p>
              <Accordion id='acc1'>
                <AccordionItem title='Why can a BPAY payment fail?'>
                  <p>
                    There are a number of reasons BPAY payments might fail while
                    attempting a vehicle registration renewal. The most common
                    reasons are:
                  </p>
                  <ul>
                    <li style={{ marginBottom: '10px' }}>
                      the Compulsory Third Party (CTP) insurance expired before
                      the payment was processed
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                      the payment amount did not match the registration fees due
                    </li>
                    <li>the registration has been cancelled</li>
                  </ul>
                </AccordionItem>
              </Accordion>
              <Accordion id='acc2'>
                <AccordionItem title='What should I do if my BPAY payment fails?'>
                  <ul>
                    <li style={{ marginBottom: '10px' }}>
                      Check your CTP insurance ‘use by’ date, if the
                      registration has a 3 month or 6 month term. You can find
                      the use by date on your green slip, or by contacting your
                      insurer. If you’re paying by BPAY, you must make the BPAY
                      payment at least 3 business days before the use by date,
                      or the registration will not be renewed and you’ll need to
                      get a new 12 month green slip.
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                      Check your BPAY payment details, and try renewing online
                      again.
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                      Contact your bank or financial institution, and ask them
                      to do a BPAY investigation – we can help if you call us on
                      13 77 88.
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                      Try renewing your vehicle registration again.
                    </li>
                  </ul>
                </AccordionItem>
              </Accordion>
              <p style={{ margin: '20 0 0.25rem' }}></p>
              <RemainderWrapper>
                <div style={{ marginRight: '1rem' }}>
                  <Svg.Reminder color={tokens.colors.brand.snswSecondaryBlue} />
                </div>
                <div>
                  <h6 style={{ fontSize: '1.25rem', margin: '0 0 0.5rem 0' }}>
                    Renewal reminders
                  </h6>
                  <Spacer mb={1}>
                    <strong>Mail</strong>
                    <p style={{ margin: 0 }}>
                      <a
                        href={
                          'https://account.service.nsw.gov.au/manage/personal-details'
                        }
                        onClick={() =>
                          handleClick(
                            'Internal Link Click',
                            'Update mailing address online'
                          )
                        }
                      >
                        Keep your address up to date
                      </a>{' '}
                      with Transport for NSW if you receive renewal reminders by
                      mail.
                    </p>
                  </Spacer>
                  <strong>Calender alerts</strong>
                  <p style={{ margin: 0 }}>
                    At the bottom of your email receipt, you’ll find a handy
                    link to add a digital reminder to your calendar. You’ll
                    receive your email receipt when your payment has processed.
                  </p>
                </div>
              </RemainderWrapper>
              <NationalPass />
              <Spacer mb={3}>
                <Button
                  type='button'
                  onClick={(e: any) =>
                    handleClick('Button Click', 'Renew another registration')
                  }
                >
                  Renew another registration
                </Button>
              </Spacer>
              <Spacer mb={3}>
                <Button
                  variant='secondary'
                  external='true'
                  onClick={() => handleClick('Button Click', 'Help us improve')}
                >
                  Help us improve
                </Button>
              </Spacer>
              <Spacer mb={3}>
                <QuickfeedStyleOverride>
                  <Quickfeed
                    sourceApplication={TUTD_APPLICATION_NAME}
                    label='How was your registration experience?'
                    environment={process.env.REACT_APP_TUTD}
                  />
                </QuickfeedStyleOverride>
              </Spacer>
            </Col>
          </Row>
        </SectionPage>
      </ContentContainer>
    </>
  );
};

export default BPayInformationPage;
