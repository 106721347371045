import styled from 'styled-components';

export const TransactionDetailsContainer = styled.div`
  border-bottom: 2px solid rgb(222, 227, 229);
  padding-bottom: 32px;

  & .canIChangeTheTerms {
    padding-left: 0;
    margin-top: 8px;
  }
`;

export const OtherTermRenewalCost = styled.strong`
  top: 8px;
  position: relative;
`;

export const OtherTermRenewalInstruction = styled.span`
  top: 8px;
  position: relative;
`;

export const TransactionPriceDetails = styled.div`
  & > div.positiveAmount {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;

    .showDiscount {
      float: left;
      height: 20px;
      width: 20px;
      margin-bottom: 15px;
      border: 1px solid black;
      clear: both;
      padding-left: 4px;
    }
  }

  & > div.negativeAmount {
    padding: 0 0 8px 0;
    display: inline;
    justify-content: space-between;

    .showDiscount {
      float: left;
      height: 17px;
      width: 17px;
      border-bottom: 2px solid rgb(222, 227, 229);
      clear: both;
      padding-left: 4px;
    }
    .addMarginTopNBottom {
      margin-top: 4px;
      margin-bottom: 4px;
    }
    .keepRight {
      float: right;
    }
  }

  .totalAmount {
    font-size: 20px;
  }
`;

export const ChangeTermsAdditionalInfo = styled.span`
  a {
    color: #2e5299;
  }
`;
