import { Config } from '../config/env';

const { logLevel } = Config;

export const debugLogger = (...logDetails: any[]) => {
  if (logLevel === 'DEBUG') {
    // Interesting syntax that works too.. :)
    // console.log.apply(console, logDetails);
    console.log('DEBUG', ...logDetails);
  }
};

export const infoLogger = (...logDetails: any[]) => {
  console.log('INFO', ...logDetails);
};

export const errorLogger = (...logDetails: any[]) => {
  console.log('ERROR', ...logDetails);
};
