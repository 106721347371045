import styled from 'styled-components';
import { Size } from '@rmstransactions/components/Styled';

export const ButtonGroup = styled.div`
  .buttons {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: ${Size.md + 1}px) {
    .button-first {
      min-width: 200px;
    }
    .button-n-plus-1 {
      min-width: 200px;
      margin-left: 24px;
    }
  }

  @media only screen and (max-width: ${Size.md}px) {
    .buttons {
      width: 100%;
    }
    .button-first,
    .button-n-plus-1 {
      width: 100%;
      margin-top: 0px;
    }
  }
`;

export const MoreInfoContainer = styled.span`
  top: 2px;
  position: relative;
  padding-left: 4px;

  div[id='ctpArea-desc'] {
    overflow: auto;
  }

  @media only screen and (max-width: 768px) {
    div[role='dialog'] {
      left: 10%;
      width: 90%;
    }
  }
`;

export const ErrorMessageContainer = styled.span`
  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;
