import React, { useContext, useEffect, useState } from 'react';
import {
  Header,
  Spacer,
  UserSession,
  ErrorService,
} from '@rmstransactions/components';
import { useHistory, useParams } from 'react-router-dom';
import { AppState, store } from 'providers/globalState';
import {
  ComponentLoader,
  ContentContainer,
  SectionPage,
  Heading,
} from '@snsw-gel/react';
import FindVehicleForm from './components/FindVehicleForm';
import MultipleVehicleTiles from './components/MultipleVehicleTiles';
import {
  handleGetRegistrationsByPlateOrBillingNumber,
  handleUserType,
} from './FindVehiclePage.helpers';
import { RenewRegoSession } from '../../session/RenewRegoSession';
import { debugLogger, errorLogger } from '../../services/LoggerService';
import {
  BILLING,
  DRUPAL_RENEW_REGISTRATION_URL,
  MASK_BILLING_NUMBER_PREFIX,
  MISSING_BILLING_NUMBER,
  PLATE,
  SCHEME_CONDITIONAL,
  TRANSACTION_STATE,
} from '../../constants/constants';
import {
  areRegistrationParamsValid,
  haveValidRegistrationParams,
  silentLogin,
} from '../../services/RegistrationService';
import InlineNotification from '../ErrorPage/components/InlineNotification';
import { Col, Row } from '@rmstransactions/components/Styled';
import TimeoutPage from '../TimeoutPage/TimeoutPage';
import { Config } from '../../config/env';

const FindVehiclePage = () => {
  const { dispatch } = useContext(store) as {
    dispatch: any;
    globalState: AppState;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isMultipleVehicle, setIsMultipleVehicle] = useState<boolean>(false);
  const [inlineErrorOrWarnings, setInlineErrorOrWarnings] = useState([]);
  const history = useHistory();
  const backLink = () => {
    history.goBack();
  };

  const {
    plateNumber,
    registrationID,
    registrationScheme,
    plateType,
  }: {
    plateNumber: string;
    registrationID: string;
    registrationScheme: string;
    plateType: string;
  } = useParams();

  const params = new URLSearchParams(window.location.search);
  const veosQueryParamValue = params.get('veos');

  useEffect(() => {
    const { apiHost } = Config;
    document.title = 'Service NSW - Renew registration - Find a vehicle';
    RenewRegoSession.clearReviewInputDetails();
    RenewRegoSession.clearValidateData();
    setIsMultipleVehicle(false);
    setIsLoading(true);
    if (!UserSession.isSilentLoginCalled()) {
      UserSession.setSilentLoginCalled();
      if (veosQueryParamValue) {
        RenewRegoSession.setVEOSQueryParam(veosQueryParamValue);
      }
      if (
        areRegistrationParamsValid(
          plateNumber,
          registrationID,
          registrationScheme,
          plateType
        )
      ) {
        RenewRegoSession.setPlateNumber(plateNumber);
        RenewRegoSession.setRegistrationId(registrationID);
        RenewRegoSession.setRegistrationScheme(registrationScheme);
        if (plateType) {
          RenewRegoSession.setPlateType(plateType);
        }
      }
      silentLogin();
    } else {
      UserSession.resetSilentLoginCalled();
      const plateNumber = RenewRegoSession.getPlateNumber();
      const plateType = RenewRegoSession.getPlateType();
      const registrationID = RenewRegoSession.getRegistrationId();
      const registrationScheme = RenewRegoSession.getRegistrationScheme();
      const veosSessionValue = RenewRegoSession.getVEOSQueryParam();
      if (veosSessionValue) {
        dispatch({
          type: 'set_veosQueryParamValue',
          payload: veosSessionValue,
        });
        RenewRegoSession.clearVEOSQueryParam();
      }
      if (plateType) {
        dispatch({
          type: 'set_plateType',
          payload: plateType,
        });
        RenewRegoSession.clearPlateType();
      }
      debugLogger(
        'registration and data from session storage',
        plateNumber,
        registrationID,
        registrationScheme,
        plateType
      );
      handleUserType(setIsLoading, dispatch, history)
        .then(() => {
          if (
            haveValidRegistrationParams(
              plateNumber,
              registrationID,
              registrationScheme
            )
          ) {
            let numberType = BILLING;
            let number = registrationID;
            if (
              registrationScheme === SCHEME_CONDITIONAL ||
              registrationID?.startsWith(MASK_BILLING_NUMBER_PREFIX) ||
              registrationID?.startsWith(MISSING_BILLING_NUMBER)
            ) {
              numberType = PLATE;
              number = plateNumber;
            }
            clearRegistrationParamsFromStorage();
            handleGetRegistrationsByPlateOrBillingNumber(
              number,
              numberType,
              history,
              dispatch,
              setIsLoading,
              setIsMultipleVehicle,
              setInlineErrorOrWarnings,
              plateType
            );
          } else {
            setIsLoading(false);
          }
        })
        .catch((error: any) => {
          errorLogger('handleUserType gave an error Response', error);
          ErrorService.handleAPIError(
            error,
            history,
            apiHost,
            TRANSACTION_STATE,
            DRUPAL_RENEW_REGISTRATION_URL
          );
        });
    }
  }, [
    history,
    dispatch,
    plateNumber,
    registrationID,
    registrationScheme,
    plateType,
    veosQueryParamValue,
  ]);

  const clearRegistrationParamsFromStorage = () => {
    RenewRegoSession.clearPlateNumber();
    RenewRegoSession.clearRegistrationId();
    RenewRegoSession.clearRegistrationScheme();
  };

  const loadingText = (
    <p>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </p>
  );

  return (
    <>
      <TimeoutPage />
      <Header
        data-testid='header'
        title='Find a vehicle to renew'
        backLink={backLink}
        linkText='Previous'
      />
      <ContentContainer>
        <ComponentLoader fullPage label={loadingText} active={isLoading} />
        {inlineErrorOrWarnings?.length > 0 &&
          inlineErrorOrWarnings?.map((inlineErrorOrWarning: any) => {
            return <SectionPage>
              <Row>
                <Col lg={8}>
                  <InlineNotification
                    errorMessageObject={inlineErrorOrWarning}
                  />
                </Col>
              </Row>
            </SectionPage>
          })}
        <SectionPage>
          <Heading style={{ marginBottom: '0px' }} level={2} >Enter a NSW plate number or billing number</Heading>
          <FindVehicleForm
            setIsLoading={setIsLoading}
            setIsMultipleVehicle={setIsMultipleVehicle}
            setInlineErrorOrWarning={setInlineErrorOrWarnings}
            suppliedPlateNumberOrBillingNumber={plateNumber}
          />
        </SectionPage>

        {isMultipleVehicle && (
          <Spacer mt={36}>
            <MultipleVehicleTiles />
          </Spacer>
        )}
      </ContentContainer>
    </>
  );
};

export default FindVehiclePage;
