import React, { useContext, useEffect, useState } from 'react';
import { Heading, SkeletonContainer, SkeletonShape } from '@snsw-gel/react';
import {
  FormRadioGroupRms,
  helpers,
  Spacer,
} from '@rmstransactions/components';
import { store } from '../../../../providers/globalState';
import {
  ChangeTermsContainer,
  DisplayContent,
  CTPMonthWrapper,
} from './styled';
import FlagsDisplay from './FlagsDisplay';
import { MultipleCTPSorter } from '../../../../calculator/MultipleCTPSorter';
import CTPSafetyCheckNotifications from './CTPSafetyCheckNotifications';
import { IEligibilityFlagsResult } from '../../../../types/Registration';
import { debugLogger } from '../../../../services/LoggerService';
import { MultipleTermSorter } from '../../../../calculator/MultipleTermSorter';
import getFormattedPrice from '../../../../helpers/getFormattedPrice';

const CTPAndTerms = (props: any) => {
  const {
    ctpPolicies,
    validRenewalTerms,
    setShouldContinueTransaction,
    isLoadingSkeleton,
    registrationPriceDetails,
  }: {
    ctpPolicies: any;
    validRenewalTerms: any;
    setShouldContinueTransaction: any;
    isLoadingSkeleton: boolean;
    registrationPriceDetails: any;
  } = props;
  const { dispatch } = useContext(store) as {
    dispatch: any;
  };
  let changeCTPOptions: any = [];
  let changeTermOptions: any = [];
  const ctpCount = Array.isArray(ctpPolicies) ? ctpPolicies.length : 0;
  const validRenewalTermsCount = Array.isArray(validRenewalTerms)
    ? validRenewalTerms.length
    : 0;
  const eligibilityFlagsResultTemp: IEligibilityFlagsResult = {
    term: '',
    ctpFlag: '',
    safetyInspectionFlag: '',
    hvInspectionFlag: '',
    computedSafetyCheckFlag: '',
    eligible: false,
  };

  //State variables
  let multipleCTPs = false;
  let singleCTP = false;
  let multipleValidRenewalTerms = false;
  let sortedCTPPolicies: any = [];
  let sortedValidRenewalTerms: any = [];
  const [selectedTerm, setSelectedTerm] = useState('');
  const [selectedTermIndex, setSelectedTermIndex] = useState(0);
  const [selectedCTPIndex, setSelectedCTPIndex] = useState(0);
  const [selectedCTP, setSelectedCTP] = useState(null);
  const [eligibilityFlagsResult, setEligibilityFlagsResult] = useState(
    eligibilityFlagsResultTemp
  );

  const legendText = (
    <div>
      <div>
        More than one CTP insurance has been purchased for this vehicle. Please
        select which one you would like to use to renew this registration.
      </div>

      <div style={{ marginTop: '20px' }}>
        Contact your insurance provider to cancel or refund any CTPs you don't
        use.
      </div>
    </div>
  );
  if (ctpCount > 1) {
    multipleCTPs = true;
    sortedCTPPolicies = MultipleCTPSorter(ctpPolicies, validRenewalTerms);
    sortedCTPPolicies.forEach((ctpPolicy: any, index: number) => {
      const labelContent = (
        <DisplayContent>
          <span>
            <span>{ctpPolicy?.insurer.insurerName + ' CTP number '}</span>
            <span>{ctpPolicy?.policyNumber + '   '}</span>
          </span>
          <CTPMonthWrapper>
            {helpers.ctpMonths(ctpPolicy.policyTerm) + ' months'}
          </CTPMonthWrapper>
        </DisplayContent>
      );
      changeCTPOptions.push({
        value: index,
        label: labelContent,
      });
    });
  } else if (ctpCount === 1) {
    singleCTP = true;
    sortedCTPPolicies = MultipleCTPSorter(ctpPolicies, validRenewalTerms);
  } else if (validRenewalTermsCount > 1) {
    sortedValidRenewalTerms = MultipleTermSorter(validRenewalTerms);
    multipleValidRenewalTerms = true;
    sortedValidRenewalTerms.forEach((validRenewalTerm: any, index: number) => {
      const labelContent = (
        <DisplayContent>
          <span>{helpers.ctpMonths(validRenewalTerm.term) + ' months '}</span>
          {isLoadingSkeleton ? (
            <SkeletonContainer height={16} width={75}>
              <span
                style={{
                  float: 'right',
                  width: '75px',
                  marginTop: '4px',
                }}
              >
                <SkeletonShape height={16} width={75} />
              </span>
            </SkeletonContainer>
          ) : (
            <span data-testid='price'>
              <CTPMonthWrapper>
                {getFormattedPrice(
                  parseFloat(
                    registrationPriceDetails?.filter(
                      (priceDetail: any) =>
                        priceDetail.term === validRenewalTerm.term
                    )[0]?.transactionPrice?.grossAmount
                  )
                )}
              </CTPMonthWrapper>
            </span>
          )}
        </DisplayContent>
      );
      changeTermOptions.push({
        value: index,
        label: labelContent,
      });
    });
  } else if (validRenewalTermsCount === 1) {
    sortedValidRenewalTerms = MultipleTermSorter(validRenewalTerms);
  } else {
    debugLogger('There is no valid renewal term found.');
  }

  const onChangeCTP = (value: any) => {
    const index = parseInt(value);
    setSelectedCTPIndex(index);
    setSelectedTerm(sortedCTPPolicies[index].policyTerm);
    setSelectedCTP(sortedCTPPolicies[index]);
    setEligibilityFlagsResult(sortedCTPPolicies[index].eligibilityFlagsResult);
    if (sortedCTPPolicies[index].eligibilityFlagsResult.eligible) {
      setShouldContinueTransaction(true);
    } else {
      setShouldContinueTransaction(false);
    }
    dispatch({
      type: 'set_selectedTerm',
      payload: sortedCTPPolicies[index].policyTerm,
    });
    dispatch({
      type: 'set_selectedCTPPolicy',
      payload: sortedCTPPolicies[index],
    });
  };

  const onChangeTerm = (value: any) => {
    const index = parseInt(value);
    setSelectedTermIndex(index);
    setSelectedTerm(sortedValidRenewalTerms[index].term);
    setEligibilityFlagsResult(
      sortedValidRenewalTerms[index].eligibilityFlagsResult
    );
    if (sortedValidRenewalTerms[index].eligibilityFlagsResult.eligible) {
      setShouldContinueTransaction(true);
    } else {
      setShouldContinueTransaction(false);
    }
    dispatch({
      type: 'set_selectedTerm',
      payload: sortedValidRenewalTerms[index].term,
    });
  };

  useEffect(() => {
    if (multipleCTPs || singleCTP) {
      setSelectedCTPIndex(selectedCTPIndex);
      setSelectedTerm(sortedCTPPolicies[selectedCTPIndex]?.policyTerm);
      setSelectedCTP(sortedCTPPolicies[selectedCTPIndex]);
      setEligibilityFlagsResult(
        sortedCTPPolicies[selectedCTPIndex]?.eligibilityFlagsResult
      );
      if (
        !sortedCTPPolicies[selectedCTPIndex]?.eligibilityFlagsResult?.eligible
      ) {
        setShouldContinueTransaction(false);
      } else {
        setShouldContinueTransaction(true);
      }
      dispatch({
        type: 'set_selectedTerm',
        payload: sortedCTPPolicies[selectedCTPIndex]?.policyTerm,
      });
      dispatch({
        type: 'set_selectedCTPPolicy',
        payload: sortedCTPPolicies[selectedCTPIndex],
      });
    } else if (validRenewalTermsCount >= 1) {
      setSelectedTerm(sortedValidRenewalTerms[selectedTermIndex]?.term);
      setEligibilityFlagsResult(
        sortedValidRenewalTerms[selectedTermIndex].eligibilityFlagsResult
      );
      if (
        !sortedValidRenewalTerms[selectedTermIndex].eligibilityFlagsResult
          .eligible
      ) {
        setShouldContinueTransaction(false);
      }
      dispatch({
        type: 'set_selectedTerm',
        payload: sortedValidRenewalTerms[selectedTermIndex]?.term,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ctpPolicies), JSON.stringify(validRenewalTerms)]);

  // @ts-ignore
  return (
    <>
      {multipleCTPs && (
        <>
          <ChangeTermsContainer>
            <Spacer mt={48}>
              {' '}
              <Heading level={2}>Select your CTP insurance</Heading>
              <FormRadioGroupRms
                id='changeCTP'
                name='changeCTP'
                legend={legendText}
                options={changeCTPOptions}
                vertical={true}
                value={selectedCTPIndex}
                onChange={onChangeCTP}
              ></FormRadioGroupRms>
            </Spacer>
          </ChangeTermsContainer>
        </>
      )}
      {multipleValidRenewalTerms && (
        <>
          <ChangeTermsContainer>
            <Spacer mt={36}>
              {' '}
              <Heading level={2}>Select your renewal term</Heading>
              <FormRadioGroupRms
                id='changeTerm'
                name='changeTerm'
                options={changeTermOptions}
                vertical={true}
                value={selectedTermIndex}
                onChange={onChangeTerm}
              ></FormRadioGroupRms>
            </Spacer>
          </ChangeTermsContainer>
        </>
      )}
      <Spacer mt={48} />
      <FlagsDisplay
        ctpPolicy={selectedCTP}
        eligibilityFlagsResult={eligibilityFlagsResult}
        term={selectedTerm}
      />
      {!eligibilityFlagsResult.eligible && (
        <CTPSafetyCheckNotifications
          eligibilityFlagsResult={eligibilityFlagsResult}
        />
      )}
    </>
  );
};
export default CTPAndTerms;
