import styled from 'styled-components';
import { ContentContainer, tokens } from '@snsw-gel/react';

interface PrivacyDisclaimerProps {
  inline?: boolean;
}

export const PrivacyWrapper = styled(ContentContainer)<PrivacyDisclaimerProps>`
  ${({ inline }) =>
    inline
      ? `
        padding: 0
        
        > div {
          padding: 0
        }
      `
      : `
        background-color: ${tokens.colors.grey.iceBlue};

        && {
          padding: 1.25rem 0;

          @media screen and (min-width: ${tokens.breakpoints.md}px) {
            padding: 2rem 0;
          }
        }
      `}
`;

export const GridLayout = styled.div<PrivacyDisclaimerProps>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  max-width: 48rem;

  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    grid-template-columns: auto 1fr;
  }

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    gap: ${({ inline }) => (inline ? '1rem' : '1.25rem')};
  }
`;

export const DisclaimerText = styled.p`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${tokens.colors.grey.shuttle};
`;
