import React from 'react';
import {
  IconNotificationError,
  IconNotificationSuccess,
} from '@snsw-gel/react';
import TermDisplay from './TermDisplay';
import * as Styled from './styled';

const FlagsDisplay = (props: any) => {
  const {
    ctpPolicy,
    eligibilityFlagsResult,
    term,
  }: { ctpPolicy: any; eligibilityFlagsResult: any; term: any } = props;

  return (
    <>
      <Styled.HeadingDetails>Renewal details</Styled.HeadingDetails>
      <TermDisplay term={term} ctpPolicy={ctpPolicy} />
      {eligibilityFlagsResult?.computedSafetyCheckFlag === 'V' && (
        <Styled.FlagsLine>
          <IconNotificationSuccess size='md' />
          &nbsp;Safety check complete
          <br></br>
        </Styled.FlagsLine>
      )}
      {eligibilityFlagsResult?.computedSafetyCheckFlag === 'I' && (
        <Styled.FlagsLine>
          <IconNotificationError size='md' />
          &nbsp;Safety check required <br></br>
        </Styled.FlagsLine>
      )}
      {eligibilityFlagsResult?.ctpFlag === 'V' && (
        <Styled.FlagsLine>
          <IconNotificationSuccess size='md' />
          &nbsp;
          {'CTP (Green Slip) ' +
            ctpPolicy?.insurer?.insurerName +
            ' ' +
            ctpPolicy?.policyNumber}
        </Styled.FlagsLine>
      )}
      {eligibilityFlagsResult?.ctpFlag === 'I' && (
        <Styled.FlagsLine>
          <IconNotificationError size='md' />
          &nbsp;CTP (Green Slip) insurance required
        </Styled.FlagsLine>
      )}
    </>
  );
};
export default FlagsDisplay;
