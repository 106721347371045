import React from 'react';
import {
  Button,
  ContentContainer,
  Col,
  Row,
  InPageAlert,
} from '@snsw-gel/react';
import { ButtonGroup } from '../../../styled';
import { Header } from '@rmstransactions/components';
import { useHistory } from 'react-router-dom';
import { gotoDrupal } from '../../../helpers/commonHelper';
import {ErrorMessageContainer} from "../../../styled";

const ErrorLayout = (props: any) => {
  const {
    heading,
    children,
    showRenewAnotherVehicle,
    messageHeadline,
  }: {
    heading: string;
    children: any;
    showRenewAnotherVehicle: boolean;
    messageHeadline: string;
  } = props;
  const history = useHistory();
  const gotoFindVehiclePage = () => {
    history.push('/find-vehicle');
  };

  const handleClickQuitBtn = () => {
    return gotoDrupal();
  };

  return (
    <>
      <Header title={heading} data-testid='header' />
      <ContentContainer>
        <Row>
          <Col span={8}>
            <InPageAlert variant="error" title={messageHeadline}>
              <ErrorMessageContainer>{children}</ErrorMessageContainer>
            </InPageAlert>
            <ButtonGroup>
              <Button
                onClick={handleClickQuitBtn}
                theme='secondary'
                className='buttons'
              >
                Quit
              </Button>
              {showRenewAnotherVehicle && (
                <Button
                  variant='tertiary'
                  className='buttons button-n-plus-1'
                  onClick={gotoFindVehiclePage}
                >
                  Renew a different vehicle
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
};

export default ErrorLayout;
