import React from 'react';

import {
  SkeletonContainer,
  SkeletonShape,
} from '@snsw-gel/react';
import { SkeletonShapeContainer, FlexContainer } from '../../styled';

import { Spacer } from '@rmstransactions/components';

const TotalCostSkeleton = () => {
  return (
    <Spacer mt={32}>
      <SkeletonContainer>
        <FlexContainer>
          <SkeletonShapeContainer>
            <SkeletonShape height={16} width={471} />
          </SkeletonShapeContainer>
          <SkeletonShape height={16} width={75} />
        </FlexContainer>
      </SkeletonContainer>

      <Spacer mt={24}>
        <SkeletonContainer>
          <FlexContainer>
            <SkeletonShapeContainer>
              <SkeletonShape height={16} width={471} />
            </SkeletonShapeContainer>

            <SkeletonShape height={16} width={75} />
          </FlexContainer>
        </SkeletonContainer>
      </Spacer>

      <Spacer mt={24}>
        <SkeletonContainer>
          <FlexContainer>
            <SkeletonShapeContainer>
              <SkeletonShape height={16} width={471} />
            </SkeletonShapeContainer>

            <SkeletonShape height={16} width={75} />
          </FlexContainer>
        </SkeletonContainer>
      </Spacer>

      <Spacer mt={24} mb={32}>
        <SkeletonContainer>
          <SkeletonShape height={16} width={273} />
        </SkeletonContainer>
      </Spacer>
    </Spacer>
  );
};

export default TotalCostSkeleton;
