import React, { useContext } from 'react';
import { store, AppState } from 'providers/globalState';
import { Button, Row, Col } from '@snsw-gel/react';
import { CardDetail } from '@rmstransactions/components/Styled';
import { VehicleCardDetails, helpers } from '@rmstransactions/components';
import { useHistory } from 'react-router-dom';
import { isEligibleToRenew } from '../FindVehiclePage.helpers';
import {
  stripSpecificDRIVESMessage,
  stripSuccessIfAny,
} from '../../../helpers/error-processor';
import InlineNotification from '../../ErrorPage/components/InlineNotification';
import {
  AUTHENTICATED_LINKED_USER_TYPE,
  ERROR_MESSAGE_TECHNICAL_ERROR,
  SCHEME_CONDITIONAL,
  SCHEME_FULL,
} from 'constants/constants';
import { RenewRegoSession } from 'session/RenewRegoSession';

const MultipleVehicleTiles = () => {
  const { globalState, dispatch } = useContext(store) as {
    globalState: AppState;
    dispatch: any;
  };

  const history = useHistory();

  const registration = globalState?.multipleVehicleList?.registration;
  const eligibility = globalState?.multipleVehicleList?.eligibility;
  const searchedValue = globalState?.multipleVehicleList?.searchedValue;
  let userDetails = globalState?.userDetails;

  const handleClickRenewButton = (
    selectedVehicle: number,
    selectedEligibility: number
  ) => {
    if (
      registration[selectedVehicle].bindingId ===
        eligibility[selectedEligibility].bindingId &&
      isEligibleToRenew(eligibility[selectedEligibility].eligibility)
    ) {
      const reviewDetailsInput = {
        registration: registration[selectedVehicle].registration,
        eligibility: eligibility[selectedEligibility].eligibility,
      };
      RenewRegoSession.setReviewInputDetails(
        JSON.stringify(reviewDetailsInput)
      );
      dispatch({
        type: 'set_reviewDetailsInput',
        payload: reviewDetailsInput,
      });
      history.push('/review-details');
    }
  };

  eligibility?.sort((a: any, b: any) => {
    if (isEligibleToRenew(a.eligibility) && isEligibleToRenew(b.eligibility)) {
      return 0;
    } else if (isEligibleToRenew(a.eligibility)) {
      return -1;
    }
    return 1;
  });

  if (RenewRegoSession.getUserDetails() !== null) {
    userDetails = JSON.parse(RenewRegoSession.getUserDetails()!);
  }

  const displayDRIVESMessage = (messages: any) => {
    const nonSuccessMessages = stripSuccessIfAny(messages);
    nonSuccessMessages.map(
      (message: any) =>
        (message.pageActionID = 'FIND_VEHICLE_PAGE_CHECK_ELIGIBILITY')
    );
    return (
      nonSuccessMessages?.length > 0 &&
      nonSuccessMessages?.map((message: any) => {
        return <InlineNotification errorMessageObject={message} />;
      })
    );
  };

  const showVehicleDetails = (
    vehicle: any,
    vehicleIndex: number,
    vehicleEligible: any,
    eligibilityIndex: number,
    message: any
  ) => {
    let registrationScheme =
      vehicleEligible?.eligibility?.registrationEligibilityInfo
        ?.registrationScheme;
    if (!helpers.isNotEmptyValue(registrationScheme)) {
      if (helpers.isNotEmptyValue(vehicle?.registration?.registrationID)) {
        registrationScheme = SCHEME_FULL;
      } else {
        registrationScheme = SCHEME_CONDITIONAL;
      }
    }

    const isRegisteredOperator =
      userDetails?.type === AUTHENTICATED_LINKED_USER_TYPE &&
      userDetails?.rmsCustomerId ===
        vehicleEligible?.eligibility?.registrationEligibilityInfo?.customerID;

    return (
      <Row alignItems='center' data-testid='multiple-vehicle-tile'>
        <Col span={8}>
          <CardDetail>
            <Row alignItems='center'>
              <Col span={6}>
                <VehicleCardDetails
                  key={`vehicleBindingId-${vehicleIndex}`}
                  plateNumber={vehicle.registration.plate.plateNumber}
                  registeredVehicle={vehicle?.registration?.registeredVehicle}
                  expiryDate={vehicle.registration.effectivePeriod.expiryDate}
                  billingNumber={
                    registrationScheme === SCHEME_FULL
                      ? vehicle?.registration?.registrationID
                      : ''
                  }
                  registrationScheme={registrationScheme}
                  isRegisteredOperator={isRegisteredOperator}
                  searchResults={true}
                />
              </Col>
              <Col span={6} style={{ marginLeft: 'auto', flex: 'inherit' }}>
                {isEligibleToRenew(vehicleEligible?.eligibility) && (
                  <Button
                    key={`buttonBindingId-${eligibilityIndex}`}
                    style={{ width: '249px' }}
                    onClick={() => {
                      handleClickRenewButton(vehicleIndex, eligibilityIndex);
                    }}
                  >
                    Renew vehicle
                  </Button>
                )}
              </Col>
            </Row>
            <>{displayDRIVESMessage(message)}</>
          </CardDetail>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {eligibility?.map((vehicleEligible: any, eligibilityIndex: number) => {
        return registration?.map((vehicle: any, vehicleIndex: number) => {
          if (helpers.isNotEmptyObject(vehicleEligible?.eligibility)) {
            if (vehicle.bindingId === vehicleEligible.bindingId) {
              return showVehicleDetails(
                vehicle,
                vehicleIndex,
                vehicleEligible,
                eligibilityIndex,
                stripSpecificDRIVESMessage(
                  vehicleEligible?.eligibility?.systemMessages?.message,
                  'NO_CTP_ESAFETY_DETAILS'
                )
              );
            }
          } else {
            return showVehicleDetails(
              vehicle,
              vehicleIndex,
              null,
              0,
              ERROR_MESSAGE_TECHNICAL_ERROR
            );
          }
          return <></>;
        });
      })}
    </>
  );
};

export default MultipleVehicleTiles;
