import React from 'react';
import {
  MoreInfoOnCTPArea,
  OperatingConditionsTable,
  RegistrationConditionsContainer,
  AreaContainer,
  SpecialCondition,
} from './styled';
import { IConditions } from 'types/Conditions';
import { Heading, MoreInfoModal } from '@snsw-gel/react';
import ContentPanel from 'components/ContentPanel';
import RegistrationConditionsMoreInfo from './RegistrationConditionsMoreInfo';
import { ctpLabels } from './ctpDisplayValues';
import { AnalyticsService } from '@rmstransactions/components';

interface RegistrationConditionsProps {
  conditions: IConditions;
  transactionStartTime: Date;
}

let tooltipStatus = false;
const handleClick = (eventName: String, transactionStartTime: Date) => {
  if (tooltipStatus === false) {
    AnalyticsService.ButtonClick(
      'Button Click',
      eventName,
      transactionStartTime
    );
    tooltipStatus = true;
  }
};

const RegistrationConditions: React.FunctionComponent<
  RegistrationConditionsProps
> = ({ conditions, transactionStartTime }) => {
  const ctpDisplayLabel = ctpLabels?.filter(
    (ctpLabel) => ctpLabel?.code === conditions?.ctpClass?.code
  )[0].label;
  const moreInfoTitle =
    conditions?.ctpClass?.code === 'TRLR'
      ? 'What is ‘Area’ of operation?'
      : 'What is ‘CTP Class’ and ‘Area’ of operation?';
  let ctpClassCode =
    conditions?.ctpClass?.code === 'TRLR' ? 'Type:' : 'CTP Class:';
  let eventName =
    'Tooltip: ' +
    ctpClassCode +
    ' ' +
    ctpDisplayLabel +
    ' (' +
    conditions?.ctpClass?.code +
    ') Area: ' +
    conditions.area;
  return (
    <RegistrationConditionsContainer>
      <strong>
        {conditions?.ctpClass?.code === 'TRLR' ? 'Type:' : 'CTP Class:'}
      </strong>{' '}
      <span data-testid='ctpClass'>
        {ctpDisplayLabel + ' (' + conditions?.ctpClass?.code + ')'}
      </span>{' '}
      <AreaContainer>
        <span
          className={
            ctpDisplayLabel.length > 15
              ? 'wordWrapForContentLength'
              : 'wordWrapForDeviceLength'
          }
        >
          <strong>Area:</strong>{' '}
          <span>
            <span data-testid='ctpArea'>{conditions.area}</span>
            <MoreInfoOnCTPArea
              onClick={() => handleClick(eventName, transactionStartTime)}
            >
              <MoreInfoModal
                hyperLinKText={
                  conditions?.ctpClass?.code === 'TRLR'
                    ? 'Click to know more Info on Area'
                    : 'Click to know more Info on CTP Class and Area'
                }
                title={moreInfoTitle}
                helpText={
                  <RegistrationConditionsMoreInfo
                    ctpClass={conditions.ctpClass}
                  />
                }
                id='ctpArea'
              />
            </MoreInfoOnCTPArea>
          </span>{' '}
        </span>
      </AreaContainer>
      <ContentPanel
        id='conditionsPanel'
        toggleButtonText='Operating conditions'
        toggleButtonClass='operatingConditions'
        panelContentClass='operatingConditionsPanel'
      >
        <Heading level={3}>Operating conditions</Heading>
        <p>Operating conditions restrict how the vehicle can be used.</p>
        <OperatingConditionsTable>
          <table data-testid='operatingConditionsTable'>
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {conditions?.operatingConditions?.map((operatingCondition) => (
                <tr key={`code${operatingCondition?.code}`}>
                  <td>{operatingCondition?.code}</td>
                  <td>{operatingCondition?.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </OperatingConditionsTable>

        {conditions.specialConditions &&
          conditions.specialConditions.length > 0 && (
            <>
              <Heading level={3}>Special conditions</Heading>
              {conditions.specialConditions.length === 1 && (
                <p>{conditions.specialConditions}</p>
              )}
              {conditions.specialConditions.length > 1 && (
                <ul>
                  {conditions.specialConditions.map((specialCondition) => (
                    <SpecialCondition key={`code${specialCondition}`}>
                      {specialCondition}
                    </SpecialCondition>
                  ))}
                </ul>
              )}
            </>
          )}
      </ContentPanel>
    </RegistrationConditionsContainer>
  );
};

export default RegistrationConditions;
