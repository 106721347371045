import React from 'react';
import styled from 'styled-components';
import {
  Button,
  ContentContainer,
  Heading,
  SectionPage,
  InPageAlert,
} from '@snsw-gel/react';
import {
  Header,
  SkeletonItemRectangle,
  SkeletonLoader,
} from '@rmstransactions/components';
import { Col, Row, Size } from '@rmstransactions/components/Styled';
import { window } from 'helpers/window';
import { ButtonGroup, ConditionalDeclarations } from './styled';
import { RegistrationConditionsContainer } from './components/RegistrationConditions/styled';
import TermsAndConditions from './components/TermsAndConditions';

const Nowrap = styled.span`
  white-space: nowrap;
`;
const LoadingRenewalPeriodContainer = styled.div`
  padding-bottom: 24px;
  .NoPadding {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
const KeepRight = styled.span`
  // float: right; will not work in a flex container, hence the below trick
  margin-left: auto;
  order: 2;
`;
const AddALine = styled.div`
  border-bottom: 2px solid rgb(222, 227, 229);
  padding-bottom: 32px;
`;

export const LoaderButtonGroup = styled.div`
  display: flex;
  .conditionalButton {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: ${Size.md}px) {
    .conditionalButton-continue {
      margin-left: 24px;
    }

    .conditionalButton-back {
      min-width: 0;
    }
  }
`;

const maxWidthSkeleton = Size.md;
const pagePadding = window.innerWidth <= Size.sm ? 40 : 60;
const windowWidth = window.innerWidth - pagePadding;
const skeletonWidth =
  maxWidthSkeleton < windowWidth
    ? String(maxWidthSkeleton)
    : String(windowWidth);
interface TAndCsProps {
  handleAgreeTAndCs: () => void;
}
const RegistrationSummaryPageLoader: React.FunctionComponent<TAndCsProps> = ({
  handleAgreeTAndCs,
}: TAndCsProps) => {
  const tcsAgreedDuringLoader = () => {
    handleAgreeTAndCs();
  };
  return (
    <>
      <Header data-testid="header" title="Check your registration renewal" />
      <ContentContainer>
        <SectionPage>
          <Row>
            <Col lg={8}>
              <SkeletonLoader id="1" width={skeletonWidth} height="102">
                <SkeletonItemRectangle width="23" height="26" />
                <SkeletonItemRectangle y={35} width="60" height="16" />
                <SkeletonItemRectangle y={60} width="60" height="16" />
                <SkeletonItemRectangle y={85} width="60" height="16" />
              </SkeletonLoader>

              <InPageAlert title='Changes to the registration details of this vehicle cannot be done online.'
                           variant='info'>
                If the ‘Operating conditions’, ‘CTP Class’ or ‘Area’ of
                operation, have changed since your last renewal, you can visit
                a service centre or call <Nowrap>13 77 88</Nowrap> to update.
              </InPageAlert>

              <LoadingRenewalPeriodContainer>
                <SkeletonLoader id="2" width="273" height="26" />
                <p className="NoPadding">Based on your previous renewal term</p>
              </LoadingRenewalPeriodContainer>

              <RegistrationConditionsContainer>
                <LoaderButtonGroup>
                  <KeepRight>
                    <SkeletonLoader id="23" width="174" height="22">
                      <SkeletonItemRectangle width="174" height="22" y={4} />
                    </SkeletonLoader>
                  </KeepRight>
                  <strong>CTP Class:</strong>&nbsp;&nbsp;
                  <SkeletonLoader id="23" width="127" height="22">
                    <SkeletonItemRectangle width="127" height="22" y={4} />
                  </SkeletonLoader>
                  &nbsp;&nbsp;&nbsp;
                  <strong>Area:</strong>&nbsp;&nbsp;
                  <SkeletonLoader id="23" width="127" height="22">
                    <SkeletonItemRectangle width="127" height="22" y={4} />
                  </SkeletonLoader>
                </LoaderButtonGroup>
              </RegistrationConditionsContainer>

              <SkeletonLoader id="4" width={skeletonWidth} height="136">
                <SkeletonItemRectangle width="67" height="16" />
                <SkeletonItemRectangle width="20" height="16" x={90} />
                <SkeletonItemRectangle width="67" height="16" y={40} />
                <SkeletonItemRectangle width="20" height="16" x={90} y={40} />
                <SkeletonItemRectangle width="67" height="16" y={80} />
                <SkeletonItemRectangle width="20" height="16" x={90} y={80} />
                <SkeletonItemRectangle width="35.55" height="16" y={120} />
              </SkeletonLoader>
              <AddALine />

              <ConditionalDeclarations>
                <Heading level={3}>I declare that</Heading>

                <LoaderButtonGroup>
                  <SkeletonLoader id="51" width="70" height="46" />
                  <SkeletonLoader id="511" width={skeletonWidth} height="46">
                    <SkeletonItemRectangle width={skeletonWidth} height="16" />
                    <SkeletonItemRectangle width="88" height="16" y={28} />
                  </SkeletonLoader>
                </LoaderButtonGroup>
                <TermsAndConditions
                  handleAgreeTAndCs={() => tcsAgreedDuringLoader()}
                />
                <LoaderButtonGroup>
                  <SkeletonLoader id="51" width="70" height="46" />
                  <SkeletonLoader id="511" width={skeletonWidth} height="46">
                    <SkeletonItemRectangle width={skeletonWidth} height="16" />
                    <SkeletonItemRectangle width="88" height="16" y={28} />
                  </SkeletonLoader>
                </LoaderButtonGroup>

                <p>
                  Making this declaration you understand that a false
                  declaration may result in criminal prosecution with maximum
                  penalties including imprisonment for up to 2 years and a fine
                  of up to $22,000.
                </p>
              </ConditionalDeclarations>

              <ButtonGroup>
                <Button
                  theme="secondary"
                  className="conditionalButton conditionalButton-back"
                  data-testid="backButton"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Back
                </Button>
                <Button
                  disabled
                  theme="secondary"
                  className="conditionalButton conditionalButton-continue"
                  data-testid="continueButton"
                >
                  Continue to payment
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </SectionPage>
      </ContentContainer>
    </>
  );
};

export default RegistrationSummaryPageLoader;
