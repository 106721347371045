interface ICustomer {
  mailingAddress: string;
  emailAddress: string;
}

export function isCustomerData(data: any): data is ICustomer {
  return (
    !!data &&
    typeof data.emailAddress === 'string'
  );
}

export type { ICustomer };
