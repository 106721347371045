import React from 'react';
import { TextLink } from '@snsw-gel/react';
import { Line } from '../../../styled';
import { EditAndRemoveStyle } from '../styled';
import { Box, Text } from '@rmstransactions/components';
import getFormattedPrice from '../../../../../helpers/getFormattedPrice';

const VEOSContribution = (props: any) => {
  const {
    renewalCost,
    carbonOffsetAmount,
    setIsVeosEditSelected,
    setIsVeosRemoveSelected,
    setCarbonOffsetAmount,
    setActiveVeosDollarButton,
    setCustomCarbonOffsetAmount,
    setDollarButtonValue,
    setCustomInputErrorMessage,
    setHasError,
  } = props;

  const carbonOffsetFloatAmount = parseFloat(carbonOffsetAmount);
  const renewalCostFloatValue = parseFloat(renewalCost);
  const totalAmountWithContribution =
    renewalCostFloatValue + carbonOffsetFloatAmount;

  const handleEdit = () => {
    setIsVeosEditSelected(true);
  };
  const handleRemove = () => {
    setIsVeosRemoveSelected(true);
    setIsVeosEditSelected(false);
    setCarbonOffsetAmount(null);
    setActiveVeosDollarButton(null);
    setCustomCarbonOffsetAmount(null);
    setDollarButtonValue('');
    setCustomInputErrorMessage('');
    setHasError('');
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Box
          display='flex'
          flexWrap='wrap'
          style={{ columnGap: '2rem', rowGap: '0.5rem' }}
        >
          <span data-testid='carbonOffsetContributionLineItem'>
            Carbon offset contribution
          </span>
          <span>
            <EditAndRemoveStyle>
              <TextLink
                className='editLink'
                onClick={handleEdit}
                data-testid='editVeos'
              >
                edit
              </TextLink>{' '}
              |{' '}
              <TextLink
                className='removeLink'
                onClick={handleRemove}
                data-testid='removeVeos'
              >
                remove
              </TextLink>
            </EditAndRemoveStyle>
          </span>
        </Box>

        <span>
          <Text data-testid='carbonOffsetAmount'>
            {getFormattedPrice(carbonOffsetFloatAmount)}
          </Text>
        </span>
      </Box>

      <Box
        fontSize={12}
        fontStyle='italic'
        mt='1'
        mb='1'
        style={{ textRendering: 'optimizeLegibility' }}
      >
        <strong data-testid='veosVoluntaryContributionText'>
          This voluntary contribution is non-refundable and separate to your
          renewal fees.
        </strong>
      </Box>
      <Line />
      <Box
        mb={18}
        mt={18}
        fontWeight='bold'
        display='flex'
        justifyContent='space-between'
      >
        <Text fontSize={18}>Total due</Text>
        <Text fontSize={20} data-testid='totalDue'>
          {getFormattedPrice(totalAmountWithContribution)}
        </Text>
      </Box>
      <Line />
    </>
  );
};

export default VEOSContribution;
