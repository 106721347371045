import React from 'react';
import {
  AreaName,
  CTPDescriptionTable,
  CTPQuestion,
  CTPQuestionAnswer,
  CTPQuestionAnswerExtraInfo,
  RegistrationConditionsMoreInfoContainer,
} from './styled';
import { ctpDescriptions, ctpLabels } from './ctpDisplayValues';
import { ICtpClass } from '../../../../types/Conditions';
import { debugLogger } from '../../../../services/LoggerService';

interface IRegistrationConditionsMoreInfo {
  ctpClass: ICtpClass;
}

const RegistrationConditionsMoreInfo: React.FunctionComponent<
  IRegistrationConditionsMoreInfo
> = ({ ctpClass }) => {
  const ctpClassCode = ctpClass?.code;
  debugLogger(
    'ctpDescriptions',
    ctpDescriptions?.filter(
      (ctpDescription) => ctpDescription?.code === ctpClassCode
    )[0]
  );
  const ctpClassDescription =
    ctpClassCode !== 'TRLR'
      ? ctpDescriptions?.filter(
          (ctpDescription) => ctpDescription?.code === ctpClassCode
        )[0].description
      : '';
  return (
    <RegistrationConditionsMoreInfoContainer>
      {ctpClassCode !== 'TRLR' && (
        <>
          <CTPQuestion>What does my ‘CTP class’ mean?</CTPQuestion>
          <CTPQuestionAnswer>
            For conditionally registered vehicles, a ‘CTP class’ is determined
            by what is entered as the shape displayed on the{' '}
            <a
              className='externalLink'
              href={
                'https://roads-waterways.transport.nsw.gov.au/roads/vehicle-registration/conditional-seasonal/conditional/vehicle-sheets/index.html'
              }
              target={'_blank'}
              rel='noopener noreferrer'
            >
              Conditional Registration Vehicle Sheet
            </a>{' '}
            upon registration.
          </CTPQuestionAnswer>
          <CTPDescriptionTable>
            <table data-testid='ctpDescriptionTable'>
              <thead>
                <tr>
                  <th>CTP Class</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      {
                        ctpLabels?.filter(
                          (ctpLabel) => ctpLabel?.code === ctpClassCode
                        )[0].label
                      }
                    </strong>
                  </td>
                  <td
                    dangerouslySetInnerHTML={{ __html: ctpClassDescription }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </CTPDescriptionTable>
          <CTPQuestion>What does my ‘Area’ mean?</CTPQuestion>
        </>
      )}
      <CTPQuestionAnswer>
        The ‘Area’ of operation is the location of primary use for this
        registration.
      </CTPQuestionAnswer>

      <CTPQuestionAnswerExtraInfo>
        ‘<AreaName>Country</AreaName>’ applies to country areas by postcode
        zones.
      </CTPQuestionAnswerExtraInfo>
      <CTPQuestionAnswerExtraInfo>
        ‘<AreaName>Other</AreaName>’ applies to non-country areas, including
        Sydney metropolitan, outer metropolitan, Newcastle, Central Coast and
        Wollongong districts.
      </CTPQuestionAnswerExtraInfo>

      <CTPQuestionAnswerExtraInfo>
        <CTPQuestion>Need to update details?</CTPQuestion>
      </CTPQuestionAnswerExtraInfo>
      <CTPQuestionAnswer>
        If the {ctpClassCode !== 'TRLR' && <span>‘CTP Class’ or </span>}‘Area’
        of operation, have changed since your last renewal, you can visit a{' '}
        <a
          href={'https://www.service.nsw.gov.au/service-centre'}
          target={'_blank'}
          rel='noopener noreferrer'
        >
          service centre
        </a>{' '}
        or call 13 77 88 to update.
      </CTPQuestionAnswer>
    </RegistrationConditionsMoreInfoContainer>
  );
};

export default RegistrationConditionsMoreInfo;
