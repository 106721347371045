import React from 'react';
import { InPageAlert } from '@snsw-gel/react';
import {getAccessibleMessage} from '../ErrorDetails.helpers';
import {treatErrorWarningCodesAsInfo} from "../../../config/error-messages";
import {ErrorMessageContainer} from "../../../styled";

const InlineNotificationConfirmation = (props: any) => {
    const {
        errorMessageObject,
        confirmationData
    }: {
        errorMessageObject: any;
        confirmationData: any
    } = props;
    window.scrollTo(0, 0);

    let severity = errorMessageObject?.severity?.toLowerCase();

    if (
        // @ts-ignore
        treatErrorWarningCodesAsInfo[errorMessageObject?.pageActionID]?.filter(
            (errorCode: string) => errorCode === errorMessageObject.identifier
        ).length > 0
    ) {
        severity = 'info';
    }
    severity = severity === 'information' ? 'info' : severity;
    const displayMessage = getAccessibleMessage(
        errorMessageObject?.messageDescription
            ? errorMessageObject?.messageDescription
            : errorMessageObject?.message
    );

    return (
        <InPageAlert
            variant={severity}
            title={errorMessageObject?.messageTitle}
            data-testid={severity}
        >
            <ErrorMessageContainer dangerouslySetInnerHTML={{ __html: displayMessage }} />
            {errorMessageObject?.identifier === 'REGISTRATION_CERTIFICATE_BEING_MAILED' && confirmationData.customer.mailingAddress !== null && (
                <p style={{ marginTop: "20" }}>
                    <strong>{confirmationData.customer.mailingAddress}</strong>{' '}<a href="https://account.service.nsw.gov.au/manage/personal-details" target="_blank" rel="noopener noreferrer">Change address</a>.
                </p>
            )}
        </InPageAlert>
    );
};

export default InlineNotificationConfirmation;
