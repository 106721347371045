import * as Constants from '../constants/constants';
export const errorMessages: Array<ErrorMessage> = [
  // DRIVES Business Errors
  {
    code: 'CUSTOMER_NOT_INDIVIDUAL',
    header: 'Cannot renew online',
    message:
      'It looks like you\'re a business customer and cannot renew online. To change your details, please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'CUSTOMER_NOT_FOUND',
    header: 'Cannot renew online',
    message:
      'Your details were not found. We apologise for the inconvenience. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'CONDITIONAL_REGO_INACTIVE',
    header: 'Cannot renew online',
    message:
      'The registration provided is not active. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'CONDITIONAL_REGO_NOT_FOUND',
    header: 'Cannot renew online',
    message:
      'The registration provided does not exist. Please check and try again.',
  },
  {
    code: 'REGISTRATION_NOT_FOUND',
    header: 'Cannot renew online',
    message:
      'There is no vehicle registration associated with the plate number.',
  },
  {
    code: 'PLATE_NOT_FOUND',
    header: 'Cannot renew online',
    message:
      'The plate number entered does not match our records. Please check the details and try again.',
  },
  {
    code: 'TRADER_PLATE',
    header: 'Cannot renew online',
    message:
      "The vehicle has a trader's plate and is not eligble to renew online.",
  },
  {
    code: 'CLUB_VEHICLE',
    header: 'Cannot renew online',
    message:
      'This vehicle is not eligible for online renewal. Please visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a> to renew.',
  },
  {
    code: 'CUSTOMER_HAS_CAMS',
    header: 'Cannot renew online',
    message:
      'We need to check a few details with you before we proceed. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'OUTSTANDING_REGO_ACTION_EXISTS',
    header: 'Cannot renew online',
    message:
      'We need to check a few details with you before we proceed. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'PLATE_HAS_CAMS',
    header: 'Cannot renew online',
    message:
      'We need to check a few details with you before we proceed. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'REGISTRATION_ALREADY_RENEWED_TODAY',
    header: 'Cannot renew online',
    message:
      'It looks like this registration has already been renewed. Need help? Call us on <a href="tel:137788">13 77 88</a>.',
  },
  {
    code: 'REGISTRATION_CANCELLED',
    header: 'Cannot renew online',
    message:
      'This registration has been cancelled. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'REGISTRATION_EXPIRED_MORE_THAN_3_MONTHS',
    header: 'Cannot renew online',
    message:
      'This vehicle is ineligible to renew registration online. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'REGISTRATION_SUSPENDED',
    header: 'Cannot renew online',
    message:
      'We need to check a few details with you before we proceed. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'REGO_RENEWAL_IN_ADVANCE',
    header: 'Cannot renew online',
    message:
      'This registration is not yet due for renewal. Please check back closer to the renewal date.',
  },
  {
    code: 'RMS_INSPECTION_REQUIRED',
    header: 'Cannot renew online',
    message:
      'Sorry, this vehicle requires an RMS inspection and is not eligible for online renewal. Please visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a> to renew your registration.',
  },
  {
    code: 'VEHICLE_HAS_CAMS',
    header: 'Cannot renew online',
    message:
      'We need to check a few details with you before we proceed. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'VEHICLE_HAS_OUTSTANDING_DEFECTS',
    header: 'Cannot renew online',
    message:
      'We need to check a few details with you before we proceed. Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'INVALID_INPUT',
    header: 'Cannot renew online',
    message:
      'There was a technical error, we apologise for any inconvenience. You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'INVALID_MSG_SOLN',
    header: 'Cannot renew online',
    message:
      'There was a technical error, we apologise for any inconvenience. You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'SCHEMA_VALIDATION_ERROR',
    header: 'Cannot renew online',
    message:
      'There was a technical error, we apologise for any inconvenience. You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
  },
  {
    code: 'VCC_EXISTS_EXCEED_ALLOWABLE_TIME',
    header: 'Cannot renew online',
    messageHeadline:
      'The Vehicle Safety Compliance Certificate has exceeded 28 days',
    message:
      'This registration had undergone a Vehicle Safety Compliance Certification which has not been presented at a service centre within 28 days of the issued date. Please visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a> to renew this registation. Need help? Call us on <a href="tel:137788">13 77 88</a>.',
  },
  {
    code: 'VCC_EXISTS',
    messageHeadline:
      'Please bring your Vehicle Safety Compliance Certificate to a service centre',
    message:
      'This registration has undergone a Vehicle Safety Compliance Certification. Please present the \'Compliance Certificate\' to a <a style="color:#2e5299" href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a> within 28 days of the issue date. Need help? Call us on <a href="tel:137788">13 77 88</a>.',
  },
  {
    code: 'SYSTEM_FAILURE',
    header: Constants.TECHNICAL_ERROR_PAGE_HEADER,
    message: Constants.TECHNICAL_ERROR_PAGE_MESSAGE,
    showRenewAnotherVehicle: false,
  },
  {
    code: 'CANT_VALIDATE_JOINT_OPERATORS',
    header: '',
    message:
      'We need to check a few details with you before you can renew with a concession. You can proceed without a concession. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: false,
  },
  {
    code: 'DOB_NOT_MATCH_CENTRELINK',
    messageHeadline: 'Concession cannot be applied',
    message:
      'We need to check a few details with you before you can renew with a concession. You can proceed without a concession. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: false,
  },
  {
    code: 'NOT_ELIGIBLE_PENSIONER_DECEASED',
    messageHeadline: 'Concession cannot be applied',
    message:
      'We need to check a few details with you before you can renew with a concession. You can proceed without a concession. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: false,
  },
  {
    code: 'SURNAME_EXCEEDS_30_CHAR_FOR_PENSIONER',
    messageHeadline: 'Concession cannot be applied',
    message:
      'We need to check a few details with you before you can renew with a concession. You can proceed without a concession. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: false,
  },
  {
    code: 'SURNAME_NOT_MATCH_CENTRELINK',
    messageHeadline: 'Concession cannot be applied',
    message:
      'We need to check a few details with you before you can renew with a concession. You can proceed without a concession. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: false,
  },
  {
    code: 'GIVEN_NAME_NOT_MATCH_CENTRELINK',
    messageHeadline: 'Concession cannot be applied',
    message:
      'We need to check a few details with you before you can renew with a concession. You can proceed without a concession. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: false,
  },
  {
    code: 'INVALID_PENSIONER_CARD_NUMBER_FORMAT',
    messageHeadline: 'Concession cannot be applied',
    message:
      'We need to check a few details with you before you can renew with a concession. You can proceed without a concession. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: false,
  },
  {
    code: 'UNEXPECTED_ERROR_PENSIONER',
    header: '',
    message:
      'We need to check a few details with you before you can renew with a concession. You can proceed without a concession. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: false,
  },
  {
    code: 'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION',
    messageHeadline: "Your concession details can't be validated right now",
    message:
      "You can continue your transaction and any applicable concessions will apply. Transport for NSW will contact you by mail if they can't validate your concession details later. Keep your mailing address up to date.",
    showRenewAnotherVehicle: false,
  },
  {
    code: 'CONDITIONAL_REGO_SELF_DECLARATION_NOT_RECORDED',
    header: 'Registration renewal incomplete',
    message:
      'There was a technical error, we apologise for any inconvenience. Please visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: true,
  },
  {
    code: 'INVALID_PAYMENT_DETAILS',
    header: 'Registration renewal incomplete',
    message:
      'There was a technical error, we apologise for any inconvenience. You can try again later or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: true,
  },
  {
    code: 'PAYMENT_INVOICE_MISMATCH',
    header: 'Registration renewal incomplete',
    message:
      'There was a technical error, we apologise for any inconvenience. You can try again later or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.',
    showRenewAnotherVehicle: true,
  },
];
export const treatErrorWarningCodesAsSuccess: { [index: string]: any } = {
  FIND_VEHICLE_PAGE_CHECK_ELIGIBILITY: [
    'REGISTRATION_ALREADY_RENEWED_TODAY',
    'REGISTRATION_AUTOMATICALLY_RENEWED',
  ],
};

export const treatErrorWarningCodesAsInfo: { [index: string]: any } = {
  FIND_VEHICLE_PAGE_CHECK_ELIGIBILITY: [
    'REGO_RENEWAL_IN_ADVANCE',
  ],
  REVIEW_DETAILS_PAGE_ONLOAD_INITIAL_QUOTE: [
    'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION',
  ],
  REVIEW_DETAILS_PAGE_CHANGE_USAGE: [
    'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION',
  ],
  CONFIRMATION_PAGE: ['PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION'],
};

export const inlineErrorOrWarningCodes: { [index: string]: any } = {
  FIND_VEHICLE_PAGE: [
    'INVALID_INPUT',
    'CUSTOMER_NOT_FOUND',
    'TRADER_PLATE',
    'PLATE_NOT_FOUND',
    'REGISTRATION_NOT_FOUND',
    'TOO_MANY_REGISTRATIONS',
    'CONDITIONAL_REGO_NOT_FOUND',
    'INVALID_REGISTRATION_NUMBER',
  ],
  FIND_VEHICLE_PAGE_CHECK_ELIGIBILITY: [
    'REGISTRATION_ALREADY_RENEWED_TODAY',
    'REGISTRATION_AUTOMATICALLY_RENEWED',
    'REGO_RENEWAL_IN_ADVANCE',
    'RENEWAL_NOTICE_NOT_EXISTS',
    'OUTSTANDING_NEW_PLATE',
    'CLUB_VEHICLE',
    'COMMON_EXPIRY_EXISTS',
    'CONDITIONAL_REGO_INACTIVE',
    'CURRENT_USAGE_NOT_ELIGIBLE_ONLINE_RENEWAL',
    'LTR_VEHICLE_NOT_ELIGIBLE',
    'OUTSTANDING_REGO_ACTION_EXISTS',
    'OUTSTANDING_REGO_TRANSFER_EXISTS',
    'PLATE_HAS_CAMS',
    'PLATE_STYLE_NOT_ELIGIBLE',
    'REGISTRATION_CANCELLED',
    'REGISTRATION_EXPIRED_MORE_THAN_3_MONTHS',
    'REGISTRATION_SUSPENDED',
    'REGISTRATION_TYPE_NOT_GENERAL',
    'REGO_CONDITION_EXISTS',
    'RMS_INSPECTION_REQUIRED',
    'VCC_EXISTS_EXCEED_ALLOWABLE_TIME',
    'VEHICLE_HAS_CAMS',
    'VEHICLE_HAS_OUTSTANDING_DEFECTS',
    'CUSTOMER_HAS_CAMS',
    'CUSTOMER_NOT_INDIVIDUAL',
  ],
  REVIEW_DETAILS_PAGE_ONLOAD_INITIAL_QUOTE: [
    'CURRENT_USAGE_NOT_PNIC_BUT_DISABILITY',
    'CURRENT_USAGE_PNIC_BUT_NO_DISABILITY',
    'UNEXPECTED_ERROR_PENSIONER',
    'CANT_VALIDATE_JOINT_OPERATORS',
    'CONCESSION_ALREADY_USED',
    'CONCESSION_CARD_TYPE_NOT_VALID',
    'PRIMARY_OWNER_IS_NOT_PERSON_FOR_CONCESSION',
    'SECONDARY_CUSTOMER_NOT_PERSON_FOR_CONCESSION',
    'NO_PENSIONER_DETAIL_RECORDED_FOR_CURRENT_USAGE',
    'DOB_NOT_MATCH_CENTRELINK',
    'GIVEN_NAME_NOT_MATCH_CENTRELINK',
    'INVALID_PENSIONER_CARD_NUMBER_FORMAT',
    'NOT_ELIGIBLE_PENSIONER_DECEASED',
    'SURAME_EXCEEDS_30_CHAR_FOR_PENSIONER',
    'SURNAME_NOT_MATCH_CENTRELINK',
    'CENTRELINK_NOT_AVAILABLE',
    'SELECTED_USAGE_NOT_PNIC_BUT_DISABILITY',
    'SELECTED_USAGE_PNIC_BUT_NO_DISABILITY',
    'VCC_EXISTS',
    'OUTSTANDING_NEW_PLATE',
    'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION',
  ],
  REVIEW_DETAILS_PAGE_CHANGE_USAGE: [
    'CHANGE_REGISTRATION_USAGE_NOT_PERMITTED',
    'REGISTRATION_USAGE_CATEGORY_NOT_FOUND',
    'CENTRELINK_NOT_AVAILABLE',
    'PRIMARY_OWNER_IS_NOT_PERSON_FOR_CONCESSION',
    'SECONDARY_CUSTOMER_NOT_PERSON_FOR_CONCESSION',
    'SELECTED_USAGE_NOT_PNIC_BUT_DISABILITY',
    'SELECTED_USAGE_PNIC_BUT_NO_DISABILITY',
    'CURRENT_USAGE_PNIC_BUT_NO_DISABILITY',
    'CURRENT_USAGE_NOT_PNIC_BUT_DISABILITY',
    'CANT_VALIDATE_JOINT_OPERATORS',
    'CONCESSION_ALREADY_USED',
    'UNEXPECTED_ERROR_PENSIONER',
    'CONCESSION_CARD_TYPE_NOT_VALID',
    'NO_PENSIONER_DETAIL_RECORDED_FOR_CURRENT_USAGE',
    'DOB_NOT_MATCH_CENTRELINK',
    'GIVEN_NAME_NOT_MATCH_CENTRELINK',
    'INVALID_PENSIONER_CARD_NUMBER_FORMAT',
    'NOT_ELIGIBLE_PENSIONER_DECEASED',
    'SURAME_EXCEEDS_30_CHAR_FOR_PENSIONER',
    'SURNAME_NOT_MATCH_CENTRELINK',
    'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION',
  ],
  REVIEW_DETAILS_PAGE_SUBMIT: [
    'CHANGE_REGISTRATION_USAGE_NOT_PERMITTED',
    'REGISTRATION_USAGE_CATEGORY_NOT_FOUND',
    'CANT_VALIDATE_JOINT_OPERATORS',
    'CONCESSION_ALREADY_USED',
    'CONCESSION_CARD_TYPE_NOT_VALID',
    'CURRENT_USAGE_NOT_PNIC_BUT_DISABILITY',
    'CURRENT_USAGE_PNIC_BUT_NO_DISABILITY',
    'NO_PENSIONER_DETAIL_RECORDED_FOR_CURRENT_USAGE',
    'PRIMARY_OWNER_IS_NOT_PERSON_FOR_CONCESSION',
    'SECONDARY_CUSTOMER_NOT_PERSON_FOR_CONCESSION',
    'UNEXPECTED_ERROR_PENSIONER',
    'DOB_NOT_MATCH_CENTRELINK',
    'GIVEN_NAME_NOT_MATCH_CENTRELINK',
    'INVALID_PENSIONER_CARD_NUMBER_FORMAT',
    'NOT_ELIGIBLE_PENSIONER_DECEASED',
    'SURAME_EXCEEDS_30_CHAR_FOR_PENSIONER',
    'SURNAME_NOT_MATCH_CENTRELINK',
    'CENTRELINK_NOT_AVAILABLE',
    'SELECTED_USAGE_NOT_PNIC_BUT_DISABILITY',
    'SELECTED_USAGE_PNIC_BUT_NO_DISABILITY',
  ],
  CONFIRMATION_PAGE: [
    'VCC_EXISTS',
    'OUTSTANDING_NEW_PLATE',
    'REGISTRATION_CERTIFICATE_BEING_MAILED',
    'HVIS_BOOKING_MAY_BE_REQUIRED',
    'VEHICLE_HAD_HVIS_INSPECTION',
    'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION',
  ],
};

export const fullPageHeaders: { [index: string]: any } = {
  FIND_VEHICLE_PAGE: 'Cannot renew online',
  REVIEW_DETAILS_PAGE_ONLOAD_INITIAL_QUOTE: 'Cannot renew online',
  REVIEW_DETAILS_PAGE_CHANGE_USAGE: 'Cannot renew online',
  REVIEW_DETAILS_PAGE_SUBMIT: 'Cannot renew online',
  CONFIRMATION_PAGE: 'Registration renewal incomplete',
};

export interface ErrorMessage {
  code: string;
  message: string;
  header?: string; // Page header
  showRenewAnotherVehicle?: boolean;
  messageHeadline?: string; // Heading/headline for the notification box
}
