import {
  ErrorMessage,
  errorMessages,
  inlineErrorOrWarningCodes,
} from '../config/error-messages';

export const getWarningCode = (messages: Array<any>): string => {
  return messages.filter(
    (message: { severity: string }) => message.severity === 'WARNING'
  )[0]?.code;
};

export const getBusinessErrorCode = (messages: Array<any>): string => {
  return messages.filter(
    (message: { severity: string; category: string }) =>
      message.severity === 'ERROR' && message.category === 'BUSINESS'
  )[0]?.code;
};

export const getErrorCode = (messages: Array<any>): string => {
  return messages.filter(
    (message: { severity: string; category: string }) =>
      message.severity === 'ERROR'
  )[0]?.code;
};

export const getErrorMessageByCode = (
  messages: Array<any>,
  errorCode: String
): any => {
  return messages.filter(
    (message: { identifier: string }) => message.identifier === errorCode
  )[0];
};
export const getErrorDetails = (errorCode: string): ErrorMessage => {
  return errorMessages.filter(
    (errorMessage) => errorMessage.code === errorCode
  )[0];
};

export const stripTelForNonMobileDevices = (errorMessage: string): string => {
  const telLinkPositionStart = errorMessage.indexOf('tel:');
  if (telLinkPositionStart > 8) {
    const telAnchorOpentagEnd = errorMessage.indexOf('>', telLinkPositionStart);
    const telAnchorClosetagStart = errorMessage.indexOf(
      '</a>',
      telLinkPositionStart
    );
    return (
      errorMessage.substring(0, telLinkPositionStart - 9) +
      errorMessage.substring(telAnchorOpentagEnd + 1, telAnchorClosetagStart) +
      errorMessage.substring(telAnchorClosetagStart + 4)
    );
  }
  return errorMessage;
};

export const nowrapTelephone = (errorMessage: string) => {
  const telLinkPositionStart = errorMessage.indexOf('tel:');
  if (telLinkPositionStart > 8) {
    const telAnchorClosetagStart = errorMessage.indexOf(
      '</a>',
      telLinkPositionStart
    );
    return (
      errorMessage.substring(0, telLinkPositionStart - 9) +
      '<span style="white-space: nowrap">' +
      errorMessage.substring(
        telLinkPositionStart - 9,
        telAnchorClosetagStart + 4
      ) +
      '</span>' +
      errorMessage.substring(telAnchorClosetagStart + 4)
    );
  }
  return errorMessage;
};

export const getErrorIdentifier = (messages: Array<any>): string => {
  return messages.filter(
    (message: { severity: string; category: string }) =>
      message.severity === 'ERROR'
  )[0]?.identifier;
};
export const getWarningIdentifier = (messages: Array<any>): string => {
  return messages.filter(
    (message: { severity: string }) => message.severity === 'WARNING'
  )[0]?.identifier;
};

export const getInfoIdentifier = (messages: Array<any>): string => {
  return messages.filter(
      (message: { severity: string }) => message.severity === 'INFORMATION'
  )[0]?.identifier;
};

export const inlineErrorOrWarningPresent = (
  messages: any,
  pageActionID: string
): any => {
  const errorCode =
    getErrorIdentifier(messages) ||
    getWarningIdentifier(messages) ||
    getInfoIdentifier(messages);
  return (
    inlineErrorOrWarningCodes[pageActionID]?.filter(
      (inlineErrorCode: string) => inlineErrorCode === errorCode
    )?.length > 0
  );
};

export const stripSuccessIfAny = (messages: any): any => {
  return messages?.filter((message: any) => message.identifier !== 'SUCCESS');
};

export const stripSpecificDRIVESMessage = (
    messages: any,
    identifier: string
): any => {
  return messages?.filter((message: any) => message.identifier !== identifier);
};

