import {
  AnalyticsSessionManager,
  UserSession,
} from '@rmstransactions/components';
import ConfirmationUUIDManager from 'session/ConfirmationUUIDManager';
import {
  DRUPAL_RENEW_REGISTRATION_URL,
  HELP_US_IMPROVE_URL, SNSW_HOME_PAGE_URL,
} from '../constants/constants';

export const transactionCleanupEvents = () => {
  // Google Analytics
  AnalyticsSessionManager.clearSessionID();
  AnalyticsSessionManager.clearUserID();
  AnalyticsSessionManager.clearUserLoginType();
  AnalyticsSessionManager.clearTransactionStartTime();

  // remove receipt UUID
  ConfirmationUUIDManager.clear();
};

export const gotoDrupal = () => {
  transactionCleanupEvents();
  window.location.href = DRUPAL_RENEW_REGISTRATION_URL;
};

export const gotoStartPage = () => {
  transactionCleanupEvents();
  window.location.href = '/find-vehicle';
};

export const gotoHelpUsImprove = () => {
  window.open(HELP_US_IMPROVE_URL);
};
export const gotoNSWHomePage = () => {
  transactionCleanupEvents();
  window.location.href = SNSW_HOME_PAGE_URL;
};

export const logout = () => {
  transactionCleanupEvents();
  UserSession.clearUserSession();
  sessionStorage.clear();
  window.location.href =
    process.env.REACT_APP_MY_ACCOUNT_BASE_URL + '/api/auth/logout';
};
