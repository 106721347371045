import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ComponentLoader,
  ContentContainer,
  FormCheckbox,
  Heading,
  SectionPage,
  BrandIconLocked,
  InPageAlert,
} from '@snsw-gel/react';
import { useHistory, useParams } from 'react-router-dom';
import { AppState, store } from 'providers/globalState';
import VehicleRegistrationCard from './components/VehicleRegistrationCard';
import { Header, AnalyticsService, PrivacyStatement } from '@rmstransactions/components';
import { Col, Row } from '@rmstransactions/components/Styled';
import RegistrationConditions from './components/RegistrationConditions';
import TransactionDetails from './components/TransactionDetails';
import {
  getRegoRenewalDetails,
  initiateRenewal,
} from 'services/RegistrationService';
import {
  ButtonGroup,
  ConditionalDeclarations,
  NoBPAYMessageContainer,
  Nowrap,
  RenewalPeriodContainer,
  ServiceCentre,
  PrivacyWrapper,
} from './styled';
import { debugLogger, errorLogger } from '../../services/LoggerService';
import RegistrationSummaryPageLoader from './RegistrationSummaryPageLoader';
import TermsAndConditions from './components/TermsAndConditions';
import {
  getWarningCode,
  getErrorCode,
  getErrorDetails,
  stripTelForNonMobileDevices,
} from '../../helpers/error-processor';
import { ErrorMessage } from '../../config/error-messages';
import { Config } from '../../config/env';
import { isMobileOnly } from 'react-device-detect';
import TimeoutPage from '../TimeoutPage/TimeoutPage';
import { UserToken } from '@rmstransactions/components';

interface IDeclarationOption {
  value: string;
  label: string;
  isChecked: boolean;
}

interface IPageState {
  registeredOperatorDeclaration: IDeclarationOption;
  termsDeclaration: IDeclarationOption;
}
const InitialPageState: IPageState = {
  registeredOperatorDeclaration: {
    value: 'registeredOperator',
    label:
      'I am the registered operator of this vehicle or the agent registering the vehicle on behalf of the registered operator, there are no changes required to the operating conditions, CTP class or area of operation and I confirm the vehicle is suitable for safe use.',
    isChecked: false,
  },
  termsDeclaration: {
    value: 'terms',
    label: 'I accept the Terms and Conditions ',
    isChecked: false,
  },
};

const { myAccDashboardUrl, sfRenewRegoUrl } = Config;

const ConditionalRegistrationSummaryPage = () => {
  const { globalState, dispatch } = useContext(store) as {
    globalState: AppState;
    dispatch: any;
  };
  const { loading, regoData } = globalState;
  const {
    plateNumber,
    registrationID,
    registrationScheme,
  }: {
    plateNumber: string;
    registrationID: string;
    registrationScheme: string;
  } = useParams();

  const [pageState, setPageState] = useState<IPageState>(InitialPageState);
  const [checkValidation, setCheckValidationState] = useState(false);
  const [warning, setWarning] = useState<any>(null);
  const [declarationTimestamp, setDeclarationTimestamp] = useState('');
  const history = useHistory();
  const pageUrl =
    window.location.protocol +
    window.location.hostname +
    '/conditional-registration-summary/plateNumber/billingNumber/Conditional';

  useEffect(() => {
    document.title = 'Service NSW - Renew registration - Review details';
    debugLogger(
      'Input query params',
      plateNumber,
      registrationID,
      registrationScheme,
      globalState.userId
    );
    const loadData = async () => {
      dispatch({
        type: 'start',
        payload: {
          regoData: null,
        },
      });
      getRegoRenewalDetails(plateNumber, registrationID, registrationScheme)
        .then((response) => {
          UserToken.setValue(response.headers['x-token']);
          if (response && response.data) {
            debugLogger('getRegoRenewalDetails Response', response.data);
            if (response.data.differentCustomer === 'Y') {
              window.location.href = sfRenewRegoUrl;
              return;
            }
            if (
              response.data.eligibilityStatus === 'Y' &&
              response.data.messages != null &&
              response.data.messages.length > 0
            ) {
              const warningCode = getWarningCode(response.data.messages);
              if (warningCode) {
                const warning: ErrorMessage = getErrorDetails(warningCode);
                warning.message =
                  warning.message && !isMobileOnly
                    ? stripTelForNonMobileDevices(warning.message)
                    : warning.message;
                setWarning(warning);
              }
            } else if (
              response.data.eligibilityStatus === 'N' &&
              response.data.messages != null &&
              response.data.messages.length > 0
            ) {
              debugLogger('Error occurred', response.data);
              const errorCode = getErrorCode(response.data.messages);
              history.push(`/error/business/${errorCode}`);
            }
            dispatch({
              type: 'end',
              payload: {
                regoData: response.data,
              },
            });
          } else {
            errorLogger('error during then', response);
            history.push('/error', 'Invalid Data Returned');
          }
        })
        .catch((response) => {
          if (response.headers['x-token']) {
            UserToken.setValue(response.headers['x-token']);
          }
          errorLogger('getRegoRenewalDetails Error Response');
          history.push('/error', response);
        });
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePageStateWithDeclarationKey = (
    key: 'registeredOperatorDeclaration' | 'termsDeclaration',
    fromModal?: boolean
  ) => {
    setPageState({
      ...pageState,
      [key]: {
        ...pageState[key],
        isChecked: fromModal ? true : !pageState[key].isChecked,
      },
    });
  };

  const setRegisteredOperator = () => {
    updatePageStateWithDeclarationKey('registeredOperatorDeclaration');
  };

  const setTermsDeclaration = (fromModal?: boolean) => {
    let currentTime = new Date().getTime().toString();
    setDeclarationTimestamp(currentTime);
    updatePageStateWithDeclarationKey('termsDeclaration', fromModal);
  };

  const isPageChecked = () => {
    return (
      pageState.registeredOperatorDeclaration.isChecked &&
      pageState.termsDeclaration.isChecked
    );
  };

  const submitRenewal = (e: React.FormEvent<HTMLFormElement>) => {
    setCheckValidationState(true);
    if (isPageChecked()) {
      dispatch({
        type: 'external_call',
        payload: {
          regoData: null,
        },
      });
      const paymentRequest = {
        declarationTimestamp,
        amount: regoData.transactionDetails.totalAmount,
      };
      debugLogger('paymentRequest', paymentRequest);
      initiateRenewal(paymentRequest)
        .then((response) => {
          dispatch({
            type: 'end',
            payload: {
              regoData: response.data,
            },
          });
          if (response.data.redirectUrl) {
            window.location.href = response.data.redirectUrl;
          } else if (
            response &&
            response.data &&
            response.data.messages != null &&
            response.data.messages[0] != null
          ) {
            debugLogger('Error occurred', response.data);
            const errorCode = getErrorCode(response.data.messages);
            history.push(`/error/business/${errorCode}`);
          } else {
            errorLogger('response.data', response.data);
            history.push('/error', 'Invalid Data Returned');
          }
        })
        .catch((error) => {
          errorLogger(error);
          history.push('/error', error);
        });
      return;
    } else {
      e.preventDefault();
    }
  };

  const gotoDashboard = () => {
    window.location.href = myAccDashboardUrl;
  };

  if (loading === 'IN_PROGRESS') {
    return (
      <RegistrationSummaryPageLoader
        handleAgreeTAndCs={() => setTermsDeclaration(true)}
      />
    );
  } else if (loading === 'COMPLETED' && regoData) {
    if (regoData.eligibilityStatus === 'Y') {
      return (
        <>
          <TimeoutPage />
          <Header
            data-testid='header'
            title='Check your registration renewal'
          />
          <ContentContainer>
            <SectionPage>
              <Row>
                <Col lg={8}>
                  <VehicleRegistrationCard
                    registrationDetails={regoData.registration}
                  />
                  <section>
                    {!warning && (
                      <InPageAlert
                        title='Changes to the registration details of this vehicle
                          cannot be done online.'
                        variant='info'
                      >
                        If the ‘Operating conditions’, ‘CTP Class’ or ‘Area’ of
                        operation, have changed since your last renewal, you can
                        visit a{' '}
                        <ServiceCentre>
                          <a
                            className='fontColor'
                            href={
                              'https://www.service.nsw.gov.au/service-centre'
                            }
                            target={'_blank'}
                            rel='noopener noreferrer'
                          >
                            service centre
                          </a>
                        </ServiceCentre>{' '}
                        or call <Nowrap>13 77 88</Nowrap> to update.
                      </InPageAlert>
                    )}
                    {warning &&
                      warning?.code !==
                        'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION' && (
                        <InPageAlert
                          title={warning?.messageHeadline || ''}
                          variant='warning'
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: warning.message,
                            }}
                          ></span>
                        </InPageAlert>
                      )}
                    {warning &&
                      warning?.code ===
                        'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION' && (
                        <InPageAlert
                          title={warning?.messageHeadline || ''}
                          variant='info'
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: warning.message,
                            }}
                          ></span>
                        </InPageAlert>
                      )}
                  </section>
                  <RenewalPeriodContainer>
                    <div className='regoPeriod'>
                      <strong data-testid='registrationTermMonths'>
                        {regoData.registration.registrationTermMonths} month
                        renewal{' '}
                      </strong>
                    </div>
                    Based on your previous renewal term
                  </RenewalPeriodContainer>

                  <RegistrationConditions
                    conditions={regoData.registration.conditions}
                    transactionStartTime={globalState.transactionStartTime}
                  />

                  <TransactionDetails
                    transactionDetails={regoData.transactionDetails}
                    registrationTermMonths={
                      regoData.registration.registrationTermMonths
                    }
                  />

                  <ConditionalDeclarations>
                    <Heading level={3}>I declare that</Heading>
                    <FormCheckbox
                      checked={
                        pageState.registeredOperatorDeclaration.isChecked
                      }
                      errorMessage='Please select the checkbox if you agree with the above statement to proceed.'
                      hasError={
                        checkValidation &&
                        !pageState.registeredOperatorDeclaration.isChecked
                      }
                      label={pageState.registeredOperatorDeclaration.label}
                      name='registeredOperatorDeclaration'
                      onChange={setRegisteredOperator}
                      data-testid='registeredOperatorDeclaration'
                      value={pageState.registeredOperatorDeclaration.value}
                    />
                    {checkValidation &&
                      !pageState.registeredOperatorDeclaration.isChecked &&
                      AnalyticsService.ErrorReport(
                        'Inline_validation_error',
                        'Declarations Error',
                        'Please select the checkbox if you agree with the above statement to proceed.',
                        globalState.transactionStartTime
                      )}
                    <TermsAndConditions
                      handleAgreeTAndCs={() => setTermsDeclaration(true)}
                    />
                    <FormCheckbox
                      checked={pageState.termsDeclaration.isChecked}
                      errorMessage='Please select the checkbox accepting the terms and conditions to proceed.'
                      hasError={
                        checkValidation && !pageState.termsDeclaration.isChecked
                      }
                      label={pageState.termsDeclaration.label}
                      name='termsDeclaration'
                      onChange={setTermsDeclaration}
                      data-testid='termsDeclaration'
                      value={pageState.termsDeclaration.value}
                    />
                    {checkValidation &&
                      !pageState.termsDeclaration.isChecked &&
                      AnalyticsService.ErrorReport(
                        'Inline_validation_error',
                        'Terms and Conditions Error',
                        'Please select the checkbox accepting the terms and conditions to proceed.',
                        globalState.transactionStartTime
                      )}
                    <p
                      className={
                        regoData.restrictedPaymentMethods != null &&
                        regoData.restrictedPaymentMethods?.indexOf('BPAY') >= 0
                          ? 'removeBottomPadding'
                          : ''
                      }
                    >
                      Making this declaration you understand that a false
                      declaration may result in criminal prosecution with
                      maximum penalties including imprisonment for up to 2 years
                      and a fine of up to $22,000.
                    </p>
                  </ConditionalDeclarations>
                  {regoData.restrictedPaymentMethods != null &&
                    regoData.restrictedPaymentMethods?.indexOf('BPAY') >= 0 && (
                      <NoBPAYMessageContainer>
                        <InPageAlert
                          variant='info'
                          title='BPAY is unavailable for this renewal'
                        >
                          This registration has expired and may be cancelled
                          before a BPAY payment is processed. BPAY will not be
                          available in the next step. BPAY can take up to 3
                          business days to process.
                        </InPageAlert>
                      </NoBPAYMessageContainer>
                    )}
                  <PrivacyStatement />
                  <ButtonGroup>
                    <Button
                      theme='secondary'
                      className='conditionalButton conditionalButton-back'
                      data-testid='backButton'
                      onClick={() => gotoDashboard()}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={(e: React.FormEvent<HTMLFormElement>) => {
                        submitRenewal(e);
                      }}
                      className='conditionalButton conditionalButton-continue'
                      data-testid='continueButton'
                    >
                      {regoData.transactionDetails.totalAmount > 0
                        ? 'Continue to payment'
                        : 'Confirm renewal'}
                    </Button>
                  </ButtonGroup>
                  <br />
                </Col>
              </Row>
            </SectionPage>
          </ContentContainer>
        </>
      );
    } else {
      return (
        <ComponentLoader
          fullPage
          label={
            <>
              Processing, please wait.
              <br /> Do not refresh or navigate away.
            </>
          }
        />
      );
    }
  } else if (loading === 'EXTERNAL_CALL_IN_PROGRESS') {
    return (
      <ComponentLoader
        fullPage
        label={
          <>
            Processing, please wait.
            <br /> Do not refresh or navigate away.
          </>
        }
      />
    );
  }
  return <span></span>;
};

export default ConditionalRegistrationSummaryPage;
