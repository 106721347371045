import styled from 'styled-components';

export const IconLabelWrapper = styled.div`
  display: flex;
  margin-bottom: -0.25rem;

  .iconWrapper,
  svg {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.5rem;
  }

  .thank-you-text{
    font-size: 0.875rem;
  }
  
  p,
  a {
    margin: 0;
  }
`;

export const CarbonOffsetContainer = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;

  svg {
    margin-top: 0.125rem;
    width: 2.25rem;
    height: 2.25rem;
  }
`;

