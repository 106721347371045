import React from "react";
import {helpers} from "../Helpers/helpers";

const AccessibleNumber = ({value = ''}) => {
    return (
        <span>
            <span aria-hidden="true">{value}</span>
            <span className="sr-only">{helpers.spellOutNumbers(value)}</span>
        </span>
    );
};

export default AccessibleNumber;
